<template>
  <div>
    <b-row v-if="d_pageInfoData.page_caption" style="margin-bottom: 20px;">
      <b-col sm="12" lg="1"></b-col>
      <b-col sm="12" md="8">
        <strong style="font-size: 30px; font-family: cursive;">{{ d_pageInfoData.page_caption }}</strong>
      </b-col>
      <b-col sm="12" lg="3"></b-col>
    </b-row>
    <b-row v-if="d_pageInfoData">
      <b-col sm="12" lg="1"></b-col>
      <b-col sm="12" lg="8">
        <template v-for="(page_info, page_info_index) in d_pageInfoData.page_info">
          <page-info :p_pageInfo="page_info"></page-info>
          <hr>
        </template>
      </b-col>
      <b-col sm="12" lg="3"></b-col>
    </b-row>
    <operation></operation>
    <!-- {{ d_reports }} -->
    <b-row>
      <b-col sm="12" lg="1"></b-col>
      <b-col sm="12" lg="8">
        <template v-for="report_type in d_reports.paramorder">
          <template v-if="d_reports.parameters[report_type] && d_reports.parameters[report_type].report_list && d_reports.parameters[report_type].report_list.length > 0">
            <analyze-report-view :p_analyzeReports="d_reports.parameters[report_type].report_list"></analyze-report-view>
          </template>
        </template>
      </b-col>
      <b-col sm="12" lg="3"></b-col>
    </b-row>
  </div>
</template>

<script>
import WamserverService from '@/services/wamserver';
import AnalyzeReportView from '@/components/widgets/AnalyzeReportView';
import Operation from '@/components/widgets/Operation';
import _menu from '@/_menu';
import {
  default as PageInfo
} from '@/components/widgets/PageInfo';
import { version } from '@/config';
import { mapGetters } from 'vuex';
export default {
  name: 'operations',
  computed: mapGetters({
    StoreLang: 'StoreLang',
  }),
  components: {
    PageInfo,
    AnalyzeReportView,
    Operation,
  },
  props: {},
  data () {
    return {
      d_settings: {},
      d_reportTypeList: [],
      d_reports: { 'parameters': {}, 'paramorder': [] },
      d_pageInfoData: '',
      d_pageName: 'operations',
      d_version: version,
      d_sideBarMenuList: _menu.website_data.page_list,
    };
  },
  created: function () {
  },
  beforeMount () {},
  mounted: function () {
    let is_page_found = false;
    for (let i in this.d_sideBarMenuList) {
      if (this.d_sideBarMenuList[i].route_name === this.d_pageName) {
        this.d_pageInfoData = this.d_sideBarMenuList[i];
        is_page_found = true;
        break;
      } else {
        if (this.d_sideBarMenuList[i].sub_menu && this.d_sideBarMenuList[i].sub_menu.length > 0) {
          for (let sub_i in this.d_sideBarMenuList[i].sub_menu) {
            if (this.d_sideBarMenuList[i].sub_menu[sub_i].route_name === this.d_pageName) {
              this.d_pageInfoData = this.d_sideBarMenuList[i].sub_menu[sub_i];
              is_page_found = true;
              break;
            }
          }
        }
      }
      if (is_page_found) {
        break;
      }
    }
  },
  methods: {},
  watch: {}
}

</script>


