<template>
  <div style="font-size: 14px; margin-top: -20px;">
    <b-row v-if="d_fullTopMenu" style="font-size: 14px;">
      <!-- <b-col cols="1"> </b-col> -->
      <b-col sm="12" lg="6">
        <img src="@/icon/1994916.png" style="width: 2em; margin-right: 5px; padding: 0px;" />
        <template v-if="d_selectedWdmr307 && d_selectedWdmr307.id">
          <strong :title="d_selectedWdmr307.label" style="margin-right: 10px;">{{ d_selectedWdmr307.label }} </strong>
          <span style="color: red;">[{{ d_selectedWdmr307.id }}] </span>,
          <i>{{ d_selectedWdmr307.owner }} - </i>
          <i>{{ DateFormatWithTime(d_selectedWdmr307.created_at) }}</i>
        </template>
        <template v-else>
          Tüm organizasyonlar / {{ user.username }}
        </template>
      </b-col>
      <b-col sm="12" lg="1" style="text-align: center;">
        <img v-if="d_loading.status" src="@/icon/sandwatch.gif" style="width: 2em;" />
      </b-col>
      <b-col sm="12" lg="2" style="text-align: center;">
        <strong style="color: rgb(252, 0, 153, 1); text-align: right"> Worganization <img src="@/icon/1994869.png" style="width: 2em;" /> </strong>
      </b-col>
      <b-col sm="12" lg="3">
        <!-- 
          <b-button class="pull-right" size="md" :variant="d_fullScreenGeneral ? 'warning' : 'white'" :style="d_fullScreenGeneral ? 'margin-right: 5px; padding: 0px; border: solid 2px green;' : 'margin-right: 5px; padding: 0px;'" @click="d_fullScreenGeneral ? d_fullScreenGeneral = false : d_fullScreenGeneral = true">
            <img src="@/icon/629153.png" title="Tam ekran" style="width: 2em;" />
          </b-button>
          <b-button class="pull-right" size="md" :variant="d_fullTopMenu ? 'warning' : 'white'" :style="d_fullTopMenu ? 'margin-right: 5px; padding: 0px; border: solid 2px green;' : 'margin-right: 5px; padding: 0px;'" @click="d_fullTopMenu ? d_fullTopMenu = false : d_fullTopMenu = true">
            <img src="@/icon/45774.png" title="Tam ekran" style="width: 2em;" />
          </b-button>
         -->
      </b-col>
    </b-row>
    <div :style="f_calculateTabStyle()">
      <w-top-menu v-if="d_fullTopMenu" :p_menuData="d_menuData"></w-top-menu>
    </div>


    <b-tabs :style="f_calculateTabStyle()">



      <b-tab v-if="!p_settings || (p_settings && (!p_settings.selected_tab || p_settings.selected_tab === 'up_and_down'))" @click="d_selectedTab = 'up_and_down'" :active="d_selectedTab === 'up_and_down'">
        <template slot="title">
          <img src="@/icon/665778.png" style="width: 1.5em;">
          <span :style="'color:' + d_menuData.style.tab_header.color">Görev Listesi</span>
        </template>
        <template v-if="d_selectedTab === 'up_and_down'">
          <div no-body :class="[d_fullScreenOperationPage ? 'full-screen-mode' : 'normal-mode']">
            <b-row>
              <b-col sm="12" lg="4">
                <b-form-input style="width: 100%; padding: 1px; font-size: 10px;" v-model="d_filter.search_text" type="text" title="Ekranda arama sonucu varsa ekranda filtreler. Bulunmaz ise listele butonuna tıklanarak arka plan araması yapabilirsiniz." placeholder="operasyon adı veya açıklaması" @dblclick="d_filter.search_text = ''"></b-form-input>
              </b-col>
              <b-col sm="12" lg="2">
                <b-form-input style="width: 100%; padding: 1px; font-size: 10px;" v-model="d_filter.operation_no" type="text" title="Ekranda arama sonucu varsa ekranda filtreler. Bulunmaz ise listele butonuna tıklanarak arka plan araması yapabilirsiniz." placeholder="operasyon no ile filtreleme" @dblclick="d_filter.operation_no = ''"></b-form-input>
              </b-col>
              <b-col sm="12" lg="1">
                <!-- <b-pagination v-if="d_organizationListData.selected_index !== ''" size="sm" class="pull-right" style="margin-bottom: 0px;" aria-controls="pagination" v-model="d_pagination.current" :total-rows="d_taskListCount" :per-page="d_pagination.perpage"></b-pagination> -->
              </b-col>
              <b-col sm="12" lg="2">
                Görev Adedi: {{ d_workTimeTaskList.count }}
                <!-- <img v-if="d_loading.status" src="@/icon/sandwatch.gif" style="width: 2em;" /> -->
              </b-col>
              <b-col sm="12" lg="2">
                <b-button style="width: 100%;" variant="outline-primary" size="sm" @click="f_getWdm17ListByControllingOrganizationSelection(); d_operationFunctions.show = false;">
                  <i class="fa fa-refresh"></i> yenile
                </b-button>
              </b-col>
              <b-col sm="12" lg="1">
                <b-button class="pull-right" size="md" :variant="d_fullScreenOperationPage ? 'warning' : 'white'" :style="d_fullScreenOperationPage ? 'margin-right: 5px; padding: 0px; border: solid 2px green;' : 'margin-right: 5px; padding: 0px;'" @click="d_fullScreenOperationPage ? d_fullScreenOperationPage = false : d_fullScreenOperationPage = true">
                  <img src="@/icon/629153.png" title="Tam ekran" style="width: 2em;" />
                </b-button>
              </b-col>
            </b-row>
            <div :style="f_calculateBottomTabStyle()" class="general_scroll_class">
              <b-row style="border-bottom: solid 1px black; font-weight: bold; background-color: limegreen;">
                <b-col sm="12" lg="2">işlem</b-col>
                <b-col sm="12" lg="4">açıklama</b-col>
                <b-col sm="12" lg="4">çalışma notları</b-col>
                <b-col sm="12" lg="2">yorumlar</b-col>
              </b-row>
              <template v-for="(task, task_ind) in d_taskList">
                <div v-if="task && task.data && task.data.general" style="padding: 3px;">
                  <!-- <b-row :style="f_calculateTaskStyle(task)"> -->
                  <b-row :style="d_selectedTaskIndex === task_ind ? 'border-bottom: solid 1px black; background-color: #c3f1e1;' : 'border-bottom: solid 1px black;'" @click="f_selectThisTask(task_ind)">
                    <b-col sm="12" lg="2">
                      <div :style="f_calculatePriorityStyle(task)" :title="task && task.data && task.data.general && task.data.general.priority && task.data.general.priority.val ? task.data.general.priority.val.label : ''">
                        <!-- <p style="writing-mode: vertical-rl; transform: rotate(180deg); font-size: 10px; margin: auto; text-align: center; min-height: 9%;">{{ task.data.general.priority.val.label }}</p> -->
                      </div>
                      <div style="width: 100%;">
                        <b-dropdown variant="outline-primary" size="sm">
                          <template slot="button-content">
                            {{ task_ind + 1 }}) operasyon işlemleri
                          </template>
                          <b-dropdown-item variant="primary" @click="f_showTaskPreviewModal(task.id)"><i class="fa fa-eye"></i> görevi görüntüle</b-dropdown-item>
                          <!-- <b-dropdown-item v-if="selected_organization_item && selected_organization_item.index && f_controlOnlyMyPermission(task, 'can_give_task') && f_isActiveUserEqualOrUpperOfTaskLayerList(task, true) && f_controlMyLayerPermission('can_give_task', true)" @click="f_giveTaskToSelectedLayer('transfer', task_ind)"><i class="fa fa-star"></i> Bu görevi seçili kullanıcıya taşı (diğerlerinden siler)</b-dropdown-item> -->
                          <!-- <b-dropdown-item v-if="selected_organization_item && selected_organization_item.index && f_controlOnlyMyPermission(task, 'can_give_task') && f_isActiveUserEqualOrUpperOfTaskLayerList(task, true) && f_controlMyLayerPermission('can_give_task', true)" @click="f_giveTaskToSelectedLayer('add', task_ind)"><i class="fa fa-star"></i> Bu görevi seçili kullanıcıya da ekle</b-dropdown-item> -->
                          <!-- <b-dropdown-item v-if="selected_organization_item"><i class="fa fa-edit"></i> seçili katmana görev ekle </b-dropdown-item> -->
                          <!-- <b-dropdown-item v-if="task.data && task.data.general && task.data.general.task_type && task.data.general.task_type.val && task.data.general.task_type.val.value === 'drug_medical_equipment' && f_isActiveUserEqualOrUpperOfTaskLayerList(task, true)" @click="f_editBrand(task)"><i class="fa fa-edit"></i> ürün verisini düzenle</b-dropdown-item> -->
                          <!-- <b-dropdown-item v-if="task.data && task.data.general && task.data.general.task_tree_list && task.data.general.task_tree_list.val" @click="f_showTaskTreeList(task, task.data.general.task_tree_list.val, task_ind)"><i class="fa fa-eye"></i> görev ağacını göster</b-dropdown-item> -->
                          <!-- <b-dropdown-item v-if="task.user_index_list && Object.keys(task.user_index_list).length > 0" @click="f_addNewTask('wdm17', 'assigned', 'user', '', 'no', 'yes', task_ind)"><i class="fa fa-edit"></i> subtask ekle (görevin atandığı kişilere atama yap)</b-dropdown-item> -->
                          <!-- <b-dropdown-item @click="f_addNewTask('wdm17', 'not_assigned', 'not_user', '', 'no', 'yes', task_ind)"><i class="fa fa-edit"></i> subtask ekle (atama yapmadan oluştur)</b-dropdown-item> -->
                          <b-dropdown-item variant="primary" @click="f_showComments(task_ind, 'all_task_list')"><i class="fa fa-comment-o"></i> yorumları görüntüle</b-dropdown-item>
                          <b-dropdown-item variant="primary" @click="f_showWorkTime(task_ind)"><i class="fa fa-calendar"></i> operasyon işlemleri</b-dropdown-item>
                          <b-dropdown-item variant="primary" @click="f_editOperation(task_ind)"><i class="fa fa-edit"></i> operasyonu düzenle</b-dropdown-item>
                          <b-dropdown-item variant="primary" @click="f_showTagOperation(task_ind)"><i class="fa fa-tag"></i> etiketler </b-dropdown-item>
                          <b-dropdown-item variant="primary" @click="f_sendAllUsersMailAboutThisOperation(task, 'yes')"><i class="fa fa-mail-forward"></i> bilgilendirme maili </b-dropdown-item>
                          <b-dropdown-item variant="primary" @click="f_saveOperationFast(task_ind, task)"><i class="fa fa-save"></i> değişiklikleri kaydet</b-dropdown-item>
                        </b-dropdown>
                      </div>

                      <div style="border-bottom: solid 1px black;">
                        <strong> {{ d_taskOrganizationMatch['task_list'] && d_taskOrganizationMatch['task_list'][task.id] ? d_taskOrganizationMatch['task_list'][task.id]['label'] : '---' }} </strong>
                      </div>

                      <div style="padding: 0px; margin: 0px; width: 100%;">{{ task.id }}</div>

                      <template v-if="task.data && task.data.general && task.data.general.task_type && task.data.general.task_type.val && task.data.general.task_type.val.label">
                        <div style="padding: 2px; margin: 2px; font-size: 10px; width: 100%;"> {{ task.data.general.task_type.val.label }} </div>
                      </template>
                      <div style="padding: 2px; margin: 2px; font-size: 10px; width: 100%;">
                        {{ f_getAssignedBy(task) }}
                      </div>

                      <div v-if="task.data.general.status && task.data.general.status.val && task.data.general.status.val.value" :style="f_calculateStatusCellStyle('', task.data.general.status.val.value, 'no', '100')" @click="f_openStatusModal(task_ind)"> {{ task.data.general.status.val.label }} </div>
                      <div v-else @click="f_openStatusModal(task_ind)" :style="f_calculateStatusCellStyle('', 'none', 'no', '100')">
                        tanımsız
                      </div>

                      <template v-if="task.data && task.data.general && task.data.general.desired_begin_date && task.data.general.desired_begin_date.val">
                        <div>
                          <i class="fa fa-flag-o"></i> {{ f_isThisDateToday(task.data.general.desired_begin_date.val) ? DateFormatWithTime(task.data.general.desired_begin_date.val, 'hour_and_minute') : DateFormatWithTime(task.data.general.desired_begin_date.val, 'all') }}
                        </div>
                      </template>
                      <template v-if="task.data && task.data.general && task.data.general.desired_end_date && task.data.general.desired_end_date.val">
                        <div>
                          <i class="fa fa-flag-checkered"></i> {{ f_isThisDateToday(task.data.general.desired_end_date.val) ? DateFormatWithTime(task.data.general.desired_end_date.val, 'hour_and_minute') : DateFormatWithTime(task.data.general.desired_end_date.val, 'all') }}
                        </div>
                      </template>


                      <!-- <b-dropdown variant="white" size="sm" style="float: left;">
                          <template slot="button-content">
                            <template v-if="task.data && task.data.general && task.data.general.status && task.data.general.status.val && task.data.general.status.val.label">
                              <div :style="f_calculateStatusCellStyle('', task.data.general.status.val.value, 'yes', '100')" :title="task.data.general.status.val.label"> {{ task.data.general.status.val.label.length > 13 ? task.data.general.status.val.label.substring(0, 10) + '...' : task.data.general.status.val.label }} </div>
                            </template>
                          </template>
                          <template v-for="(st, st_ind) in d_wdm17StateOptions">
                            <b-dropdown-item :key="'d_wdm17StateOptions_dropdown_' + st_ind" v-if="f_controlState('all_task_list', st.value, task)" @click="f_saveOperationStatus(st, task_ind)"><i class="fa fa-star"></i> {{ st.label.toLocaleLowerCase('tr') }} </b-dropdown-item>
                          </template>
                        </b-dropdown> -->
                      
                      <div v-if="task.date" style="text-align: left; font-size: 10px;">
                        {{ DateFormatWithTime(task.date) }};
                      </div>
                      <div v-if="task.ending_date" style="text-align: left; font-size: 10px;">
                        {{ DateFormatWithTime(task.ending_date) }}
                      </div>
                      <!-- <div v-if="task.data && task.data.general && task.data.general.task_visibility && task.data.general.task_visibility.val && task.data.general.task_visibility.val.label" class="task-visibility" :title="task.data.general.task_visibility.val.label">
                          <img v-if="task.data.general.task_visibility.val.value === 'everybody'" src="@/icon/2196504.png" width="20px" style="cursor: pointer;" />
                          <img v-if="task.data.general.task_visibility.val.value === 'added_layer_and_upper_layers'" src="@/icon/2209527.png" width="20px" style="cursor: pointer;" />
                          <img v-if="task.data.general.task_visibility.val.value === 'added_layer_and_upper_layers_and_team_members'" src="@/icon/2717434.png" width="20px" style="cursor: pointer;" />
                        </div> -->
                      <!-- <div v-if="task.data && task.data.general && task.data.general.task_tree_list && task.data.general.task_tree_list.val" class="task-comment" :title="task.data.general.task_tree_list.val">
                          <img src="@/icon/1705440.png" width="20px" style="cursor: pointer;" />
                        </div> -->
                      <div class="task-comment" @click="f_showComments(task_ind, 'all_task_list')">
                        <i class="fa fa-comment-o"></i> {{ d_taskCommentSeenList[task.id] }}
                      </div>
                      <div class="task-worktime" @click="f_showWorkTime(task_ind)">
                        <i class="fa fa-clock-o"></i>
                        <template v-if="task.data && task.data.general && task.data.general.work_time && task.data.general.work_time.list && task.data.general.work_time.list.length > 0">
                          {{ task.data.general.work_time.list.length }}
                        </template>
                        <template v-else>
                          0
                        </template>
                      </div>
                      <!-- <div class="task-worktime" v-if="task.data && task.data.general && task.data.general.work_time && task.data.general.work_time.list && task.data.general.work_time.list.length > 0">
                            {{ task.data.general.work_time.list[task.data.general.work_time.list.length - 1]['start']['val'] }} | {{ task.data.general.work_time.list[task.data.general.work_time.list.length - 1]['end']['val'] }}
                        </div> -->
                      <div class="task-comment" @click="f_showDocuments(task, task_ind)">
                        <i class="fa fa-book"></i> {{ task.document_list ? task.document_list.length : '0' }}
                      </div>
                      <div v-if="task.data && task.data.general && task.data.general.useful_link && task.data.general.useful_link.val" class="operation-useful-link" :title="task.data.general.useful_link.val" @click="f_goToLink(task.data.general.useful_link.val)">
                        <img :href="task.data.general.useful_link.val" target="_blank" src="@/icon/201295.png" width="20px" style="border-radius: 3px; cursor: pointer;" />
                      </div>
                      <div class="operation-tag" @click="f_showTagOperation(task_ind)">
                        <i class="fa fa-tag"></i>
                      </div>
                      <template v-if="task.data && task.data.general && task.data.general.tag && task.data.general.tag.list && task.data.general.tag.list.length > 0">
                        <div v-for="(tag, tag_ind) in task.data.general.tag.list" class="operation-tag" @click="f_showTagOperation(task_ind)">
                          {{ tag.tag_name.val }}
                        </div>
                      </template>

                      <template v-if="d_selectedTaskIndex === task_ind">
                        <div>
                          <b-button variant="outline-primary" size="sm" @click="f_saveOperationFast(task_ind, task)">
                            <i class="fa fa-save"></i> kaydet
                          </b-button>
                        </div>
                      </template>

                    </b-col>



                    <b-col sm="12" lg="4">
                      <!-- <div :id="'operation_label_' + task_ind" @click="f_showTaskPreviewModal(task.id)" style="cursor: pointer;"> -->
                      <div :style="d_selectedTaskIndex === task_ind ? '' : 'overflow-y: auto; overflow-x: hidden; max-height: 250px;'">
                        <template v-if="d_selectedTaskIndex === task_ind">
                          <h6>Operasyon Adı:</h6>
                          <b-form-textarea v-model="task.label" rows="4"></b-form-textarea>
                          <h6>Operasyon Notu:</h6>
                          <ckeditor :editor="editor" v-model="task.data.general.note.val" :config="editorConfig"></ckeditor>
                        </template>
                        <template v-else>
                          <div>
                            <strong>{{ task.label ? task.label : 'name not defined' }}</strong>
                          </div>
                          <div v-html="task.data && task.data.general && task.data.general.note && task.data.general.note.val ? task.data.general.note.val : ''"></div>
                        </template>
                        <template v-for="(doc, doc_ind) in task.document_list">
                          <div v-if="['png', 'jpeg', 'jpg'].indexOf(doc.fileExtension) !== -1">
                            <img :src="doc.document" style="width: 100%;">
                          </div>
                        </template>
                      </div>
                    </b-col>



                    <b-col sm="12" lg="4">
                      <template v-if="d_selectedTaskIndex === task_ind">
                        <b-row style="margin: 0px;">
                          <b-col sm="12" md="12" style="text-align: right;">
                            <b-button variant="outline-primary" size="sm" @click="f_addWorkTimeNote(task)">
                              <i class="fa fa-plus-circle"></i> yeni çalışma notu
                            </b-button>
                          </b-col>
                        </b-row>
                      </template>
                      <template v-if="task.data && task.data.general && task.data.general.work_time && task.data.general.work_time.list && task.data.general.work_time.list.length > 0">
                        <div :style="d_selectedTaskIndex === task_ind ? '' : 'overflow-y: auto; overflow-x: hidden; max-height: 250px;'">

                          <template v-for="(worktime, worktime_ind) in task.data.general.work_time.list">
<!--                             <template v-if="worktime.work_time_note && worktime.work_time_note.val && worktime.start && worktime.start.val && worktime.end && worktime.end.val"></template>
 -->                            
                            <h6>Çalışma Notu {{ worktime_ind + 1 }})</h6>
                            <template v-if="d_selectedTaskIndex === task_ind">
                              <b-row style="margin: 0px;">
                                <b-col sm="12" md="9"> </b-col>
                                <b-col sm="12" md="3">
                                  <b-button style="width: 100%;" variant="outline-danger" size="sm" @click="f_deleteWorkTimeNote(task, worktime_ind)">
                                    <i class="fa fa-trash"></i> sil
                                  </b-button>
                                </b-col>
                              </b-row>
                              <b-row style="margin: 0px;">
                                <b-col sm="12" md="3">
                                  Başlama
                                </b-col>
                                <b-col sm="12" md="9">
                                  <datepicker :config="{ enableTime: true, minDate: '', maxDate: 'today', time_24hr: true}" class="form-control" style="background: white;" v-model="worktime.start.val"></datepicker>
                                </b-col>
                              </b-row>
                              <b-row style="margin: 0px; min-height: 50px;">
                                <b-col sm="12" md="3">
                                  Bitiş
                                </b-col>
                                <b-col sm="12" md="9">
                                  <datepicker v-if="d_showWorkTimeEnd" :config="{ enableTime: true, minDate: worktime.start.val, maxDate: '', time_24hr: true}" class="form-control" style="background: white;" v-model="worktime.end.val"></datepicker>
                                </b-col>
                              </b-row>
                            </template>
                            <template v-else>
                              <b-row>
                                <b-col sm="12" md="12">
                                  <strong>{{ DateFormatWithTime(worktime.start.val) }} -> {{ DateFormatWithTime(worktime.end.val) }}</strong>
                                </b-col>
                              </b-row>
                            </template>
                          
                            <b-row>
                              <b-col sm="12" md="12">
                                <!-- <div v-html="worktime.work_time_note.val" style="overflow-y: auto; overflow-x: hidden; max-height: 300px;"></div> -->
                                <template v-if="d_selectedTaskIndex === task_ind">
                                    <ckeditor :editor="editor" v-model="worktime.work_time_note.val" :config="editorConfig"></ckeditor>
                                </template>
                                <template v-else>
                                  <div v-html="worktime.work_time_note.val"></div>
                                </template>
                                <!-- <div style="white-space: pre-line;">
                                  {{ worktime.work_time_note.val }}
                                </div> -->
                              </b-col>
                            </b-row>
                          </template>
                        </div>
                      </template>
                    </b-col>
                    <b-col sm="12" lg="2">
                      <template v-if="task.data && task.data.general && task.data.general.comment && task.data.general.comment.list && task.data.general.comment.list.length > 0">
                        <div :style="d_selectedTaskIndex === task_ind ? '' : 'overflow-y: auto; overflow-x: hidden; max-height: 250px;'">
                          <template v-for="(com, com_ind) in task.data.general.comment.list">
                            <b-card :key="'task.data.general.comment.list_' + com_ind" no-body style="margin: 3px;">
                              <b-row>
                                <b-col sm="12" md="12">
                                  <template v-if="d_allOrganizationUsers[com.who_commented.val.value] && d_allOrganizationUsers[com.who_commented.val.value].pic">
                                    <img :src="d_allOrganizationUsers[com.who_commented.val.value].pic" @click="f_showUserProfileBModal(com.who_commented.val.label, d_allOrganizationUsers[com.who_commented.val.value].pic)" style="border-radius: 3px; margin: 2px; cursor: pointer; width: 20px;" />
                                  </template>
                                  <template v-else>
                                    <img src="@/icon/9370.png" style="border-radius: 3px; width: 20px;" />
                                  </template>
                                  {{ com.who_commented.val.label }} {{ com.comment_datetime.val.label }}
                                </b-col>
                              </b-row>
                              <b-row>
                                <b-col sm="12" md="12">
                                  <!-- 
                                    <div style="margin-top: 5px; padding: 5px; white-space: pre-line;">
                                      {{ com.message.val }}
                                    </div>
                                   -->
                                  <div v-html="com.message.val"  :style="d_selectedTaskIndex === task_ind ? '' : 'overflow-y: auto; overflow-x: hidden; max-height: 250px;'"> </div>
                                </b-col>
                              </b-row>
                            </b-card>
                          </template>
                        </div>
                      </template>
                    </b-col>
                  </b-row>
                </div>
              </template>
            </div>
          </div>
        </template>
      </b-tab>


      <b-tab v-if="!p_settings || (p_settings && (!p_settings.selected_tab || p_settings.selected_tab === 'date_line'))" @click="d_selectedTab = 'date_line'" :active="d_selectedTab === 'date_line'">
        <template slot="title">
          <img src="@/icon/665778.png" style="width: 1.5em;">
          <span :style="'color:' + d_menuData.style.tab_header.color">Tarih Çizelge</span>
        </template>
        <template v-if="d_selectedTab === 'date_line'">
          <div :class="[d_fullScreenOperationPage ? 'full-screen-mode' : 'normal-mode']">
            <b-row>
              <b-col sm="12" lg="4">
                <b-form-input style="width: 100%; padding: 1px; font-size: 10px;" v-model="d_filter.search_text" type="text" title="Ekranda arama sonucu varsa ekranda filtreler. Bulunmaz ise listele butonuna tıklanarak arka plan araması yapabilirsiniz." placeholder="operasyon adı veya açıklaması" @dblclick="d_filter.search_text = ''"></b-form-input>
              </b-col>
              <b-col sm="12" lg="2">
                <b-form-input style="width: 100%; padding: 1px; font-size: 10px;" v-model="d_filter.operation_no" type="text" title="Ekranda arama sonucu varsa ekranda filtreler. Bulunmaz ise listele butonuna tıklanarak arka plan araması yapabilirsiniz." placeholder="operasyon no ile filtreleme" @dblclick="d_filter.operation_no = ''"></b-form-input>
              </b-col>
              <b-col sm="12" lg="1">
                <!-- <b-pagination v-if="d_organizationListData.selected_index !== ''" size="sm" class="pull-right" style="margin-bottom: 0px;" aria-controls="pagination" v-model="d_pagination.current" :total-rows="d_taskListCount" :per-page="d_pagination.perpage"></b-pagination> -->
              </b-col>
              <b-col sm="12" lg="2">
                Görev Adedi: {{ d_workTimeTaskList.count }}
                <!-- <img v-if="d_loading.status" src="@/icon/sandwatch.gif" style="width: 2em;" /> -->
              </b-col>
              <b-col sm="12" lg="2">
                <b-button style="width: 100%;" variant="outline-primary" size="sm" @click="f_getWdm17ListByControllingOrganizationSelection(); d_operationFunctions.show = false;">
                  <i class="fa fa-refresh"></i> yenile
                </b-button>
              </b-col>
              <b-col sm="12" lg="1">
                <b-button class="pull-right" size="md" :variant="d_fullScreenOperationPage ? 'warning' : 'white'" :style="d_fullScreenOperationPage ? 'margin-right: 5px; padding: 0px; border: solid 2px green;' : 'margin-right: 5px; padding: 0px;'" @click="d_fullScreenOperationPage ? d_fullScreenOperationPage = false : d_fullScreenOperationPage = true">
                  <img src="@/icon/629153.png" title="Tam ekran" style="width: 2em;" />
                </b-button>
              </b-col>
            </b-row>
            <div :style="f_calculateBottomTabStyle()" class="general_scroll_class">
              <table>
                <thead>
                  <tr>
                    <th style="position: sticky; z-index: 1000; top: 0; text-align: center; background: rgba(255, 240, 250, 1); border-left: solid 1px #e9f4f1;">
                      Organizasyon
                    </th>
                    <th style="position: sticky; z-index: 1000; top: 0; text-align: center; background: rgba(255, 240, 250, 1); border-left: solid 1px #e9f4f1;">
                      Id
                    </th>
                    <th style="position: sticky; z-index: 1000; top: 0; text-align: center; background: rgba(255, 240, 250, 1); border-left: solid 1px #e9f4f1;">
                      Görev Tipi
                    </th>
                    <th style="position: sticky; z-index: 1000; top: 0; text-align: center; background: rgba(255, 240, 250, 1); border-left: solid 1px #e9f4f1;">
                      Adı
                    </th>
                    <th style="position: sticky; z-index: 1000; top: 0; text-align: center; background: rgba(255, 240, 250, 1); border-left: solid 1px #e9f4f1;">
                      Atanan
                    </th>
                    <th style="position: sticky; z-index: 1000; top: 0; text-align: center; background: rgba(255, 240, 250, 1); border-left: solid 1px #e9f4f1;">
                      Durum
                    </th>
                    <th style="position: sticky; z-index: 1000; top: 0; text-align: center; background: rgba(255, 240, 250, 1); border-left: solid 1px #e9f4f1;">
                      İşlem
                    </th>
                    <template v-for="(task_date, task_date_ind) in d_workTimeTaskList.work_time_date_order">
                      <th :style="task_date === d_todayDate ? 'position: sticky; z-index: 1000; top: 0; text-align: center; background: #41dcdc; border-left: solid 1px #e9f4f1; padding: 5px;' : 'position: sticky; z-index: 1000; top: 0; text-align: center; background: rgba(252, 0, 153, 1); border-left: solid 1px #e9f4f1; padding: 5px;'">
                        <!-- {{ DateFormat(task_date) }} -->
                        {{ d_workTimeTaskList.work_time_date_view_match_list[task_date] }}
                      </th>
                    </template>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(tag, tag_index) in d_workTimeTaskList.tag_order">
                    <tr>
                      <td colspan="6">
                        <div style="color: black; border-radius: 2px 2px; cursor: pointer; text-align: left; box-shadow: -1px -1px 2px #85ffbd; background-color: #85FFBD; background-image: linear-gradient(45deg, #85FFBD 0%, #FFFB7D 100%); min-width: 100px; margin: 5px; min-height: 20px;">
                          <img src="@/icon/2958560.png" width="20px" style="border-radius: 3px; cursor: pointer;" />
                          <strong>{{ tag }}</strong>
                        </div>
                      </td>
                    </tr>
                    <template v-for="(sub_tag, sub_tag_index) in d_workTimeTaskList.tags[tag].sub_tag_order">
                      <tr>
                        <td colspan="6">
                          <div style="color: black; border-radius: 2px 2px; cursor: pointer; text-align: left; box-shadow: -1px -1px 2px #85d8ff; background-color: #64ccda; background-image: linear-gradient(45deg, #e6fced 0%, #fffb7d 100%); min-width: 100px; margin: 5px; min-height: 20px; margin-left: 10px;">
                            <img src="@/icon/1421585.png" width="20px" style="border-radius: 3px; cursor: pointer;" />
                            <strong>{{ sub_tag }}</strong>
                          </div>
                        </td>
                      </tr>
                      <template v-for="(task_item, task_item_index) in d_workTimeTaskList.tags[tag].sub_tag_list[sub_tag].task_list">
                        <tr v-if="d_taskList[task_item.task_index] && d_taskList[task_item.task_index].id" @click="d_workTimeTaskList.selected_task_index = task_item.task_index; $forceUpdate();" :style="d_workTimeTaskList.selected_task_index === task_item.task_index ? 'background: #ecffe7;' : ''">
                          <td style="border-left: solid 1px #e9f4f1; min-width: 100px;">
                            <strong> {{ d_taskOrganizationMatch['task_list'] && d_taskList[task_item.task_index] && d_taskList[task_item.task_index].id && d_taskOrganizationMatch['task_list'][d_taskList[task_item.task_index].id] ? d_taskOrganizationMatch['task_list'][d_taskList[task_item.task_index].id]['label'] : '---' }} </strong>
                          </td>
                          <td style="border-left: solid 1px #e9f4f1; min-width: 100px;">
                            <div>
                              <template v-if="task_item.task_index !== '' && d_taskList[task_item.task_index] && d_taskList[task_item.task_index].data && d_taskList[task_item.task_index].data.general">
                                <b-row>
                                  <b-col cols="12">
                                    <b-dropdown variant="white" size="sm" style="width: 100%; float: left;">
                                      <template slot="button-content">
                                        <div style="padding: 0px; margin: 0px;">
                                          <template v-if="d_taskList[task_item.task_index].data && d_taskList[task_item.task_index].data.general && d_taskList[task_item.task_index].data.general.task_type && d_taskList[task_item.task_index].data.general.task_type.val && d_taskList[task_item.task_index].data.general.task_type.val.label">
                                            {{ d_taskList[task_item.task_index].id }}
                                          </template>
                                        </div>
                                      </template>
                                      <b-dropdown-item @click="f_showTaskPreviewModal(d_taskList[task_item.task_index].id)"><i class="fa fa-eye"></i> görevi görüntüle</b-dropdown-item>
                                      <b-dropdown-item @click="f_showComments(task_item.task_index, 'all_task_list')"><i class="fa fa-edit"></i> yorumları görüntüle</b-dropdown-item>
                                      <b-dropdown-item @click="f_showWorkTime(task_item.task_index)"><i class="fa fa-calendar"></i> operasyon işlemleri</b-dropdown-item>
                                      <b-dropdown-item @click="f_editOperation(task_item.task_index)"><i class="fa fa-edit"></i> operasyonu düzenle</b-dropdown-item>
                                      <b-dropdown-item @click="f_showTagOperation(task_item.task_index)"><i class="fa fa-edit"></i> etiketler </b-dropdown-item>
                                      <b-dropdown-item @click="f_sendAllUsersMailAboutThisOperation(d_taskList[task_item.task_index], 'yes')"><i class="fa fa-edit"></i> bilgilendirme maili </b-dropdown-item>
                                      <b-dropdown-item @click="f_addNewOperationWithThisTag(tag, d_taskList[task_item.task_index])"><i class="fa fa-edit"></i> aynı etiketle aynı kullanıcıları seçerek yeni operasyon oluştur </b-dropdown-item>
                                    </b-dropdown>
                                  </b-col>
                                </b-row>
                              </template>
                            </div>
                          </td>
                          <td style="border-left: solid 1px #e9f4f1; min-width: 100px;">
                            <div>
                              <template v-if="task_item.task_index !== '' && d_taskList[task_item.task_index] && d_taskList[task_item.task_index].data && d_taskList[task_item.task_index].data.general">
                                <b-row>
                                  <b-col cols="12">
                                    <div v-if="d_taskList[task_item.task_index].data.general.task_type" style="padding: 3px; font-size: 10px; float: left; min-width: 75px; font-weight: bold;" :title="d_taskList[task_item.task_index].data.general.task_type.val.label"> {{ d_taskList[task_item.task_index].data.general.task_type && d_taskList[task_item.task_index].data.general.task_type.val.label.length > 50 ? d_taskList[task_item.task_index].data.general.task_type.val.label.substring(0, 50) + '...' : d_taskList[task_item.task_index].data.general.task_type.val.label }} </div>
                                    <div v-if="d_taskList[task_item.task_index].data.general.priority" :style="d_taskPriorityTextStyle.task_list[d_taskList[task_item.task_index].id]" :title="d_taskList[task_item.task_index].data.general.priority.val.label"> {{ d_taskList[task_item.task_index].data.general.priority && d_taskList[task_item.task_index].data.general.priority.val.label.length > 50 ? d_taskList[task_item.task_index].data.general.priority.val.label.substring(0, 50) + '...' : d_taskList[task_item.task_index].data.general.priority.val.label }} </div>
                                  </b-col>
                                </b-row>
                              </template>
                            </div>
                          </td>
                          <td style="border-left: solid 1px #e9f4f1; min-width: 300px;">
                            <div>
                              <template v-if="task_item.task_index !== '' && d_taskList[task_item.task_index] && d_taskList[task_item.task_index].data && d_taskList[task_item.task_index].data.general">
                                <b-row>
                                  <b-col cols="12">
                                    <div @click="f_showTaskPreviewModal(d_taskList[task_item.task_index].id)" style="cursor: pointer;">
                                      {{ d_taskList[task_item.task_index].label }}
                                    </div>
                                  </b-col>
                                </b-row>
                              </template>
                            </div>
                          </td>
                          <td style="border-left: solid 1px #e9f4f1; min-width: 100px;">
                            <div>
                              <template v-if="task_item.task_index !== '' && d_taskList[task_item.task_index] && d_taskList[task_item.task_index].data && d_taskList[task_item.task_index].data.general">
                                <b-row>
                                  <b-col cols="12">
                                    <span v-if="d_taskList[task_item.task_index].data.general.assigned_user_title && d_taskList[task_item.task_index].data.general.assigned_user_title.val && d_taskList[task_item.task_index].data.general.assigned_user_title.val.label" style="color: #2196f3;" :title="d_taskList[task_item.task_index].data.general.assigned_user_title.val.label"> {{ d_taskList[task_item.task_index].data.general.assigned_user_title.val.label.length > 20 ? d_taskList[task_item.task_index].data.general.assigned_user_title.val.label.substring(0,20) + '...' : d_taskList[task_item.task_index].data.general.assigned_user_title.val.label }} </span> {{ f_getAssignedBy(d_taskList[task_item.task_index]) }}
                                  </b-col>
                                </b-row>
                              </template>
                            </div>
                          </td>
                          <td style="border-left: solid 1px #e9f4f1; min-width: 100px;">
                            <div v-if="d_taskList[task_item.task_index]" :style="d_taskCellStyleList[d_taskList[task_item.task_index].id].without_border">
                              <template v-if="task_item.task_index !== '' && d_taskList[task_item.task_index] && d_taskList[task_item.task_index].data && d_taskList[task_item.task_index].data.general">
                                <b-row>
                                  <b-col cols="12">
                                    <div v-if="d_taskList[task_item.task_index].data.general.status && d_taskList[task_item.task_index].data.general.status.val && d_taskList[task_item.task_index].data.general.status.val.value" :title="d_taskList[task_item.task_index].data.general.status.val.label" @click="f_openStatusModal(task_item.task_index)"> {{ d_taskList[task_item.task_index].data.general.status.val.label.length > 13 ? d_taskList[task_item.task_index].data.general.status.val.label.substring(0, 10) + '...' : d_taskList[task_item.task_index].data.general.status.val.label }} </div>
                                    <div v-else @click="f_openStatusModal(task_item.task_index)" style="cursor: pointer; border: solid 1px black; text-align: center;">
                                      tanımsız
                                    </div>
                                  </b-col>
                                </b-row>
                              </template>
                            </div>
                          </td>
                          <td style="border-left: solid 1px #e9f4f1; min-width: 100px;">
                            <div>
                              <template v-if="task_item.task_index !== '' && d_taskList[task_item.task_index] && d_taskList[task_item.task_index].data && d_taskList[task_item.task_index].data.general">
                                <b-row>
                                  <b-col cols="12">
                                    <b-row>
                                      <b-col cols="12">
                                        <div v-if="d_taskList[task_item.task_index].date" style="text-align: left; font-size: 10px; float: left;">
                                          {{ DateFormatWithTime(d_taskList[task_item.task_index].date) }};
                                        </div>
                                        <div v-if="d_taskList[task_item.task_index].ending_date" style="text-align: left; font-size: 10px; float: left;">
                                          {{ DateFormatWithTime(d_taskList[task_item.task_index].ending_date) }}
                                        </div>
                                        <div class="task-comment" @click="f_showComments(task_item.task_index, 'all_task_list')">
                                          <i class="fa fa-comment-o"></i> {{ d_taskCommentSeenList[d_taskList[task_item.task_index].id] }}
                                        </div>
                                        <div class="task-worktime" @click="f_showWorkTime(task_item.task_index)">
                                          <i class="fa fa-clock-o"></i>
                                          <template v-if="d_taskList[task_item.task_index].data && d_taskList[task_item.task_index].data.general && d_taskList[task_item.task_index].data.general.work_time && d_taskList[task_item.task_index].data.general.work_time.list && d_taskList[task_item.task_index].data.general.work_time.list.length > 0">
                                            {{ d_taskList[task_item.task_index].data.general.work_time.list.length }}
                                          </template>
                                          <template v-else>
                                            0
                                          </template>
                                        </div>
                                        <div class="task-comment" @click="f_showDocuments(d_taskList[task_item.task_index], task_item.task_index)">
                                          <i class="fa fa-book"></i> {{ d_taskList[task_item.task_index].document_list ? d_taskList[task_item.task_index].document_list.length : '' }}
                                        </div>
                                        <div v-if="d_taskList[task_item.task_index].data && d_taskList[task_item.task_index].data.general && d_taskList[task_item.task_index].data.general.useful_link && d_taskList[task_item.task_index].data.general.useful_link.val" class="operation-useful-link" :title="d_taskList[task_item.task_index].data.general.useful_link.val" @click="f_goToLink(d_taskList[task_item.task_index].data.general.useful_link.val)">
                                          <img :href="d_taskList[task_item.task_index].data.general.useful_link.val" target="_blank" src="@/icon/201295.png" width="20px" style="border-radius: 3px; cursor: pointer;" />
                                        </div>
                                        <div class="operation-tag" @click="f_showTagOperation(task_item.task_index)">
                                          <i class="fa fa-tag"></i>
                                        </div>
                                        <template v-if="d_taskList[task_item.task_index].data && d_taskList[task_item.task_index].data.general && d_taskList[task_item.task_index].data.general.tag && d_taskList[task_item.task_index].data.general.tag.list && d_taskList[task_item.task_index].data.general.tag.list.length > 0">
                                        </template>
                                      </b-col>
                                    </b-row>
                                  </b-col>
                                </b-row>
                              </template>
                            </div>
                          </td>
                          <template v-for="(task_date, task_date_ind) in d_workTimeTaskList.work_time_date_order">
                            <td :style="task_date === d_todayDate ? 'background : rgb(65, 220, 220); border-left: solid 1px #e9f4f1; min-width: 100px;' : 'background : white; border-left: solid 1px #e9f4f1; min-width: 100px;'">
                              <template v-if="d_workTimeTaskList.log_status_list[task_date] && d_workTimeTaskList.log_status_list[task_date][task_item.task_id] && d_workTimeTaskList.log_status_list[task_date][task_item.task_id].log_status_list.length > 0">
                                <div v-for="(lg, lg_index) in d_workTimeTaskList.log_status_list[task_date][task_item.task_id].log_status_list" @click="f_showTaskPreviewModal(task_item.task_id)" :title="lg.label">
                                  <div> {{ lg.label }} </div>
                                  <!-- <div :style="f_calculateStatusCellStyle('', lg.value)">
                                    {{ lg.label.length > 10 ? lg.label.substring(0,10) + '..' : lg.label }}
                                  </div> -->
                                </div>
                              </template>
                              <div v-if="f_isTaskHasWorkTimeInThisDay(d_taskList[task_item.task_index], task_date)">
                                <template v-if="d_taskList[task_item.task_index] && d_taskList[task_item.task_index].data && d_taskList[task_item.task_index].data.general && d_taskList[task_item.task_index].data.general.work_time && d_taskList[task_item.task_index].data.general.work_time.list && d_taskList[task_item.task_index].data.general.work_time.list.length > 0">
                                  <div v-for="(work_time, worktime_ind) in d_taskList[task_item.task_index].data.general.work_time.list" @click="f_showWorkTime(task_item.task_index)">
                                    <template v-if="(work_time.start && work_time.start.val && work_time.start.val.split(' ')[0] === task_date) || (work_time.end && work_time.end.val && work_time.end.val.split(' ')[0] === task_date)">
                                      <i class="fa fa-clock-o"></i>
                                      <span v-if="work_time.start && work_time.start.val && work_time.start.val.split(' ')[0] === task_date">
                                        {{ DateFormatWithTime(work_time.start.val, 'hour_and_minute') }}
                                      </span>
                                      <span v-if="work_time.end && work_time.end.val && work_time.end.val.split(' ')[0] === task_date">
                                        {{ DateFormatWithTime(work_time.end.val, 'hour_and_minute') }}
                                      </span>
                                    </template>
                                  </div>
                                </template>
                              </div>
                              <div v-if="task_date === d_todayDate" @click="f_showWorkTime(task_item.task_index, 'this_date', task_date)">
                                <!-- <img src="@/icon/2707569.png" width="20px" style="border-radius: 3px; cursor: pointer;" /> -->
                              </div>
                              <div v-if="d_taskBetweenDesiredStartAndEndList['task_list'][d_taskList[task_item.task_index].id] && d_taskBetweenDesiredStartAndEndList['task_list'][d_taskList[task_item.task_index].id].date_list.indexOf(task_date) !== -1">
                                <i class="fa fa-flag-o"></i> {{ DateFormatWithTime(d_taskList[task_item.task_index].data.general.desired_begin_date.val, 'hour_and_minute') }}
                              </div>
                              <div v-if="d_taskDesiredDayToday['task_list'][d_taskList[task_item.task_index].id] === task_date">
                                <i class="fa fa-flag-checkered"></i> {{ DateFormatWithTime(d_taskList[task_item.task_index].data.general.desired_end_date.val, 'hour_and_minute') }}
                              </div>
                            </td>
                          </template>
                        </tr>
                      </template>
                    </template>
                    <tr v-if="d_workTimeTaskList.tags[tag].task_list.length > 0">
                      <td colspan="6">
                        <div style="background: #ecfef8; min-width: 100px; margin: 5px; border-radius: 2px 2px; padding-left: 5px;">
                          <img src="@/icon/1421585.png" width="20px" style="border-radius: 3px; cursor: pointer;" />
                          <strong>Alt Etiket Yok</strong>
                        </div>
                      </td>
                    </tr>
                    <template v-for="(task_item, task_item_index) in d_workTimeTaskList.tags[tag].task_list">
                      <tr v-if="d_taskList[task_item.task_index] && d_taskList[task_item.task_index].id" @click="d_workTimeTaskList.selected_task_index = task_item.task_index; $forceUpdate();" :style="d_workTimeTaskList.selected_task_index === task_item.task_index ? 'background: #ecffe7;' : ''">
                        <td style="border-left: solid 1px #e9f4f1; min-width: 100px;">
                          <strong> {{ d_taskOrganizationMatch['task_list'] && d_taskList[task_item.task_index] && d_taskList[task_item.task_index].id && d_taskOrganizationMatch['task_list'][d_taskList[task_item.task_index].id] ? d_taskOrganizationMatch['task_list'][d_taskList[task_item.task_index].id]['label'] : '---' }} </strong>
                        </td>
                        <td style="border-left: solid 1px #e9f4f1; min-width: 100px;">
                          <div>
                            <template v-if="task_item.task_index !== '' && d_taskList[task_item.task_index] && d_taskList[task_item.task_index].data && d_taskList[task_item.task_index].data.general">
                              <b-row>
                                <b-col cols="12">
                                  <b-dropdown variant="white" size="sm" style="width: 100%; float: left;">
                                    <template slot="button-content">
                                      <div style="padding: 0px; margin: 0px;">
                                        <template v-if="d_taskList[task_item.task_index].data && d_taskList[task_item.task_index].data.general && d_taskList[task_item.task_index].data.general.task_type && d_taskList[task_item.task_index].data.general.task_type.val && d_taskList[task_item.task_index].data.general.task_type.val.label">
                                          {{ d_taskList[task_item.task_index].id }}
                                        </template>
                                      </div>
                                    </template>
                                    <b-dropdown-item @click="f_showTaskPreviewModal(d_taskList[task_item.task_index].id)"><i class="fa fa-eye"></i> görevi görüntüle</b-dropdown-item>
                                    <b-dropdown-item @click="f_showComments(task_item.task_index, 'all_task_list')"><i class="fa fa-edit"></i> yorumları görüntüle</b-dropdown-item>
                                    <b-dropdown-item @click="f_showWorkTime(task_item.task_index)"><i class="fa fa-calendar"></i> operasyon işlemleri</b-dropdown-item>
                                    <b-dropdown-item @click="f_editOperation(task_item.task_index)"><i class="fa fa-edit"></i> operasyonu düzenle</b-dropdown-item>
                                    <b-dropdown-item @click="f_showTagOperation(task_item.task_index)"><i class="fa fa-edit"></i> etiketler </b-dropdown-item>
                                    <b-dropdown-item @click="f_sendAllUsersMailAboutThisOperation(d_taskList[task_item.task_index], 'yes')"><i class="fa fa-edit"></i> bilgilendirme maili </b-dropdown-item>
                                    <b-dropdown-item @click="f_addNewOperationWithThisTag(tag, d_taskList[task_item.task_index])"><i class="fa fa-edit"></i> aynı etiketle aynı kullanıcıları seçerek yeni operasyon oluştur </b-dropdown-item>
                                  </b-dropdown>
                                </b-col>
                              </b-row>
                            </template>
                          </div>
                        </td>
                        <td style="border-left: solid 1px #e9f4f1; min-width: 100px;">
                          <div>
                            <template v-if="task_item.task_index !== '' && d_taskList[task_item.task_index] && d_taskList[task_item.task_index].data && d_taskList[task_item.task_index].data.general">
                              <b-row>
                                <b-col cols="12">
                                  <div v-if="d_taskList[task_item.task_index].data.general.task_type" style="padding: 3px; font-size: 10px; float: left; min-width: 75px; font-weight: bold;" :title="d_taskList[task_item.task_index].data.general.task_type.val.label"> {{ d_taskList[task_item.task_index].data.general.task_type && d_taskList[task_item.task_index].data.general.task_type.val.label.length > 50 ? d_taskList[task_item.task_index].data.general.task_type.val.label.substring(0, 50) + '...' : d_taskList[task_item.task_index].data.general.task_type.val.label }} </div>
                                  <div v-if="d_taskList[task_item.task_index].data.general.priority" :style="d_taskPriorityTextStyle.task_list[d_taskList[task_item.task_index].id]" :title="d_taskList[task_item.task_index].data.general.priority.val.label"> {{ d_taskList[task_item.task_index].data.general.priority && d_taskList[task_item.task_index].data.general.priority.val.label.length > 50 ? d_taskList[task_item.task_index].data.general.priority.val.label.substring(0, 50) + '...' : d_taskList[task_item.task_index].data.general.priority.val.label }} </div>
                                </b-col>
                              </b-row>
                            </template>
                          </div>
                        </td>
                        <td style="border-left: solid 1px #e9f4f1; min-width: 300px;">
                          <div>
                            <template v-if="task_item.task_index !== '' && d_taskList[task_item.task_index] && d_taskList[task_item.task_index].data && d_taskList[task_item.task_index].data.general">
                              <b-row>
                                <b-col cols="12">
                                  <div @click="f_showTaskPreviewModal(d_taskList[task_item.task_index].id)" style="cursor: pointer;">
                                    {{ d_taskList[task_item.task_index].label }}
                                  </div>
                                </b-col>
                              </b-row>
                            </template>
                          </div>
                        </td>
                        <td style="border-left: solid 1px #e9f4f1; min-width: 100px;">
                          <div>
                            <template v-if="task_item.task_index !== '' && d_taskList[task_item.task_index] && d_taskList[task_item.task_index].data && d_taskList[task_item.task_index].data.general">
                              <b-row>
                                <b-col cols="12">
                                  <span v-if="d_taskList[task_item.task_index].data.general.assigned_user_title && d_taskList[task_item.task_index].data.general.assigned_user_title.val && d_taskList[task_item.task_index].data.general.assigned_user_title.val.label" style="color: #2196f3;" :title="d_taskList[task_item.task_index].data.general.assigned_user_title.val.label"> {{ d_taskList[task_item.task_index].data.general.assigned_user_title.val.label.length > 20 ? d_taskList[task_item.task_index].data.general.assigned_user_title.val.label.substring(0,20) + '...' : d_taskList[task_item.task_index].data.general.assigned_user_title.val.label }} </span> {{ f_getAssignedBy(d_taskList[task_item.task_index]) }}
                                </b-col>
                              </b-row>
                            </template>
                          </div>
                        </td>
                        <td style="border-left: solid 1px #e9f4f1; min-width: 100px;">
                          <div v-if="d_taskList[task_item.task_index]" :style="d_taskCellStyleList[d_taskList[task_item.task_index].id].without_border">
                            <template v-if="task_item.task_index !== '' && d_taskList[task_item.task_index] && d_taskList[task_item.task_index].data && d_taskList[task_item.task_index].data.general">
                              <b-row>
                                <b-col cols="12">
                                  <div v-if="d_taskList[task_item.task_index].data.general.status && d_taskList[task_item.task_index].data.general.status.val && d_taskList[task_item.task_index].data.general.status.val.value" :title="d_taskList[task_item.task_index].data.general.status.val.label" @click="f_openStatusModal(task_item.task_index)"> {{ d_taskList[task_item.task_index].data.general.status.val.label.length > 13 ? d_taskList[task_item.task_index].data.general.status.val.label.substring(0, 10) + '...' : d_taskList[task_item.task_index].data.general.status.val.label }} </div>
                                  <div v-else @click="f_openStatusModal(task_item.task_index)" style="cursor: pointer; border: solid 1px black; text-align: center;">
                                    tanımsız
                                  </div>
                                </b-col>
                              </b-row>
                            </template>
                          </div>
                        </td>
                        <td style="border-left: solid 1px #e9f4f1; min-width: 100px;">
                          <div>
                            <template v-if="task_item.task_index !== '' && d_taskList[task_item.task_index] && d_taskList[task_item.task_index].data && d_taskList[task_item.task_index].data.general">
                              <b-row>
                                <b-col cols="12">
                                  <b-row>
                                    <b-col cols="12">
                                      <div v-if="d_taskList[task_item.task_index].date" style="text-align: left; font-size: 10px; float: left;">
                                        {{ DateFormatWithTime(d_taskList[task_item.task_index].date) }};
                                      </div>
                                      <div v-if="d_taskList[task_item.task_index].ending_date" style="text-align: left; font-size: 10px; float: left;">
                                        {{ DateFormatWithTime(d_taskList[task_item.task_index].ending_date) }}
                                      </div>
                                      <div class="task-comment" @click="f_showComments(task_item.task_index, 'all_task_list')">
                                        <i class="fa fa-comment-o"></i> {{ d_taskCommentSeenList[d_taskList[task_item.task_index].id] }}
                                      </div>
                                      <div class="task-worktime" @click="f_showWorkTime(task_item.task_index)">
                                        <i class="fa fa-clock-o"></i>
                                        <template v-if="d_taskList[task_item.task_index].data && d_taskList[task_item.task_index].data.general && d_taskList[task_item.task_index].data.general.work_time && d_taskList[task_item.task_index].data.general.work_time.list && d_taskList[task_item.task_index].data.general.work_time.list.length > 0">
                                          {{ d_taskList[task_item.task_index].data.general.work_time.list.length }}
                                        </template>
                                        <template v-else>
                                          0
                                        </template>
                                      </div>
                                      <div class="task-comment" @click="f_showDocuments(d_taskList[task_item.task_index], task_item.task_index)">
                                        <i class="fa fa-book"></i> {{ d_taskList[task_item.task_index].document_list ? d_taskList[task_item.task_index].document_list.length : '' }}
                                      </div>
                                      <div v-if="d_taskList[task_item.task_index].data && d_taskList[task_item.task_index].data.general && d_taskList[task_item.task_index].data.general.useful_link && d_taskList[task_item.task_index].data.general.useful_link.val" class="operation-useful-link" :title="d_taskList[task_item.task_index].data.general.useful_link.val" @click="f_goToLink(d_taskList[task_item.task_index].data.general.useful_link.val)">
                                        <img :href="d_taskList[task_item.task_index].data.general.useful_link.val" target="_blank" src="@/icon/201295.png" width="20px" style="border-radius: 3px; cursor: pointer;" />
                                      </div>
                                      <div class="operation-tag" @click="f_showTagOperation(task_item.task_index)">
                                        <i class="fa fa-tag"></i>
                                      </div>
                                      <template v-if="d_taskList[task_item.task_index].data && d_taskList[task_item.task_index].data.general && d_taskList[task_item.task_index].data.general.tag && d_taskList[task_item.task_index].data.general.tag.list && d_taskList[task_item.task_index].data.general.tag.list.length > 0">
                                      </template>
                                    </b-col>
                                  </b-row>
                                </b-col>
                              </b-row>
                            </template>
                          </div>
                        </td>
                        <template v-for="(task_date, task_date_ind) in d_workTimeTaskList.work_time_date_order">
                          <td :style="task_date === d_todayDate ? 'background : rgb(65, 220, 220); border-left: solid 1px #e9f4f1; min-width: 100px;' : 'background : white; border-left: solid 1px #e9f4f1; min-width: 100px;'">
                            <template v-if="d_workTimeTaskList.log_status_list[task_date] && d_workTimeTaskList.log_status_list[task_date][task_item.task_id] && d_workTimeTaskList.log_status_list[task_date][task_item.task_id].log_status_list.length > 0">
                              <div v-for="(lg, lg_index) in d_workTimeTaskList.log_status_list[task_date][task_item.task_id].log_status_list" @click="f_showTaskPreviewModal(task_item.task_id)" :title="lg.label">
                                <div> {{ lg.label }} </div>
                                <!-- <div :style="f_calculateStatusCellStyle('', lg.value)">
                                  {{ lg.label.length > 10 ? lg.label.substring(0,10) + '..' : lg.label }}
                                </div> -->
                              </div>
                            </template>
                            <div v-if="f_isTaskHasWorkTimeInThisDay(d_taskList[task_item.task_index], task_date)">
                              <template v-if="d_taskList[task_item.task_index] && d_taskList[task_item.task_index].data && d_taskList[task_item.task_index].data.general && d_taskList[task_item.task_index].data.general.work_time && d_taskList[task_item.task_index].data.general.work_time.list && d_taskList[task_item.task_index].data.general.work_time.list.length > 0">
                                <div v-for="(work_time, worktime_ind) in d_taskList[task_item.task_index].data.general.work_time.list" @click="f_showWorkTime(task_item.task_index)">
                                  <template v-if="(work_time.start && work_time.start.val && work_time.start.val.split(' ')[0] === task_date) || (work_time.end && work_time.end.val && work_time.end.val.split(' ')[0] === task_date)">
                                    <i class="fa fa-clock-o"></i>
                                    <span v-if="work_time.start && work_time.start.val && work_time.start.val.split(' ')[0] === task_date">
                                      {{ DateFormatWithTime(work_time.start.val, 'hour_and_minute') }}
                                    </span>
                                    <span v-if="work_time.end && work_time.end.val && work_time.end.val.split(' ')[0] === task_date">
                                      {{ DateFormatWithTime(work_time.end.val, 'hour_and_minute') }}
                                    </span>
                                  </template>
                                </div>
                              </template>
                            </div>
                            <div v-if="task_date === d_todayDate" @click="f_showWorkTime(task_item.task_index, 'this_date', task_date)">
                              <!-- <img src="@/icon/2707569.png" width="20px" style="border-radius: 3px; cursor: pointer;" /> -->
                            </div>
                            <div v-if="d_taskBetweenDesiredStartAndEndList['task_list'][d_taskList[task_item.task_index].id] && d_taskBetweenDesiredStartAndEndList['task_list'][d_taskList[task_item.task_index].id].date_list.indexOf(task_date) !== -1">
                              <i class="fa fa-flag-o"></i> {{ DateFormatWithTime(d_taskList[task_item.task_index].data.general.desired_begin_date.val, 'hour_and_minute') }}
                            </div>
                            <div v-if="d_taskDesiredDayToday['task_list'][d_taskList[task_item.task_index].id] === task_date">
                              <i class="fa fa-flag-checkered"></i> {{ DateFormatWithTime(d_taskList[task_item.task_index].data.general.desired_end_date.val, 'hour_and_minute') }}
                            </div>
                          </td>
                        </template>
                      </tr>
                    </template>
                  </template>
                  <tr>
                    <td colspan="5">
                      <div style="background: #d9fdff; min-width: 100px; margin: 5px; border-radius: 2px 2px;">
                        <img src="@/icon/3162318.png" width="20px" style="border-radius: 3px; cursor: pointer;" />
                        <strong>Etiketli olmayan</strong>
                      </div>
                    </td>
                  </tr>
                  <template v-for="(task_item, task_item_index) in d_workTimeTaskList.not_tag_including_task_list">
                    <tr v-if="d_taskList[task_item.task_index] && d_taskList[task_item.task_index].id" @click="d_workTimeTaskList.selected_task_index = task_item.task_index; $forceUpdate();" :style="d_workTimeTaskList.selected_task_index === task_item.task_index ? 'background: #ecffe7;' : ''">
                      <td style="border-left: solid 1px #e9f4f1; min-width: 100px;">
                        <strong> {{ d_taskOrganizationMatch['task_list'] && d_taskList[task_item.task_index] && d_taskList[task_item.task_index].id && d_taskOrganizationMatch['task_list'][d_taskList[task_item.task_index].id] ? d_taskOrganizationMatch['task_list'][d_taskList[task_item.task_index].id]['label'] : '---' }} </strong>
                      </td>
                      <td style="border-left: solid 1px #e9f4f1; min-width: 100px;">
                        <div>
                          <template v-if="task_item.task_index !== '' && d_taskList[task_item.task_index] && d_taskList[task_item.task_index].data && d_taskList[task_item.task_index].data.general">
                            <b-row>
                              <b-col cols="12">
                                <b-dropdown variant="white" size="sm" style="width: 100%; float: left;">
                                  <template slot="button-content">
                                    <div style="padding: 0px; margin: 0px;">
                                      <template v-if="d_taskList[task_item.task_index].data && d_taskList[task_item.task_index].data.general && d_taskList[task_item.task_index].data.general.task_type && d_taskList[task_item.task_index].data.general.task_type.val && d_taskList[task_item.task_index].data.general.task_type.val.label">
                                        {{ d_taskList[task_item.task_index].id }}
                                      </template>
                                    </div>
                                  </template>
                                  <b-dropdown-item @click="f_showTaskPreviewModal(d_taskList[task_item.task_index].id)"><i class="fa fa-eye"></i> görevi görüntüle</b-dropdown-item>
                                  <b-dropdown-item @click="f_showComments(task_item.task_index, 'all_task_list')"><i class="fa fa-edit"></i> yorumları görüntüle</b-dropdown-item>
                                  <b-dropdown-item @click="f_showWorkTime(task_item.task_index)"><i class="fa fa-calendar"></i> operasyon işlemleri</b-dropdown-item>
                                  <b-dropdown-item @click="f_editOperation(task_item.task_index)"><i class="fa fa-edit"></i> operasyonu düzenle</b-dropdown-item>
                                  <b-dropdown-item @click="f_showTagOperation(task_item.task_index)"><i class="fa fa-edit"></i> etiketler </b-dropdown-item>
                                  <b-dropdown-item @click="f_sendAllUsersMailAboutThisOperation(d_taskList[task_item.task_index], 'yes')"><i class="fa fa-edit"></i> bilgilendirme maili </b-dropdown-item>
                                </b-dropdown>
                              </b-col>
                            </b-row>
                          </template>
                        </div>
                      </td>
                      <td style="border-left: solid 1px #e9f4f1; min-width: 100px;">
                        <div>
                          <template v-if="task_item.task_index !== '' && d_taskList[task_item.task_index] && d_taskList[task_item.task_index].data && d_taskList[task_item.task_index].data.general">
                            <b-row>
                              <b-col cols="12">
                                <div v-if="d_taskList[task_item.task_index].data.general.task_type" style="padding: 3px; font-size: 10px; float: left; min-width: 75px; font-weight: bold;" :title="d_taskList[task_item.task_index].data.general.task_type.val.label"> {{ d_taskList[task_item.task_index].data.general.task_type && d_taskList[task_item.task_index].data.general.task_type.val.label.length > 50 ? d_taskList[task_item.task_index].data.general.task_type.val.label.substring(0, 50) + '...' : d_taskList[task_item.task_index].data.general.task_type.val.label }} </div>
                                <div v-if="d_taskList[task_item.task_index].data.general.priority" :style="d_taskPriorityTextStyle.task_list[d_taskList[task_item.task_index].id]" :title="d_taskList[task_item.task_index].data.general.priority.val.label"> {{ d_taskList[task_item.task_index].data.general.priority && d_taskList[task_item.task_index].data.general.priority.val.label.length > 50 ? d_taskList[task_item.task_index].data.general.priority.val.label.substring(0, 50) + '...' : d_taskList[task_item.task_index].data.general.priority.val.label }} </div>
                              </b-col>
                            </b-row>
                          </template>
                        </div>
                      </td>
                      <td style="border-left: solid 1px #e9f4f1; min-width: 300px;">
                        <div>
                          <template v-if="task_item.task_index !== '' && d_taskList[task_item.task_index] && d_taskList[task_item.task_index].data && d_taskList[task_item.task_index].data.general">
                            <b-row>
                              <b-col cols="12">
                                <div @click="f_showTaskPreviewModal(d_taskList[task_item.task_index].id)" style="cursor: pointer;">
                                  {{ d_taskList[task_item.task_index].label }}
                                </div>
                              </b-col>
                            </b-row>
                          </template>
                        </div>
                      </td>
                      <td style="border-left: solid 1px #e9f4f1; min-width: 100px;">
                        <div>
                          <template v-if="task_item.task_index !== '' && d_taskList[task_item.task_index] && d_taskList[task_item.task_index].data && d_taskList[task_item.task_index].data.general">
                            <b-row>
                              <b-col cols="12">
                                <span v-if="d_taskList[task_item.task_index].data.general.assigned_user_title && d_taskList[task_item.task_index].data.general.assigned_user_title.val && d_taskList[task_item.task_index].data.general.assigned_user_title.val.label" style="color: #2196f3;" :title="d_taskList[task_item.task_index].data.general.assigned_user_title.val.label"> {{ d_taskList[task_item.task_index].data.general.assigned_user_title.val.label.length > 20 ? d_taskList[task_item.task_index].data.general.assigned_user_title.val.label.substring(0,20) + '...' : d_taskList[task_item.task_index].data.general.assigned_user_title.val.label }} </span> {{ f_getAssignedBy(d_taskList[task_item.task_index]) }}
                              </b-col>
                            </b-row>
                          </template>
                        </div>
                      </td>
                      <td style="border-left: solid 1px #e9f4f1; min-width: 100px;">
                        <div v-if="d_taskList[task_item.task_index]" :style="d_taskCellStyleList[d_taskList[task_item.task_index].id].without_border">
                          <template v-if="task_item.task_index !== '' && d_taskList[task_item.task_index] && d_taskList[task_item.task_index].data && d_taskList[task_item.task_index].data.general">
                            <b-row>
                              <b-col cols="12">
                                <div v-if="d_taskList[task_item.task_index].data.general.status && d_taskList[task_item.task_index].data.general.status.val && d_taskList[task_item.task_index].data.general.status.val.value" :title="d_taskList[task_item.task_index].data.general.status.val.label" @click="f_openStatusModal(task_item.task_index)"> {{ d_taskList[task_item.task_index].data.general.status.val.label.length > 13 ? d_taskList[task_item.task_index].data.general.status.val.label.substring(0, 10) + '...' : d_taskList[task_item.task_index].data.general.status.val.label }} </div>
                                <div v-else @click="f_openStatusModal(task_item.task_index)" style="cursor: pointer; border: solid 1px black; text-align: center;">
                                  tanımsız
                                </div>
                              </b-col>
                            </b-row>
                          </template>
                        </div>
                      </td>
                      <td style="border-left: solid 1px #e9f4f1; min-width: 100px;">
                        <div>
                          <template v-if="task_item.task_index !== '' && d_taskList[task_item.task_index] && d_taskList[task_item.task_index].data && d_taskList[task_item.task_index].data.general">
                            <b-row>
                              <b-col cols="12">
                                <b-row>
                                  <b-col cols="12">
                                    <div v-if="d_taskList[task_item.task_index].date" style="text-align: left; font-size: 10px; float: left;">
                                      {{ DateFormatWithTime(d_taskList[task_item.task_index].date) }};
                                    </div>
                                    <div v-if="d_taskList[task_item.task_index].ending_date" style="text-align: left; font-size: 10px; float: left;">
                                      {{ DateFormatWithTime(d_taskList[task_item.task_index].ending_date) }}
                                    </div>
                                    <div class="task-comment" @click="f_showComments(task_item.task_index, 'all_task_list')">
                                      <i class="fa fa-comment-o"></i> {{ d_taskCommentSeenList[d_taskList[task_item.task_index].id] }}
                                    </div>
                                    <div class="task-worktime" @click="f_showWorkTime(task_item.task_index)">
                                      <i class="fa fa-clock-o"></i>
                                      <template v-if="d_taskList[task_item.task_index].data && d_taskList[task_item.task_index].data.general && d_taskList[task_item.task_index].data.general.work_time && d_taskList[task_item.task_index].data.general.work_time.list && d_taskList[task_item.task_index].data.general.work_time.list.length > 0">
                                        {{ d_taskList[task_item.task_index].data.general.work_time.list.length }}
                                      </template>
                                      <template v-else>
                                        0
                                      </template>
                                    </div>
                                    <div class="task-comment" @click="f_showDocuments(d_taskList[task_item.task_index], task_item.task_index)">
                                      <i class="fa fa-book"></i> {{ d_taskList[task_item.task_index].document_list ? d_taskList[task_item.task_index].document_list.length : '' }}
                                    </div>
                                    <div v-if="d_taskList[task_item.task_index].data && d_taskList[task_item.task_index].data.general && d_taskList[task_item.task_index].data.general.useful_link && d_taskList[task_item.task_index].data.general.useful_link.val" class="operation-useful-link" :title="d_taskList[task_item.task_index].data.general.useful_link.val" @click="f_goToLink(d_taskList[task_item.task_index].data.general.useful_link.val)">
                                      <img :href="d_taskList[task_item.task_index].data.general.useful_link.val" target="_blank" src="@/icon/201295.png" width="20px" style="border-radius: 3px; cursor: pointer;" />
                                    </div>
                                    <div class="operation-tag" @click="f_showTagOperation(task_item.task_index)">
                                      <i class="fa fa-tag"></i>
                                    </div>
                                    <template v-if="d_taskList[task_item.task_index].data && d_taskList[task_item.task_index].data.general && d_taskList[task_item.task_index].data.general.tag && d_taskList[task_item.task_index].data.general.tag.list && d_taskList[task_item.task_index].data.general.tag.list.length > 0">
                                    </template>
                                  </b-col>
                                </b-row>
                              </b-col>
                            </b-row>
                          </template>
                        </div>
                      </td>
                      <template v-for="(task_date, task_date_ind) in d_workTimeTaskList.work_time_date_order">
                        <td :style="task_date === d_todayDate ? 'background : rgb(65, 220, 220); border-left: solid 1px #e9f4f1; min-width: 100px;' : 'background : white; border-left: solid 1px #e9f4f1; min-width: 100px;'">
                          <template v-if="d_workTimeTaskList.log_status_list[task_date] && d_workTimeTaskList.log_status_list[task_date][task_item.task_id] && d_workTimeTaskList.log_status_list[task_date][task_item.task_id].log_status_list.length > 0">
                            <div v-for="(lg, lg_index) in d_workTimeTaskList.log_status_list[task_date][task_item.task_id].log_status_list" @click="f_showTaskPreviewModal(task_item.task_id)" :title="lg.label">
                              <div> {{ lg.label }} </div>
                              <!-- <div :style="f_calculateStatusCellStyle('', lg.value)">
                                {{ lg.label.length > 10 ? lg.label.substring(0,10) + '..' : lg.label }}
                              </div> -->
                            </div>
                          </template>
                          <div v-if="f_isTaskHasWorkTimeInThisDay(d_taskList[task_item.task_index], task_date)">
                            <template v-if="d_taskList[task_item.task_index] && d_taskList[task_item.task_index].data && d_taskList[task_item.task_index].data.general && d_taskList[task_item.task_index].data.general.work_time && d_taskList[task_item.task_index].data.general.work_time.list && d_taskList[task_item.task_index].data.general.work_time.list.length > 0">
                              <div v-for="(work_time, worktime_ind) in d_taskList[task_item.task_index].data.general.work_time.list" @click="f_showWorkTime(task_item.task_index)">
                                <template v-if="(work_time.start && work_time.start.val && work_time.start.val.split(' ')[0] === task_date) || (work_time.end && work_time.end.val && work_time.end.val.split(' ')[0] === task_date)">
                                  <i class="fa fa-clock-o"></i>
                                  <span v-if="work_time.start && work_time.start.val && work_time.start.val.split(' ')[0] === task_date">
                                    {{ DateFormatWithTime(work_time.start.val, 'hour_and_minute') }}
                                  </span>
                                  <span v-if="work_time.end && work_time.end.val && work_time.end.val.split(' ')[0] === task_date">
                                    {{ DateFormatWithTime(work_time.end.val, 'hour_and_minute') }}
                                  </span>
                                </template>
                              </div>
                            </template>
                          </div>
                          <div v-if="task_date === d_todayDate" @click="f_showWorkTime(task_item.task_index, 'this_date', task_date)">
                            <!-- <img src="@/icon/2707569.png" width="20px" style="border-radius: 3px; cursor: pointer;" /> -->
                          </div>
                          <div v-if="d_taskBetweenDesiredStartAndEndList['task_list'][d_taskList[task_item.task_index].id] && d_taskBetweenDesiredStartAndEndList['task_list'][d_taskList[task_item.task_index].id].date_list.indexOf(task_date) !== -1">
                            <i class="fa fa-flag-o"></i> {{ DateFormatWithTime(d_taskList[task_item.task_index].data.general.desired_begin_date.val, 'hour_and_minute') }}
                          </div>
                          <div v-if="d_taskDesiredDayToday['task_list'][d_taskList[task_item.task_index].id] === task_date">
                            <i class="fa fa-flag-checkered"></i> {{ DateFormatWithTime(d_taskList[task_item.task_index].data.general.desired_end_date.val, 'hour_and_minute') }}
                          </div>
                        </td>
                      </template>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </template>
      </b-tab>
      

      <b-tab v-if="!p_settings || (p_settings && (!p_settings.selected_tab || p_settings.selected_tab === 'date_and_card'))" @click="d_selectedTab = 'date_and_card'" :active="d_selectedTab === 'date_and_card'">
        <template slot="title">
          <img src="@/icon/665778.png" style="width: 1.5em;">
          <span :style="'color:' + d_menuData.style.tab_header.color">Tarih Kart</span>
        </template>
        <template v-if="d_selectedTab === 'date_and_card'">
          <div no-body :class="[d_fullScreenOperationPage ? 'full-screen-mode' : 'normal-mode']">
            <b-row>
              <b-col sm="12" lg="3">
                <!-- 
                  <b-button v-if="d_organizationListData.selected_index !== ''" @click="f_newOperation()" variant="white" size="sm">
                    <img src="@/icon/2766751.png" class="img-rounded img-responsive" style="width: 2em;" title="Yeni operasyon eklemek için tıklayınız." /> yeni
                  </b-button>
                  <b-button @click="d_operationFunctions.show = true" variant="white" size="sm">
                    <img src="@/icon/1517831.png" class="img-rounded img-responsive" style="width: 2em;" title="İşlemler için tıklayınız." /> işlemler
                  </b-button>
                 -->
                <b-form-input style="width: 100%; padding: 1px; font-size: 10px;" v-model="d_filter.search_text" type="text" placeholder="operasyon adı veya açıklaması" @dblclick="d_filter.search_text = ''"></b-form-input>
              </b-col>
              <b-col sm="12" lg="2">
                <b-form-input style="width: 100%; padding: 1px; font-size: 10px;" v-model="d_filter.operation_no" type="text" placeholder="operasyon no ile filtreleme" @dblclick="d_filter.operation_no = ''"></b-form-input>
              </b-col>
              <b-col sm="12" lg="2">
                {{ d_workTimeTaskList.count }}
                <img v-if="d_loading.status" src="@/icon/sandwatch.gif" style="width: 2em;" />
              </b-col>
              <b-col sm="12" lg="1">
                <b-button style="width: 100%;" variant="white" @click="f_getWdm17ListByControllingOrganizationSelection(); d_operationFunctions.show = false;">
                  <div style="text-align: left;">
                    <img src="@/icon/3012385.png" style="width: 2em;" />
                  </div>
                </b-button>
              </b-col>
              <b-col sm="12" lg="3">
                <!-- <b-pagination v-if="d_organizationListData.selected_index !== ''" size="sm" class="pull-right" style="margin-bottom: 0px;" aria-controls="pagination" v-model="d_pagination.current" :total-rows="d_taskListCount" :per-page="d_pagination.perpage"></b-pagination> -->
              </b-col>
              <b-col sm="12" lg="1">
                <b-button class="pull-right" size="md" :variant="d_fullScreenOperationPage ? 'warning' : 'white'" :style="d_fullScreenOperationPage ? 'margin-right: 5px; padding: 0px; border: solid 2px green;' : 'margin-right: 5px; padding: 0px;'" @click="d_fullScreenOperationPage ? d_fullScreenOperationPage = false : d_fullScreenOperationPage = true">
                  <img src="@/icon/629153.png" title="Tam ekran" style="width: 2em;" />
                </b-button>
              </b-col>
            </b-row>
            <div id="timeline" :style="f_calculateBottomTabStyle()" class="general_scroll_class">
              <table>
                <thead>
                  <tr>
                    <template v-for="(task_date, task_date_ind) in d_taskTimeLine.date_order">
                      <th style="position: sticky; z-index: 1000; top: 0; text-align: center; background: rgba(252, 0, 153, 0.5);">
                        {{ d_taskTimeLine.date_list[task_date].date_show }} [ {{ d_taskTimeLine.date_list[task_date].task_count }} ]
                      </th>
                    </template>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="row in d_taskTimeLine.table">
                    <template v-for="(col, col_ind) in row">
                      <td style="vertical-align: top;">
                        <div v-if="col.task_ind !== '' && d_taskList[col.task_ind] && d_taskList[col.task_ind].data && d_taskList[col.task_ind].data.general" :style="d_taskCellStyleList[d_taskList[col.task_ind].id].with_border">
                          <b-row>
                            <b-col cols="12">
                              <b-dropdown variant="white" size="sm" style="width: 100%; float: left;">
                                <template slot="button-content">
                                  <div style="padding: 0px; margin: 0px;">
                                    <template v-if="d_taskList[col.task_ind].data && d_taskList[col.task_ind].data.general && d_taskList[col.task_ind].data.general.task_type && d_taskList[col.task_ind].data.general.task_type.val && d_taskList[col.task_ind].data.general.task_type.val.label">
                                      <div style="padding: 3px; font-size: 10px; float: left; min-width: 75px" :title="d_taskList[col.task_ind].data.general.task_type.val.label"> {{ d_taskList[col.task_ind].data.general.task_type.val.label.length > 13 ? d_taskList[col.task_ind].data.general.task_type.val.label.substring(0, 10) + '...' : d_taskList[col.task_ind].data.general.task_type.val.label }} </div>
                                    </template>
                                  </div>
                                </template>
                                <b-dropdown-item @click="f_showTaskPreviewModal(d_taskList[col.task_ind].id)"><i class="fa fa-eye"></i> görevi görüntüle</b-dropdown-item>
                                <b-dropdown-item @click="f_showComments(col.task_ind, 'all_task_list')"><i class="fa fa-edit"></i> yorumları görüntüle</b-dropdown-item>
                                <b-dropdown-item @click="f_showWorkTime(col.task_ind)"><i class="fa fa-calendar"></i> operasyon işlemleri</b-dropdown-item>
                                <b-dropdown-item @click="f_editOperation(col.task_ind)"><i class="fa fa-edit"></i> operasyonu düzenle</b-dropdown-item>
                                <b-dropdown-item @click="f_showTagOperation(col.task_ind)"><i class="fa fa-edit"></i> etiketler </b-dropdown-item>
                                <b-dropdown-item @click="f_sendAllUsersMailAboutThisOperation(d_taskList[col.task_ind], 'yes')"><i class="fa fa-edit"></i> bilgilendirme maili </b-dropdown-item>
                              </b-dropdown>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col cols="12">{{ d_taskList[col.task_ind].id }}</b-col>
                          </b-row>
                          <b-row>
                            <b-col cols="12">
                              <div @click="f_showTaskPreviewModal(d_taskList[col.task_ind].id)" style="cursor: pointer;" :title="d_taskList[col.task_ind].label">
                                {{ d_taskList[col.task_ind].label.length > 50 ? d_taskList[col.task_ind].label.substring(0,50) + '...' : d_taskList[col.task_ind].label }}
                              </div>
                            </b-col>
                          </b-row>
                          <!--
                                <b-row>
                                  <b-col cols="12">
                                    <div style="text-align: left; padding: 2px; margin: 2px; font-size: 10px; width: 100%; cursor: pointer;">
                                      {{ f_getAssignedBy(d_taskList[col.task_ind]) }}
                                    </div>
                                  </b-col>
                                </b-row>
                               -->
                          <b-row>
                            <b-col cols="12">
                              <b-row :style="d_taskCellStyleList[d_taskList[col.task_ind].id].without_border">
                                <b-col cols="12">
                                  <div v-if="d_taskList[col.task_ind].data.general.status && d_taskList[col.task_ind].data.general.status.val && d_taskList[col.task_ind].data.general.status.val.value" style="cursor: pointer; text-align: center;" :title="d_taskList[col.task_ind].data.general.status.val.label" @click="f_openStatusModal(col.task_ind)"> {{ d_taskList[col.task_ind].data.general.status.val.label.length > 13 ? d_taskList[col.task_ind].data.general.status.val.label.substring(0, 10) + '...' : d_taskList[col.task_ind].data.general.status.val.label }} </div>
                                  <div v-else @click="f_openStatusModal(col.task_ind)" style="cursor: pointer; text-align: center;">
                                    tanımsız
                                  </div>
                                </b-col>
                              </b-row>
                              <b-row>
                                <b-col cols="12">
                                  <div v-if="d_taskList[col.task_ind].date" style="text-align: left; font-size: 10px; float: left;">
                                    {{ DateFormatWithTime(d_taskList[col.task_ind].date) }};
                                  </div>
                                  <div v-if="d_taskList[col.task_ind].ending_date" style="text-align: left; font-size: 10px; float: left;">
                                    {{ DateFormatWithTime(d_taskList[col.task_ind].ending_date) }}
                                  </div>
                                  <div class="task-comment" @click="f_showComments(col.task_ind, 'all_task_list')">
                                    <i class="fa fa-comment-o"></i> {{ d_taskCommentSeenList[d_taskList[col.task_ind].id] }}
                                  </div>
                                  <div class="task-worktime" @click="f_showWorkTime(col.task_ind)">
                                    <i class="fa fa-clock-o"></i>
                                    <template v-if="d_taskList[col.task_ind].data && d_taskList[col.task_ind].data.general && d_taskList[col.task_ind].data.general.work_time && d_taskList[col.task_ind].data.general.work_time.list && d_taskList[col.task_ind].data.general.work_time.list.length > 0">
                                      {{ d_taskList[col.task_ind].data.general.work_time.list.length }}
                                    </template>
                                    <template v-else>
                                      0
                                    </template>
                                  </div>
                                  <div class="task-comment" @click="f_showDocuments(d_taskList[col.task_ind], col.task_ind)">
                                    <i class="fa fa-book"></i> {{ d_taskList[col.task_ind].document_list ? d_taskList[col.task_ind].document_list.length : '' }}
                                  </div>
                                  <div v-if="d_taskList[col.task_ind].data && d_taskList[col.task_ind].data.general && d_taskList[col.task_ind].data.general.useful_link && d_taskList[col.task_ind].data.general.useful_link.val" class="operation-useful-link" :title="d_taskList[col.task_ind].data.general.useful_link.val" @click="f_goToLink(d_taskList[col.task_ind].data.general.useful_link.val)">
                                    <img :href="d_taskList[col.task_ind].data.general.useful_link.val" target="_blank" src="@/icon/201295.png" width="20px" style="border-radius: 3px; cursor: pointer;" />
                                  </div>
                                  <div class="operation-tag" @click="f_showTagOperation(col.task_ind)">
                                    <i class="fa fa-tag"></i>
                                  </div>
                                  <template v-if="d_taskList[col.task_ind].data && d_taskList[col.task_ind].data.general && d_taskList[col.task_ind].data.general.tag && d_taskList[col.task_ind].data.general.tag.list && d_taskList[col.task_ind].data.general.tag.list.length > 0">
                                    <div v-for="(tag, tag_ind) in d_taskList[col.task_ind].data.general.tag.list" class="operation-tag" @click="f_showTagOperation(col.task_ind)">
                                      {{ tag.tag_name.val }}
                                    </div>
                                  </template>
                                </b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                        </div>
                      </td>
                    </template>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </template>
      </b-tab>

    

      <b-tab v-if="d_selectedWdmr307 && d_selectedWdmr307.id" @click="d_selectedTab = 'organization_details'" :active="d_selectedTab === 'organization_details'">
        <template slot="title">
          <img src="@/icon/2448909.png" style="width: 1.5em;">
          <span :style="'color:' + d_menuData.style.tab_header.color">Organizasyon detayları</span>
        </template>
        <template v-if="d_selectedTab === 'organization_details'">
          <!-- <diagram :model="diagramModel"></diagram> -->
          <b-card no-body>
            <b-card-header class="p-1" :style="'min-height: 40px; color:' + d_menuData.style.tab_header.color + '; background-color: ' + d_menuData.style.tab_header.backgroundColor">
              <img src="@/icon/2448909.png" class="img-rounded img-responsive" style="width: 2em;" /> {{ d_selectedWdmr307.label ? d_selectedWdmr307.label : 'Detaylar' }}
            </b-card-header>
            <b-row v-if="d_WmanagerLayerData.organization.url_show_iframe">
              <b-col sm="12" md="12">
                <div>
                  <div class="wdmr-link" :title="d_WmanagerLayerData.organization.url_show_iframe" @click="f_goToLink(d_WmanagerLayerData.organization.url_show_iframe)">
                    <img :href="d_WmanagerLayerData.organization.url_show_iframe" target="_blank" src="@/icon/201295.png" width="20px" style="border-radius: 3px; cursor: pointer;" />
                  </div>
                  <iframe v-if="d_WmanagerLayerData.organization.url_show_iframe" :src="d_WmanagerLayerData.organization.url_show_iframe" style="width: 100%; min-height: 500px;"></iframe>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </template>
      </b-tab>
      <b-tab v-if="!p_settings || (p_settings && (!p_settings.selected_tab || p_settings.selected_tab === 'report_list'))" style="background-color: white;" @click="d_selectedTab = 'report_list'" :active="d_selectedTab === 'report_list'">
        <template slot="title">
          <img src="@/icon/2448909.png" style="width: 1.5em;">
          <span :style="'color:' + d_menuData.style.tab_header.color">Raporlar</span>
        </template>
        <template v-if="d_selectedTab === 'report_list'">
          <b-row v-if="d_reports.paramorder.length > 0">
            <b-col sm="12" lg="12">
              <template v-for="report_type in d_reports.paramorder">
                <template v-if="d_reports.parameters[report_type] && d_reports.parameters[report_type].report_list && d_reports.parameters[report_type].report_list.length > 0">
                  <analyze-report-view :p_analyzeReports="d_reports.parameters[report_type].report_list"></analyze-report-view>
                </template>
              </template>
            </b-col>
          </b-row>
        </template>
      </b-tab>
    </b-tabs>
    <!-- <b-row>
        <b-col cols="12">f
          <template v-if="d_totalLogCount > d_logResultPageData['perpage']">
            <b-pagination size="sm" v-model="d_logResultPageData['current']" :total-rows="d_totalLogCount" :per-page="d_logResultPageData['perpage']" aria-controls="log-table"></b-pagination>
          </template>
          <div id="case_table" :style="f_dataSetStyle(300)" tabindex="0">
          </div>
        </b-col>
      </b-row> -->
    <b-modal v-if="d_showTaskCommentsModal" v-model="d_showTaskCommentsModal" title="Yorumlar" centered class="modal-success" @ok="d_showTaskCommentsModal = false" ok-variant="success" hide-footer size="lg">
      <b-card no-body>
        <b-card-header header-bg-variant="white" header-text-variant="dark" class="p-1">
          <i class="fa fa-comment-o"></i> Yeni Yorum
          <span :title="d_taskList[selected_task_index].label"> {{ d_taskList[selected_task_index].label.substring(0, 50) }}... [ {{ d_taskList[selected_task_index].id }}] </span>
        </b-card-header>
        <b-row style="margin: 0px;">
          <b-col sm="10" md="10" style="padding: 0px;">
            <!-- <b-textarea id="write-comment" v-model="d_newTaskComment" placeholder="Lütfen yorumunuzu yazınız."></b-textarea> -->
            <ckeditor :editor="editor" v-model="d_newTaskComment" :config="editorConfig"></ckeditor>
          </b-col>
          <b-col sm="2" md="2" style="padding: 0px;">
            <b-button style="width: 100%; height: 100%;" variant="secondary" @click="f_addNewComment()">
              <i class="fa fa-comment-o"></i> ekle
            </b-button>
          </b-col>
        </b-row>
        <b-card no-body v-if="d_filteredWhoCanSeeUsers.length > 0" style="margin-left: 20px; margin-top: 10px; width: 300px;" @mouseleave="d_filteredWhoCanSeeUsers = []">
          <b-card-header header-bg-variant="secondary" header-text-variant="dark" class="p-1">
            Kişiler
          </b-card-header>
          <div style="overflow-x: hidden; overflow-y: auto; height: 250px; width: 300px; background: #c8ced380; padding: 5px;">
            <template v-for="(us, us_ind) in d_filteredWhoCanSeeUsers">
              <b-row :key="'d_filteredWhoCanSeeUsers_' + us_ind" class="auto-complete-row">
                <b-col sm="12" @click="f_completeUsername(us.value)">
                  <i class="fa fa-user"></i> {{ us.label }}
                </b-col>
              </b-row>
            </template>
          </div>
        </b-card>
      </b-card>
      <b-card no-body style="margin-bottom: 1px;">
        <b-card-header header-bg-variant="white" header-text-variant="dark" class="p-1">
          <i class="fa fa-comment-o"></i> Yorumlar
        </b-card-header>
        <template v-if="d_selectedTaskForComment.data && d_selectedTaskForComment.data.general && d_selectedTaskForComment.data.general.comment && d_selectedTaskForComment.data.general.comment.list && d_selectedTaskForComment.data.general.comment.list.length > 0">
          <div style="overflow-x: hidden; overflow-y: auto; height: 250px;">
            <template v-for="(com, com_ind) in d_selectedTaskForComment.data.general.comment.list">
              <b-card :key="'d_selectedTaskForComment.data.general.comment.list_' + com_ind" no-body style="margin: 3px;">
                <b-row>
                  <b-col sm="12" md="3">
                    <template v-if="d_allOrganizationUsers[com.who_commented.val.value] && d_allOrganizationUsers[com.who_commented.val.value].pic">
                      <img :src="d_allOrganizationUsers[com.who_commented.val.value].pic" @click="f_showUserProfileBModal(com.who_commented.val.label, d_allOrganizationUsers[com.who_commented.val.value].pic)" style="border-radius: 3px; margin: 2px; cursor: pointer; width: 20px;" />
                    </template>
                    <template v-else>
                      <img src="@/icon/9370.png" style="border-radius: 3px; width: 20px;" />
                    </template>
                    {{ com.who_commented.val.label }} {{ com.comment_datetime.val.label }}
                  </b-col>
                  <b-col sm="12" md="8">
                    <!-- 
                      <div style="margin-top: 5px; padding: 5px; white-space: pre-line;">
                        {{ com.message.val }}
                      </div>
                     -->
                    <div v-html="com.message.val"> </div>
                  </b-col>
                </b-row>
              </b-card>
            </template>
          </div>
        </template>
      </b-card>
      <b-row>
        <b-col cols="12" style="text-align: right;">
          <b-button variant="danger" @click="d_showTaskCommentsModal = false">{{ $t('wdm16.4053') }}</b-button>
        </b-col>
      </b-row>
    </b-modal>
    <modal v-if="d_taskDocuments.show" @close="d_taskDocuments.show = false" :width="'750'">
      <h3 slot="header">
        <i class="fa fa-comment-o"></i>
        Dokümanlar
      </h3>
      <div slot="body">
        <b-form-file @input="f_addDocumentFastToTask(d_taskDocuments.task_ind)" style="height: 100px; border: dotted 2px #25bcff; background: #daf9ff;" v-model="d_tmpFile" :accept="f_createAcceptedDocumentTypes()" :placeholder="$t('wdm16.13222')" :drop-placeholder="$t('wdm16.13223')">
          <img src="@/icon/1530793.png" style="width: 2em;">
        </b-form-file>
        <template v-if="d_showTaskDocumentField && d_taskDocuments.task.document_list && d_taskDocuments.task.document_list.length > 0" v-for="(doc, doc_ind) in d_taskDocuments.task.document_list">
          <div style="margin: 5px;">
            <b-row style="margin: 0px;">
              <b-col cols="6">
                <b-button style="margin-right: 5px;" size="sm" variant="danger" @click="f_deleteDocumentInTask(d_taskDocuments.task_ind, doc_ind)"> <i class="fa fa-trash"></i> sil </b-button> {{ doc_ind + 1 }} ) {{ doc.file_name }}
              </b-col>
              <b-col cols="6">
                <b-button style="margin-right: 5px;" size="sm" v-if="['jpeg', 'png', 'jpg'].indexOf(doc.fileExtension.toLowerCase()) !== -1" variant="primary" @click="f_previewImage(d_taskDocuments.task, doc_ind, doc.file_name)">önizle</b-button>
                <b-button style="margin-right: 5px;" size="sm" variant="secondary" @click="f_downloadDocument(d_taskDocuments.task, doc_ind, doc.file_name)">indir</b-button>
              </b-col>
            </b-row>
          </div>
        </template>
      </div>
      <div slot="footer">
        <b-button variant="danger" @click="d_taskDocuments.show = false">{{ $t('wdm16.4053') }}</b-button>
      </div>
    </modal>
    <modal v-if="show_image_show_modal" @close="show_image_show_modal = false" :width="'1000'">
      <h3 slot="header">{{ document_show_data.file_name }}</h3>
      <div slot="body">
        <div>
          <b-button variant="white" @click="f_rotateImage()" title="saat yönünde döndür">
            <img src="@/icon/615577.png" style="width: 2em;">
          </b-button>
          <b-button variant="white" @click="f_scaleImageBig()" title="yaklaş">
            <img src="@/icon/425490.png" style="width: 2em;">
          </b-button>
          <b-button variant="white" @click="f_scaleImageSmall()" title="uzaklaş">
            <img src="@/icon/248972.png" style="width: 2em;">
          </b-button>
        </div>
        <div style="overflow-x: auto; overflow-y: auto; min-height: 500px; border: solid 1px black;">
          <img :style="'transform: scale(' + d_scale.toString() + ', ' + d_scale.toString() + ') rotate(' + rotate_degree.toString() + 'deg); position: relative;'" :src="document_show_data.file">
        </div>
      </div>
      <div slot="footer">
        <b-button variant="success" @click="show_image_show_modal = false">{{ $t('wdm16.4053') }}</b-button>
      </div>
    </modal>
    <b-modal v-if="d_showWdmrPreview.show" v-model="d_showWdmrPreview.show" title="Operasyon Detayları" centered class="modal-success" @ok="d_showWdmrPreview.show = false" scrollable ok-variant="success" size="lg">
      <div class="wdmr-link" :title="d_showWdmrPreview.url_show_iframe" @click="f_goToLink(d_showWdmrPreview.url_show_iframe)">
        <img :href="d_showWdmrPreview.url_show_iframe" target="_blank" src="@/icon/201295.png" width="20px" style="border-radius: 3px; cursor: pointer;" />
      </div>
      <iframe :src="d_showWdmrPreview.url_show_iframe" style="width: 100%; min-height: 500px;"></iframe>
      <template slot="modal-footer">
        <button type="button" class="btn btn-danger" @click="d_showWdmrPreview.show = false">{{ $t('wdm16.4053') }}</button>
      </template>
    </b-modal>
    <b-modal v-if="d_showWorkTimeModal.show" v-model="d_showWorkTimeModal.show" title="Çalışma notu" centered class="modal-success" @ok="d_showWorkTimeModal.show = false" ok-variant="success" hide-footer size="lg" no-close-on-esc no-close-on-backdrop>
      <b-card no-body>
        <b-card-header header-bg-variant="white" header-text-variant="dark" class="p-1">
          <small> {{ d_taskList[selected_task_index].id }} </small> Çalışma Notları
        </b-card-header>
        <div>
          <strong>{{ d_taskList[selected_task_index].label }}</strong>
        </div>
        <div v-html="d_taskList[selected_task_index].data && d_taskList[selected_task_index].data.general && d_taskList[selected_task_index].data.general.note && d_taskList[selected_task_index].data.general.note.val ? d_taskList[selected_task_index].data.general.note.val : ''" style="overflow-y: auto; overflow-x: hidden; max-height: 250px;"></div>

        <b-row style="margin: 0px;">
          <b-col sm="12" md="3">
            Çalışma Notu
          </b-col>
          <b-col sm="12" md="9">
            <ckeditor :editor="editor" v-model="d_newWorkTime.work_time_note" :config="editorConfig"></ckeditor>
            <!-- <b-textarea v-model="d_newWorkTime.work_time_note"></b-textarea> -->
          </b-col>
        </b-row>
        <b-row style="margin: 0px;">
          <b-col sm="12" md="3">
            Başlama
          </b-col>
          <b-col sm="12" md="9">
            <datepicker :config="{ enableTime: true, minDate: '', maxDate: 'today', time_24hr: true}" class="form-control" style="background: white;" v-model="d_newWorkTime.start" @input="f_showWorkTimeEnd"></datepicker>
          </b-col>
        </b-row>
        <b-row style="margin: 0px; min-height: 50px;">
          <b-col sm="12" md="3">
            Bitiş
          </b-col>
          <b-col sm="12" md="9">
            <datepicker v-if="d_showWorkTimeEnd" :config="{ enableTime: true, minDate: d_newWorkTime.start, maxDate: '', time_24hr: true}" class="form-control" style="background: white;" v-model="d_newWorkTime.end"></datepicker>
          </b-col>
        </b-row>
        <b-row style="margin: 0px;">
          <b-col sm="12" md="3">
          </b-col>
          <b-col sm="12" md="9">
            <b-button v-if="d_newWorkTime.start" variant="secondary" @click="f_addEditNewWorkTime()">
              <i v-if="!d_workTimeEditMode" class="fa fa-plus"></i>
              <i v-else-if="d_workTimeEditMode" class="fa fa-edit"></i>
            </b-button>
          </b-col>
        </b-row>
      </b-card>
      <b-card no-body style="margin-bottom: 1px;">
        <template v-if="selected_task_index !== '' && d_taskList[selected_task_index] && d_taskList[selected_task_index].data && d_taskList[selected_task_index].data.general && d_taskList[selected_task_index].data.general.work_time && d_taskList[selected_task_index].data.general.work_time.list">
          <b-row>
            <b-col sm="12" md="6">Toplam Çalışma Süresi</b-col>
            <b-col sm="12" md="6">
              <strong> Gün: </strong> {{ d_taskWorkTime.day }}
              <strong> Saat: </strong> {{ d_taskWorkTime.hour }}
              <strong> Dakika: </strong> {{ d_taskWorkTime.minute }}
              <strong> Saniye: </strong> {{ d_taskWorkTime.second }}
            </b-col>
          </b-row>
          <b-card v-for="(worktime, worktime_ind) in d_taskList[selected_task_index].data.general.work_time.list" :key="'work_time.list_' + worktime_ind" no-body :style="d_workTimeEditMode && d_selectedWorkItemIndex === worktime_ind ? 'margin: 3px; background: #fbffdf; border: dotted 1px black; background: #ccecf9;' : 'margin: 3px; background: #fbffdf;'">
            <b-card-header header-bg-variant="white" header-text-variant="dark" class="p-1">
              <b-row style="margin: 0px;">
                <b-col sm="12" md="6" style="padding-left: 0px; white-space: nowrap;">
                  <template v-if="worktime.start && worktime.start.val">
                    {{ DateFormatWithTime(worktime.start.val) }} ->
                  </template>
                  <template v-if="worktime.end && worktime.end.val">
                    {{ DateFormatWithTime(worktime.end.val) }} ->
                  </template>
                </b-col>
                <b-col sm="12" md="6" style="padding-left: 0px; white-space: nowrap;">
                  <b-dropdown variant="secondary" size="md" class="pull-right">
                    <template slot="button-content"> </template>
                    <b-dropdown-item @click="f_updateWorkTimeInfo(worktime_ind)"> kaydet </b-dropdown-item>
                    <b-dropdown-item @click="f_deleteWorkTime(worktime_ind)"> sil </b-dropdown-item>
                    <b-dropdown-item @click="f_editWorkTime(worktime_ind)"> düzenle </b-dropdown-item>
                  </b-dropdown>
                </b-col>
              </b-row>
            </b-card-header>
            <b-row v-if="worktime.work_time_note && worktime.work_time_note.val">
              <b-col sm="12" md="12">
                <ckeditor :editor="editor" v-model="worktime.work_time_note.val" :config="editorConfig"></ckeditor>
                <!-- <div style="white-space: pre-line;">
                  {{ worktime.work_time_note.val }}
                </div> -->
              </b-col>
            </b-row>
          </b-card>
        </template>
      </b-card>
      <b-row>
        <b-col cols="12" style="text-align: right;">
          <b-button variant="danger" @click="d_showWorkTimeModal.show = false">{{ $t('wdm16.4053') }}</b-button>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal v-if="d_organizationListData.show" :title="'Organizasyon Listesi'" centered class="modal-success" v-model="d_organizationListData.show" @ok="d_organizationListData.show = false" ok-variant="success" hide-footer size="lg">
      <b-row>
        <b-col cols="12">
          <b-form-input id="organization_search" style="width: 100%;" v-model="d_organizationSearch.text" type="text" placeholder="filtrelemek için yazınız" @dblclick="d_organizationSearch.text = ''"></b-form-input>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <template v-for="(x, x_ind) in d_organizationListData.list">
            <b-card v-if="f_searchOrganization(x)" :style="d_organizationListData.selected_index === x_ind ? 'background: #9adcd3; margin-bottom: 3px; cursor: pointer;' : 'margin-bottom: 3px; cursor: pointer;'" no-body @click="f_selectWdmr307(x_ind)">
              <b-col cols="12">
                {{ x_ind + 1 }} - {{ x.label }} <small>[ {{ x.w_id }} ]</small>
              </b-col>
              <!-- <b-card-footer footer-bg-variant="primary" header-text-variant="dark" class="p-1" style="background-color: #5db5bd!important;"></b-card-footer> -->
            </b-card>
          </template>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal v-if="d_wisdomData.show" v-model="d_wisdomData.show" centered class="modal-success" @ok="d_wisdomData.show = false" ok-variant="success" hide-footer size="lg">
      <template v-if="d_wisdomData.data_type === 'wdm307' && d_wisdomData.wisdom_op !== 'edit'">
        <b-card no-body>
          <b-card-header class="p-1" variant="white">
            <b-row style="margin: 0px;">
              <b-col cols="12">
                <strong>Kullanıcı seçimi yapınız</strong>
              </b-col>
            </b-row>
          </b-card-header>
          <div v-if="d_allWdm22list.length > 0" style="overflow-y: auto; height: 150px; overflow-x: hidden;">
            <template v-for="(x, x_ind) in d_allWdm22list">
              <b-card :style="d_selectedWdm22IdList.indexOf(x.key) !== -1 ? 'margin-bottom: 5px; cursor: pointer; background: #9adcd3;' : 'margin-bottom: 5px; cursor: pointer;'" no-body @click="f_addRemoveUser(x)">
                <b-col cols="12">
                  {{ x_ind + 1 }} - {{ x.label }} <small>[ {{ x.key }} ]</small>
                </b-col>
                <!-- <b-card-footer footer-bg-variant="primary" header-text-variant="dark" class="p-1" style="background-color: #5db5bd!important;"></b-card-footer> -->
              </b-card>
            </template>
          </div>
        </b-card>
      </template>
      <template v-else-if="d_wisdomData.data_type === 'wdm17' && d_wisdomData.wisdom_op !== 'edit'">
        <b-card no-body>
          <b-card-header class="p-1" variant="white">
            <b-row style="margin: 0px;">
              <b-col cols="6">
                <strong>Kullanıcı seçimi yapınız</strong>
              </b-col>
              <b-col cols="6">
                <select v-model="d_wisdomData.operation_user_type">
                  <option value="same_operation_to_everyone" title="Seçilen tüm kullanıcılara aynı operasyon hazırlanarak iletilecektir.">tek operasyon</option>
                  <option value="copy_to_everyone" title="Seçilen her kullanıcıya aynı operasyonun bir kopyası üretilip oluşturulacaktır.">operasyonu kopyala</option>
                </select>
              </b-col>
            </b-row>
          </b-card-header>
          <div style="overflow-y: auto; height: 150px; overflow-x: hidden;">
            <template v-for="(x, x_ind) in d_organizationWdm22List">
              <b-card :style="d_selectedWdm22IdList.indexOf(x.w_id) !== -1 ? 'margin-bottom: 5px; cursor: pointer; background: #9adcd3;' : 'margin-bottom: 5px; cursor: pointer;'" no-body @click="f_addRemoveUser(x)">
                <b-col cols="12">
                  {{ x_ind + 1 }} - {{ x.label }} <small>[ {{ x.w_id }} ]</small>
                </b-col>
                <!-- <b-card-footer footer-bg-variant="primary" header-text-variant="dark" class="p-1" style="background-color: #5db5bd!important;"></b-card-footer> -->
              </b-card>
            </template>
          </div>
        </b-card>
      </template>
      <div v-if="d_wisdomData.wisdom_op === 'edit'" class="wdmr-link" :title="d_wisdomData.url_iframe" @click="f_goToLink(d_wisdomData.url_iframe)">
        <img :href="d_wisdomData.url_iframe" target="_blank" src="@/icon/201295.png" width="20px" style="border-radius: 3px; cursor: pointer;" />
      </div>
      <iframe :src="d_wisdomData.url_iframe" style="width: 100%; min-height: 500px;"></iframe>
      <!-- <iframe src="http://localhost:8077/#/wdmr-record?layer_id=500" style="width: 100%; min-height: 500px;"></iframe> -->
      <b-row>
        <b-col cols="12" style="text-align: right;">
          <b-button type="button" variant="danger" @click="d_wisdomData.show = false">{{ $t('wdm16.4053') }}</b-button>
          <button v-if="d_wisdomData.wisdom_op === 'new' && d_wisdomData.data_type === 'wdm17'" type="button" class="btn btn-success" v-on:click="f_saveNewOperation()"> İşlemi tamamla </button>
          <button v-else-if="d_wisdomData.wisdom_op === 'edit' && d_wisdomData.data_type === 'wdm17'" type="button" class="btn btn-success" v-on:click="f_saveEditOperation()"> Sayfamı Güncelle </button>
          <button v-else-if="d_wisdomData.wisdom_op === 'edit' && d_wisdomData.data_type === 'wdm307'" type="button" class="btn btn-success" v-on:click="f_saveEditOrganization()"> Sayfamı Güncelle </button>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal v-if="d_applyStatusOperation.show" :title="'Durum işlemi'" centered class="modal-success" v-model="d_applyStatusOperation.show" @ok="d_applyStatusOperation.show = false" ok-variant="success" hide-footer>
      <b-row>
        <b-col cols="8"></b-col>
        <b-col cols="4" style="text-align: right;">
          <b-dropdown variant="white" size="sm">
            <template slot="button-content">
              işlemler
            </template>
            <b-dropdown-item @click="f_selectAssignedDateForLogStatusDate(d_applyStatusOperation.task_ind, 'assigned_date')">işlem tarihini atanma tarihi olarak seç</b-dropdown-item>
            <b-dropdown-item @click="f_selectAssignedDateForLogStatusDate(d_applyStatusOperation.task_ind, 'now_date')">şu anki tarih ve saati seç</b-dropdown-item>
          </b-dropdown>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">İşlem Tarihi</b-col>
        <b-col cols="6">
          <datepicker :config="{ enableTime: true, minDate: '', maxDate: 'today', time_24hr: true}" class="form-control" style="background: white;" v-model="d_applyStatusOperation.log_date"></datepicker>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <template v-for="(x, x_ind) in d_wdm17StateOptions">
            <b-card :style="d_applyStatusOperation.selected_index === x_ind ? 'background: #9adcd3; margin-bottom: 3px; cursor: pointer;' : 'margin-bottom: 3px; cursor: pointer;'" no-body @click="d_applyStatusOperation.selected_index = x_ind; $forceUpdate()">
              <b-col cols="12">
                {{ x_ind + 1 }} - {{ x.label }}
              </b-col>
            </b-card>
          </template>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" style="text-align: right;">
          <button v-if="d_applyStatusOperation.selected_index !== ''" type="button" class="btn btn-success" v-on:click="f_saveOperationStatus(d_wdm17StateOptions[d_applyStatusOperation.selected_index], d_applyStatusOperation.task_ind)">kaydet</button>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal v-if="d_supportManagement.show" :title="'Destek Sistemi Entegrasyon Bilgileri'" centered class="modal-success" v-model="d_supportManagement.show" @ok="d_supportManagement.show = false" ok-variant="success" hide-footer>
        <b-row class="mb-3">
          <b-col sm="12">
            <strong>Genel Bilgiler</strong>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col cols="5">Global Key</b-col>
          <b-col cols="5">
            <b-form-input v-model="d_supportManagement.global_key"></b-form-input>
          </b-col>
        </b-row>
        <b-row class="mb-4">
          <b-col cols="5">Destek Sistem URL</b-col>
          <b-col cols="5">
            <b-form-input v-model="d_supportManagement.support_system_url"></b-form-input>
          </b-col>
        </b-row>
        <b-row class="mb-3">
          <b-col sm="12">
            <strong>Konu Kullanıcı Tanımlama</strong>
          </b-col>
        </b-row>
        <b-row class="mb-4">
          <b-col cols="5">
            <b-form-input v-model="d_supportManagement.subject_name" placeholder="Konu Adı"></b-form-input>
          </b-col>
          <b-col cols="5">
            <select v-model="d_supportManagement.selected_user" style="width: 180px;">
              <option v-for="(user, index) in d_organizationWdm22List" :key="index" :value="user">
                {{ user.label }} [id: {{ user.w_id }}]
              </option>
            </select>
          </b-col>
          <b-col cols="2">
            <b-button @click="f_appendUser()" variant="primary" size="sm">ekle</b-button>
          </b-col>
        </b-row>
        <b-row class="mb-3">
          <b-col sm="12">
            <strong>Konu Kullanıcı Listesi</strong>
          </b-col>
        </b-row>
        <b-row class="mb-4" v-if="d_selectedWdmr307.data.general.subject_list">
          <template v-for="(user_item, user_item_index) in d_selectedWdmr307.data.general.subject_list.list">
            <b-col sm="12" md="5" class="mb-3">
              {{ user_item.subject_name.val }}<small style="color: green;"></small>
            </b-col>
            <b-col sm="12" md="5" class="mb-3">
              {{ user_item.username.val }}
            </b-col>
            <b-col sm="12" md="2" class="mb-3">
              <b-button @click="f_removeUser(user_item, user_item_index)" variant="warning" size="sm">sil</b-button>
            </b-col>
          </template>
        </b-row>
        <b-row>
          <b-col cols="12" style="text-align: right;">
            <button type="button" class="btn btn-success" v-on:click="f_supportManagementRecord()">kaydet</button>
          </b-col>
        </b-row>
    </b-modal>
    <b-modal v-if="d_tagOperation.show" :title="'Etiket işlemi'" centered class="modal-success" v-model="d_tagOperation.show" @ok="d_tagOperation.show = false" ok-variant="success" hide-footer>
      <b-card no-body>
        <b-card-header class="p-1" variant="white">
          <b-row style="margin: 0px;">
            <b-col cols="12">
              <strong>Etiket seçimi yapınız</strong>
            </b-col>
          </b-row>
        </b-card-header>
        <b-row>
          <b-col cols="5">Yeni Etiket</b-col>
          <b-col cols="5">
            <b-form-input v-model="d_tagOperation.new_tag"></b-form-input>
          </b-col>
          <b-col cols="2">
            <b-button variant="white" @click="f_addNewTagToOrganization()">
              ekle
            </b-button>
          </b-col>
        </b-row>
        <div v-if="d_tagOperation.operation_ind !== ''" style="overflow-y: auto; height: 150px; overflow-x: hidden;">
          <template v-for="(x, x_ind) in d_tagOperation.all_tags">
            <b-card :style="d_tagOperation.selected_tags.indexOf(x) !== -1 ? 'margin-bottom: 5px; cursor: pointer; background: #9adcd3;' : 'margin-bottom: 5px; cursor: pointer;'" no-body @click="f_addRemoveTag(x)">
              <b-col cols="12">
                {{ x_ind + 1 }} - {{ x }}
              </b-col>
              <!-- <b-card-footer footer-bg-variant="primary" header-text-variant="dark" class="p-1" style="background-color: #5db5bd!important;"></b-card-footer> -->
            </b-card>
          </template>
        </div>
        <div v-else>
          <template v-for="(x, x_ind) in d_tagOperation.all_tags">
            <b-card style="margin-bottom: 5px;" no-body>
              <b-row>
                <b-col cols="12" style="text-align: left;">
                  {{ x_ind + 1 }} - {{ x }}
                </b-col>
              </b-row>
            </b-card>
          </template>
        </div>
      </b-card>
      <b-row>
        <b-col cols="12" style="text-align: right;">
          <button type="button" class="btn btn-success" v-on:click="f_updateOperationTags()">Operasyon etiketlerini güncelle </button>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal v-if="d_operationFunctions.show" :title="'Operasyon görev fonksiyonları'" centered class="modal-success" v-model="d_operationFunctions.show" @ok="d_operationFunctions.show = false" ok-variant="success" hide-footer size="lg">
      <b-row>
        <b-col cols="12">
          <b-card style="margin-bottom: 5px;" no-body v-if="true">
            <b-row>
              <b-col sm="12" lg="4">
                Arama ifadesi
              </b-col>
              <b-col sm="12" lg="6">
                <b-form-input style="width: 100%; padding: 1px; font-size: 10px;" v-model="d_filter.search_text" type="text" placeholder="operasyon adı veya açıklaması" @dblclick="d_filter.search_text = ''"></b-form-input>
              </b-col>
              <b-col sm="12" lg="2">
                <b-button style="width: 100%;" variant="white" @click="d_filter.search_text = '';">
                  <div style="text-align: left;">
                    <img src="@/icon/2960590.png" style="width: 2em;" />
                  </div>
                </b-button>
              </b-col>
            </b-row>
          </b-card>
          <b-card style="margin-bottom: 5px;" no-body v-if="true">
            <b-row>
              <b-col sm="12" lg="4">
                Operasyon görevi no
              </b-col>
              <b-col sm="12" lg="6">
                <b-form-input style="width: 100%;" v-model="d_filter.operation_no" type="text" placeholder="operasyon no ile filtreleme" @dblclick="d_filter.operation_no = ''"></b-form-input>
              </b-col>
              <b-col sm="12" lg="2">
                <b-button style="width: 100%;" variant="white" @click="d_filter.operation_no = '';">
                  <div style="text-align: left;">
                    <img src="@/icon/2960590.png" style="width: 2em;" />
                  </div>
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal v-if="d_workTimeTagFilter.show" :title="'Etiket Filtresi'" centered class="modal-success" v-model="d_workTimeTagFilter.show" @ok="d_workTimeTagFilter.show = false" ok-variant="success" hide-footer size="lg">
      <b-row>
        <b-col cols="12">
          <b-card style="margin-bottom: 5px;" no-body v-if="true">
            <b-row>
              <b-col sm="12" lg="12">
                <b-form-checkbox-group v-model="d_workTimeTagFilter.selected_list" stacked>
                  <b-form-checkbox v-for="(tag, tag_index) in d_workTimeTagFilter.all_list" :value="tag" :key="'work_time_tag_filter_' + tag_index">
                    {{ tag }}
                  </b-form-checkbox>
                </b-form-checkbox-group>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" style="text-align: right;">
          <button type="button" class="btn btn-success" v-on:click="f_updateWorkTimeTaskListFilter()">uygula</button>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal v-if="d_workTimeDateFilter.show" :title="'Tarih Filtresi'" centered class="modal-success" v-model="d_workTimeDateFilter.show" @ok="d_workTimeDateFilter.show = false" ok-variant="success" hide-footer size="lg">
      <b-row>
        <b-col cols="12">
          <b-card style="margin-bottom: 5px;" no-body v-if="true">
            <b-row>
              <b-col sm="12" lg="12">
                <b-form-checkbox-group v-model="d_workTimeDateFilter.selected_list" stacked>
                  <b-form-checkbox v-for="(dt, dt_index) in d_workTimeDateFilter.all_list" :value="dt" :key="'work_time_date_filter_' + dt_index">
                    {{ DateFormat(dt) }}
                  </b-form-checkbox>
                </b-form-checkbox-group>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" style="text-align: right;">
          <button type="button" class="btn btn-success" v-on:click="f_updateWorkTimeTaskListFilter()">uygula</button>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal v-if="d_workTimeTaskIndexFilter.show" :title="'Görev Filtresi'" centered class="modal-success" v-model="d_workTimeTaskIndexFilter.show" @ok="d_workTimeTaskIndexFilter.show = false" ok-variant="success" hide-footer size="lg">
      <b-row>
        <b-col cols="12">
          <b-card style="margin-bottom: 5px;" no-body v-if="true">
            <b-row>
              <b-col sm="12" lg="12">
                <b-form-checkbox-group v-model="d_workTimeTaskIndexFilter.selected_list" stacked>
                  <b-form-checkbox v-for="(task, task_index) in d_taskList" :value="task_index" :key="'work_time_task_index_filter_' + task_index">
                    {{ task.id }} - {{ task.label ? task.label : task.id }}
                  </b-form-checkbox>
                </b-form-checkbox-group>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" style="text-align: right;">
          <button type="button" class="btn btn-success" v-on:click="f_updateWorkTimeTaskListFilter()">uygula</button>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal v-if="d_showPrintModal.show" v-model="d_showPrintModal.show" title="Yazdır" centered class="modal-success" @ok="d_showPrintModal.show = false" ok-variant="success" hide-footer size="xl">
      <div v-if="d_selectedWdmr307 && d_selectedWdmr307.data">
        <div style= "height: 40px;">
          <b-button @click="f_printFunction()" style= "float: right; color:white; background: rgba(252, 0, 153); border: none">Yazdır</b-button>
          <button @click="f_chancePrintLang" style= "float: right; border: none; background-color: white; margin-right: 10px">en/tr</button>
          <div style="">
            <div>
              <label for="inputAdamGun">Adam Gün Ücret:</label>
              <input id="inputAdamGun" v-model="d_inputDayPrice" type="number" min="0"/>

              <label for="inputKDV">KDV (%):</label>
              <input id="inputKDV" v-model="d_inputKDV" type="number" min="0" />

            </div>
            <div v-if="d_totalPrice">
              <span>Fatura Ödenecek Tutar ({{ d_totalPriceWithoutKDV }} TL + %{{ d_inputKDV }} KDV): {{ d_totalPrice }} TL</span>
            </div>
          </div>
        </div>
        <hr/>
        <div ref="print_area" style="font-family: 'Times New Roman', Times, serif;">
          <template>
            <div>
              <p style="background-color: rgb(80, 175, 201); border: 2px solid rgb(80, 175, 201); color: white; font-variant: small-caps;">{{ d_selectedWdmr307.label }}</p>
              <p style="background-color: rgb(219, 229, 241); font-variant: small-caps;">{{d_printLabels.organizationPurpose[d_printLang]}}</p>
              <p v-if="d_selectedWdmr307.data && d_selectedWdmr307.data.general && d_selectedWdmr307.data.general.about && d_selectedWdmr307.data.general.about.val">
                {{ d_selectedWdmr307.data.general.about.val }}
              </p>
            </div>
            <template v-if="d_tasksForPrint.with_tags.tags_order.length">
              <p style="background-color: rgb(80, 175, 201); border: 2px solid rgb(80, 175, 201); color: white; font-variant: small-caps;">{{d_printLabels.phases[d_printLang]}}</p>
              <div v-for="tag in d_tasksForPrint.with_tags.tags_order" style="color: black;">
                <p style="background-color: rgb(219, 229, 241); font-variant: small-caps;">{{tag}}</p>
                <p>{{d_printLabels.desiredBeginEnd[d_printLang]}}: {{ f_desiredBeginEndForTag(tag) }} </p>
                <p>{{d_printLabels.actualBeginEnd[d_printLang]}}: {{ f_actualBeginEndForTag(tag) }} </p>
                <p>{{d_printLabels.personDay[d_printLang]}}: {{ f_getAllWorkDaysFromTag(tag) }}</p>
                <div v-for="task, taskIndex in d_tasksForPrint.with_tags.tags[tag].list" >
                  <strong>{{d_printLabels.operation[d_printLang]}}-{{taskIndex+1}}, {{task.data.general.operation_name.val}} ({{task.id}})</strong>
                  <ul>
                    <li>
                      {{d_printLabels.operationType[d_printLang]}}: 
                      <template v-if="task.data.general && task.data.general.task_type && task.data.general.task_type.val && task.data.general.task_type.val.label">
                        {{task.data.general.task_type.val.label}}
                      </template>
                    </li>
                    <li>{{d_printLabels.operationPurpose[d_printLang]}}
                      <ul>
                        <li v-if="task.data.general && task.data.general.note && task.data.general.note.val" v-html="task.data.general.note.val"></li>
                      </ul>
                    </li>
                    <li>{{d_printLabels.responsible[d_printLang]}}: {{f_getAssignedBy(task)}} </li>
                    <li>{{d_printLabels.personDay[d_printLang]}}: <span v-if="task.data.general.planned_total_worktime_days">{{ task.data.general.planned_total_worktime_days.val }}</span> </li>
                    <li>{{d_printLabels.desiredBeginEnd[d_printLang]}}: <span v-if="task.data.general.desired_begin_date && task.data.general.desired_end_date"> {{ f_dateTimeForLang(task.data.general.desired_begin_date.val, false) }} - {{f_dateTimeForLang(task.data.general.desired_end_date.val, false)}}</span></li>
                    <li>{{d_printLabels.workNotes[d_printLang]}}: 
                      <div v-if="task.data.general.work_time && task.data.general.work_time.list.length" >
                        <ul v-for="workTime in task.data.general.work_time.list">
                          <li>{{f_dateTimeForLang(workTime.start.val,true)}} - {{f_dateTimeForLang(workTime.end.val,true)}}
                            <ul v-if="workTime.work_time_note && workTime.work_time_note.val">
                              <li v-html="workTime.work_time_note.val"></li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li>{{d_printLabels.status[d_printLang]}}: 
                      <strong v-if="task.data.general && task.data.general.status && task.data.general.status.val && task.data.general.status.val.label">
                        {{ task.data.general.status.val.translation && task.data.general.status.val.translation[d_printLang] ? task.data.general.status.val.translation[d_printLang] : task.data.general.status.val.label }}
                      </strong>
                    </li>
                  </ul>
                </div>
                <br/>
              </div>
            </template>

            <template v-if="d_tasksForPrint.without_tags.list.length > 0">
              <p style="background-color: rgb(219, 229, 241); font-variant: small-caps;">{{d_printLabels.additionalDevelopments[d_printLang]}}</p>
              <div v-if="d_tasksForPrint.without_tags.list && d_tasksForPrint.without_tags.list.length" >
                <p>{{d_printLabels.desiredBeginEnd[d_printLang]}}: {{ f_desiredBeginEndForTag() }} </p>
                <p>{{d_printLabels.actualBeginEnd[d_printLang]}}: {{ f_actualBeginEndForTag() }} </p>
                <p>{{d_printLabels.personDay[d_printLang]}}: {{ f_getAllWorkDaysFromTag() }}</p>
              </div>
              <div v-for="task, taskIndex in d_tasksForPrint.without_tags.list">
                <strong>{{d_printLabels.operation[d_printLang]}}-{{taskIndex+1}}, {{task.data.general.operation_name.val}} ({{task.id}})</strong>
                  <ul>
                    <li>
                      {{d_printLabels.operationType[d_printLang]}}: 
                      <template v-if="task.data.general && task.data.general.task_type && task.data.general.task_type.val && task.data.general.task_type.val.label">
                        {{task.data.general.task_type.val.label}}
                      </template>
                    </li>
                    <li>{{d_printLabels.operationPurpose[d_printLang]}}
                      <ul>
                        <li v-if="task.data.general && task.data.general.note && task.data.general.note.val" v-html="task.data.general.note.val"></li>
                      </ul>
                    </li>
                    <li>{{d_printLabels.responsible[d_printLang]}}: {{f_getAssignedBy(task)}} </li>
                    <li>{{d_printLabels.personDay[d_printLang]}}: <span v-if="task.data.general.planned_total_worktime_days">{{ task.data.general.planned_total_worktime_days.val}}</span> </li>
                    <li>
                        {{d_printLabels.desiredBeginEnd[d_printLang]}}: 
                        <span>
                            {{ task.data.general.desired_begin_date && task.data.general.desired_begin_date.val ? f_dateTimeForLang(task.data.general.desired_begin_date.val,false) : '' }} - 
                            {{ task.data.general.desired_end_date && task.data.general.desired_end_date.val ? f_dateTimeForLang(task.data.general.desired_end_date.val,false) : '' }}
                        </span>
                    </li>
                    <li>{{d_printLabels.workNotes[d_printLang]}}:
                      <div v-if="task.data.general.work_time && task.data.general.work_time.list.length" >
                        <ul v-for="workTime in task.data.general.work_time.list">
                          <li>
                            {{ workTime.start && workTime.start.val ? f_dateTimeForLang(workTime.start.val,true) : '' }} - 
                            {{ workTime.end && workTime.end.val ? f_dateTimeForLang(workTime.end.val,true) : '' }}
                            <ul v-if="workTime.work_time_note && workTime.work_time_note.val">
                              <li v-html="workTime.work_time_note.val"></li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li>{{d_printLabels.status[d_printLang]}}: 
                      <strong v-if="task.data.general && task.data.general.status && task.data.general.status.val && task.data.general.status.val.label">
                        {{ task.data.general.status.val.translation && task.data.general.status.val.translation[d_printLang] ? task.data.general.status.val.translation[d_printLang] : task.data.general.status.val.label }}
                      </strong>
                    </li>
                  </ul>
              </div>
            </template>

              <div class="printProjectTeamDiv">
                <p style="background-color: rgb(80, 175, 201); border: 2px solid rgb(80, 175, 201); color: white; font-variant: small-caps;">{{d_printLabels.projectTeam[d_printLang]}}</p>
                <template v-for="user of d_allAssigned.users_order">
                  <p v-for="title of d_allAssigned[user].titles_order" style="background-color: rgb(219, 229, 241); font-variant: small-caps;">{{d_allAssigned[user].label}} - {{ d_allAssigned[user].title[title].translation[d_printLang] }}, Wisdomera</p>
                </template>
              </div>
              <div class="printTableDiv">
                <p style="background-color: rgb(80, 175, 201); border: 2px solid rgb(80, 175, 201); color: white; font-variant: small-caps;">{{d_printLabels.projectWorkPlan[d_printLang]}}</p>
                <table style="width: 100%; border-collapse: collapse;">
                  <thead style="background-color: rgb(170, 237, 255);" >
                    <tr>
                      <th style="width: 40%">{{d_printLabels.operation[d_printLang]}}</th>
                      <th style="width: 20%">{{d_printLabels.responsible[d_printLang]}} </th>
                      <th style="width: 20%">{{d_printLabels.personDay[d_printLang]}} </th>
                      <th style="width: 20%">{{d_printLabels.endDate[d_printLang]}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="tag in d_tasksForPrint.with_tags.tags_order">
                      <td>{{ tag }}</td>
                      <td> <p v-for="user in f_getAssignedByFromTag(tag)">{{ user }}<br/></p></td>
                      <td>{{ f_getAllWorkDaysFromTag(tag) }}</td>
                      <td>{{ f_getTasksFinishDate(tag) }}</td>
                    </tr>
                    <tr v-if="d_tasksForPrint.without_tags.list.length">
                      <td>{{d_printLabels.additionalDevelopments[d_printLang]}}</td>
                      <td><p v-for="user in f_getAssignedByFromTag()">{{ user }}<br/></p></td>
                      <td>{{ f_getAllWorkDaysFromTag() }}</td>
                      <td>{{ f_getTasksFinishDate() }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
          </template>
        </div>
      </div>
      <div v-else>
        <p>Bir organizasyon seçin</p>
      </div>
    </b-modal>
  </div>
</template>

<script>
import AnalyzeReportView from '@/components/widgets/AnalyzeReportView';
import { DateFunctions } from '@/services/public/date_functions';
import { WORGANIZATION_FRONTEND_URL } from '@/config/index';
var d_newTaskCommentKeyLastLocation = '';
// import WmanagerService from '@/services/wmanager';
import { WmailApi } from '@/services/Wmail/wmailapi';
import { WmanagerApi } from '@/services/Wmanager/WmanagerApi';
import { ClsSearch } from '@/services/public/search';
import Datepicker from "wisdom-vue-datepicker";
import {
  default as router
} from "@/router";
import moment from "moment";
import {
  default as Modal
} from "@/components/widgets/Modal";
import { ClsWdmrForever, ClsWdmrSave } from "@/services/public/wdm";
import OrganizationService from '@/services/organization';
import WconnectService from '@/services/wconnect';
import WdmService from '@/services/wdm';
import OptionService from '@/services/option';
import UserService from '@/services/users';
import GlobalService from '@/services/global';
import FileSaver from 'file-saver';
import FileTransferService from '@/services/file_transfer';
import { ClsWdmrManage } from "@/services/public/wdmr_manage";
import { mapGetters } from 'vuex';
import Vue from 'vue'
import CKEditor from '@ckeditor/ckeditor5-vue';
Vue.use(CKEditor);
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {
  default as WTopMenu
} from '@/components/widgets/WTopMenu';
// import {
//   default as Modal
// } from '@/components/widgets/Modal';

const htmlTemplate = `
<!DOCTYPE html>
<html>
<head>
  <meta charset="utf-8">
  <title>##TITLE##</title>
  <style type="text/css">
      @page {
        size: A4;
        margin: 10mm 8mm 10mm 8mm;
      }
    @media print {
      .new_css_class_use_test {
          width: 100%;
          display: inline-block;
      }

      html, body {
        width: 210mm;
        background: white!important;
        height: 297mm;
      }

      body {
        -webkit-print-color-adjust: exact; /* Chrome, Safari */
        print-color-adjust: exact; /* Firefox */
      }
    }

    .printProjectTeamDiv {
    page-break-before: always;
    }

    .printTableDiv table, 
    .printTableDiv td, 
    .printTableDiv tr, 
    .printTableDiv th {
        border: 1px solid black;
    }


    .header, .header-space {
      height: 60px;
      width: 100%;
    }
    .header {
      position: fixed;
      top: 0;
    }
    .footer {
      position: fixed;
      bottom: 0;
    }
    table{
      width: 100%;
    }

    ##CSS##
  </style>

</head>
<body>
  <div class="page-header">
    ##COVER##
  </div>
  <div>
        <table>
          <thead>
            <tr>
              <td>
                <div class="header-space">&nbsp;</div>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div class="content">##BODY##</div>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="header">##HEADER##</div>
  </div>
</body>
</html>
`;

export default {
  name: 'Operation',
  props: {
    p_settings: {
      type: Object,
      required: false
    }
  },
  components: {
    Modal,
    WTopMenu,
    Datepicker,
    AnalyzeReportView
  },
  computed: {
    ...mapGetters({
      StoreDevice: 'StoreDevice',
      StoreLoading: 'StoreLoading',
    })
  },
  data () {
    return {
      d_selectedTaskIndex: '',
      d_reports: { 'parameters': {}, 'paramorder': [] },
      d_organizationSearch: {
        'text': '',
      },
      d_loading: {
        'status': false,
      },
      d_taskPriorityTextStyle: {
        'task_list': {}
      },
      d_taskOrganizationMatch: {
        'task_list': {}
      },
      d_taskBetweenDesiredStartAndEndList: {
        'task_list': {}
      },
      d_taskDesiredDayToday: {
        'task_list': {}
      },
      d_todayDate: '',
      d_desiredEndDateToday: {
        'task_list': {}
      },
      d_taskCellStyleList: {},
      d_taskCommentSeenList: {},
      d_workTimeTaskIndexFilter: {
        'selected_list': [],
        'all_list': [],
        'show': false
      },
      d_workTimeDateFilter: {
        'selected_range_count': 10,
        'selected_list': [],
        'all_list': [],
        'show': false
      },
      d_workTimeTagFilter: {
        'selected_list': [],
        'all_list': [],
        'show': false
      },
      d_workTimeTaskList: {
        'count': 0,
        'task_all_date_match': {},
        'log_status_list': {},
        'selected_tag_list': [],
        'selected_row_index': '',
        'selected_task_index_list': [],
        'selected_date_list': [],
        'not_tag_including_task_list': [],
        'work_time_date_order': [],
        'work_time_date_view_match_list': [],
        'tag_order': [],
        'tags': {}
      },
      d_timeLineMode: 1,
      d_operationFunctions: {
        'show': false
      },
      d_taskTimeLine: {
        'date_list': {},
        'date_order': [],
        'table': []
      },
      d_fullTopMenu: true,
      d_updateOperationsInterval: 0,
      d_wdmrListByAlgorithmResponse: {
        'end_log_operation_id': '',
        'last_case_point': '',
        'last_log_operation_id': '',
        'last_root_layer_point': '',
        'next': '',
        'status_code': '',
        'status_message': '',
        'total_root_layer_point': '',
        'wdmr_count_list': ''
      },
      editor: ClassicEditor,
      editorConfig: {
        // placeholder: 'placeholder datası',
        toolbar: ["heading", "|", "bold", "italic", "link", "|", "bulletedList", "numberedList", "|", "indent", "outdent", "|", "blockQuote", "|", "mediaEmbed", "|", "insertTable", "tableColumn", "tableRow", "mergeTableCells", "|", "undo", "redo"]
          // toolbar: ["undo", "redo", "bold", "italic", "blockQuote", "ckfinder", "imageTextAlternative", "imageUpload", "heading", "imageStyle:full", "imageStyle:side", "link", "numberedList", "bulletedList", "mediaEmbed", "insertTable", "tableColumn", "tableRow", "mergeTableCells"]
          // config parameters: ['alignment', 'autosave', 'balloonToolbar', 'blockToolbar', 'ckfinder', 'cloudServices', 'codeBlock', 'exportPdf', 'exportWord', 'extraPlugins', 'fontBackgroundColor', 'fontColor', 'fontFamily', 'fontSize', 'heading', 'highlight', 'image', 'indentBlock', 'initialData', 'language', 'link', 'mediaEmbed', 'mention', 'placeholder', 'plugins', 'removePlugins', 'restrictedEditing', 'simpleUpload', 'table', 'title', 'toolbar', 'typing', 'wordCount']
          // https://ckeditor.com/docs/ckeditor5/latest/api/module_core_editor_editorconfig-EditorConfig.html
      },
      d_tagOperation: { 'show': false, 'operation_ind': '', 'all_tags': [], 'selected_tags': [], 'new_tag': '' },
      d_supportManagement: { 
        'show': false, 
        'subject_name': "",
        "selected_user": null,
        "global_key": "",
        "support_system_url": "",
        'subject_list': []
      },
      d_showWdmrPreview: { 'show': false, 'url_show_iframe': '' },
      d_editingOperationIndex: '',
      WDMR_LINKS: require('@/config/index').WDMR_LINKS,
      d_WmanagerLayerData: require('@/services/Wmanager/Layers').Layers,
      d_filter: { 'operation_no': '', 'search_text': '' },
      d_applyStatusOperation: {
        'log_date': '',
        'show': false,
        'selected_index': '',
        'task_ind': ''
      },
      d_allWdm22list: [],
      d_selectedWdmr17: '',
      d_selectedWdm22IdList: [],
      d_organizationWdm22List: [],
      change: '',
      d_organizationListData: {
        'show': false,
        'list': [],
        'selected_index': ''
      },
      d_selectedWdmr307: '',
      d_fullScreenOperationPage: false,
      d_fullScreenGeneral: false,
      d_selectedAnatomySchemaList: [],
      show_wisdom_data_modal: false,
      data_type: '',
      d_wdm22: {},
      wisdom_data: {},
      d_showWorkTimeModal: { 'show': false, 'task_id': '' },
      d_showWorkTimeEnd: true,
      d_selectedWorkItemIndex: '',
      d_taskWorkTime: {
        'day': 0,
        'hour': 0,
        'minute': 0,
        'second': 0
      },
      d_newWorkTime: { 'start': '', 'end': '', 'work_time_note': '' },
      d_workTimeEditMode: false,
      data_show_change: '0',
      d_showTaskPreviewData: '',
      d_scale: 1,
      rotate_degree: 0,
      document_show_data: { 'file': '', 'file_name': '' },
      show_image_show_modal: false,
      d_showTaskDocumentField: true,
      d_tmpFile: '',
      d_taskDocuments: {
        'show': false,
        'task': '',
        'task_ind': ''
      },
      d_filteredWhoCanSeeUsers: [],
      d_showTaskCommentsModal: false,
      d_allOrganizationUsers: {},
      d_selectedTaskForComment: {},
      d_newTaskComment: '',
      selected_task_index: '',
      d_organizationList: [],
      d_menuData: {},
      d_logResultPageData: { 'current': 1, 'perpage': 100 },
      d_searchText: '',
      d_isDataNewOrEdit: '',
      d_dataType: '',
      user: {},
      d_startingDate: '',
      d_endingDate: '',
      d_totalLogCount: 0,
      option_data: {},
      d_wdm17StateOptions: [],
      d_wdm17TaskTypeOptions: [],
      d_priorityList: [],
      d_searchTaskListData: {
        'search_text': '',
        'organization_list': [],
        'active_user_index_list': [],
        'selected_layer': [],
        'username': '',
        'organization_id': 'uuid',
        'user_type': '', // this, all
        'priority': [], // drug_medical_equipment
        'task_type_list': [], // general, drug_medical_equipment, meeting etc.
        'state_list': [],
        'organization_type': 'all', // 'this' organization, 'all' organization
        'pagination': { 'start': 0, 'end': 20, 'current': 1, 'perpage': 20 },
        'tree_filter': []
      },
      d_pagination: {
        'start': 0,
        'end': 99,
        'current': 1,
        'perpage': 100
      },
      d_taskList: [],
      d_allTaskList: [],
      d_tmpTaskList: [],
      d_taskListCount: 1000000,
      d_selectedTab: 'up_and_down', // up_and_down, date_line
      d_wisdomData: {
        'operation_user_type': 'copy_to_everyone',
        'option_data': {},
        'show': false,
        'data_type': '',
        'layer_type': '',
        'wisdom_op': '',
        'url_iframe': ''
      },
      d_showPrintModal: {
        'show': false,
      },
      d_tasksForPrint: {
        'with_tags': {
          'tags_order': [],
          'tags': {}
        },
        'without_tags': {
          'list': []
        }
      },
      d_totalPrice: 0,
      d_totalPriceWithoutKDV: 0,
      d_inputDayPrice: "",
      d_inputKDV: 20,
      timerForCalculatePrice: null,
      d_printLang : "tr",
      d_printLabels : {
        "documentTitle": {
          "tr": "Doküman",
          "en": "Document"
        },
        "title": {
          "tr": "Kapsam Teklif, Proje Takip ve İş Planı",
          "en": "Scope Proposal, Project Tracking and Work Plan"
        },
        "organizationPurpose": {
          "tr": "Projenin Amacı",
          "en": "Purpose of The Project"
        },
        "phases": {
          "tr": "Fazlar",
          "en": "Phases"
        },
        "operation": {
          "tr": "Operasyon",
          "en": "Operation"
        },
        "operationType": {
          "tr": "Operasyon Tipi",
          "en": "Operation Type"
        },
        "operationPurpose": {
          "tr": "Açıklama",
          "en": "Explanation"
        },
        "responsible": {
          "tr": "Görevliler",
          "en": "Responsible"
        },
        "personDay": {
          "tr": "Adam / Gün",
          "en": "Person / Day"
        },
        "desiredBeginEnd": {
          "tr": "Beklenen Başlama - Bitiş",
          "en": "Desired Begin - End"
        },
        "actualBeginEnd": {
          "tr": "Gerçekleşen Başlama - Bitiş",
          "en": "Actual Begin - End"
        },
        "endDate": {
          "tr": "Sonlanma Tarihi",
          "en": "End Date"
        },
        "workNotes": {
          "tr": "Çalışma Notu",
          "en": "Working Notes"
        },
        "status": {
          "tr": "Durum",
          "en": "Status"
        },
        "additionalDevelopments": {
          "tr": "Ek Geliştirmeler",
          "en": "Additional Developments"
        },
        "projectTeam": {
          "tr": "Proje Sorumluları",
          "en": "Project Team"
        },
        "projectWorkPlan": {
          "tr": "Proje Tüm Paydaşları İş Planı",
          "en": "Project All Stakeholders Work Plan"
        }
      },
      d_allAssigned: {}
    }
  },
  beforeCreate: function () {},
  created: function () {
    let get_wdm17_list = false;
    if (this.p_settings) {
      if (this.p_settings.top_menu_show === 'no') {
        this.d_fullTopMenu = false;
      }
      if (this.p_settings.selected_tab) {
        this.d_selectedTab = this.p_settings.selected_tab;
      }
      if (this.p_settings.user_type) {
        get_wdm17_list = true;
      }
    }
    // console.log('this.StoreDevice.isMobile : ', this.StoreDevice.isMobile);
    if (this.StoreDevice.isMobile) {
      this.d_selectedTab = 'up_and_down';
      this.$forceUpdate();
    }
    this.f_createMenuData();
    // this.f_prepareWdm17Data();
    this.user = JSON.parse(localStorage.getItem('user'));
    this.d_startingDate = moment(new Date()).format('YYYY-MM-DD');
    this.d_endingDate = moment(new Date()).format('YYYY-MM-DD');
    this.f_getOrganizationList(get_wdm17_list);
    // this.f_getAllWdm22List();
    this.f_wdmList();
    let today_date = new Date();
    this.d_todayDate = moment(today_date).toISOString().split('T')[0];
  },
  mounted: function () {},
  destroyed () {
    clearInterval(this.d_updateOperationsInterval);
    // clearInterval(this.d_nextDrawAlgorithm);
  },
  methods: {
    f_selectThisTask: function (task_ind) {
      if (this.d_selectedTaskIndex !== task_ind) {
        this.d_selectedTaskIndex = task_ind;
      }
    },
    f_deleteWorkTimeNote: function (task_data, task_ind) {
      task_data.data.general.work_time.list.splice(task_ind, 1);
    },
    f_addWorkTimeNote: function (task_data) {
      let now_date = new Date();
      let hour_string = now_date.getHours().toString();
      let now_date_string = moment(now_date).toISOString();
      let minute_val = (parseInt((parseInt(now_date_string.split('T')[1].split(':')[1]) / 5).toString().split('.')[0]) * 5).toString();
      if (minute_val.length === 1) {
        minute_val = '0' + minute_val;
      }
      if (!task_data.data.general.work_time) {
        task_data.data.general.work_time = { 'list': [] };
      }
      let prepare_work_time = {
        'start': { 'val': now_date_string.split('T')[0] + ' ' + hour_string + ':' + minute_val },
        'end': { 'val': now_date_string.split('T')[0] + ' ' + hour_string + ':' + minute_val },
        'work_time_note': { 'val': '' }
      };
      task_data.data.general.work_time.list.push(prepare_work_time);
      this.$forceUpdate();
    },
    f_searchOrganization: function (x) {
      if (this.d_organizationSearch.text) {
        if (x.label.toLocaleLowerCase().indexOf(this.d_organizationSearch.text.toLocaleLowerCase()) !== -1) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
      return false;
    },
    f_updateWorkTimeTaskListFilter: function () {
      this.f_createWorkTimeTaskList();
      this.d_workTimeDateFilter.show = false;
      this.d_workTimeTaskIndexFilter.show = false;
      this.d_workTimeTagFilter.show = false;
    },
    f_isDesiredEndDateToday: function (task_data, target_date) {
      let desired_end_date = '';
      // console.log('task_data : ', task_data);
      try {
        // console.log('target_date : ', target_date);
        // console.log('task_data.data.general.desired_end_date.val : ', task_data.data.general.desired_end_date.val);
        if (task_data.data.general.desired_end_date.val.indexOf('T') !== -1) {
          desired_end_date = task_data.data.general.desired_end_date.val.split('T')[0];
        } else if (task_data.data.general.desired_end_date.val.indexOf(' ') !== -1) {
          desired_end_date = task_data.data.general.desired_end_date.val.split(' ')[0];
        }
        if (desired_end_date) {
          if (this.d_workTimeTaskList.work_time_date_order.indexOf(desired_end_date) === -1) {
            this.d_workTimeTaskList.work_time_date_order.push(desired_end_date);
          }
        }
      } catch (err) {}
      // console.log('desired_end_date : ', desired_end_date);
      if (desired_end_date) {
        if (target_date === desired_end_date) {
          return true;
        }
      }
      return false;
    },
    f_isBetweenDesiredBeginAndEndDateToday: function (task_data, target_date) {
      let desired_begin_date = '';
      let desired_end_date = '';
      try {
        if (task_data.data.general.desired_begin_date.val.indexOf('T') !== -1) {
          desired_begin_date = task_data.data.general.desired_begin_date.val.split('T')[0];
        } else if (task_data.data.general.desired_begin_date.val.indexOf(' ') !== -1) {
          desired_begin_date = task_data.data.general.desired_begin_date.val.split(' ')[0];
        }
        if (desired_begin_date) {
          if (this.d_workTimeTaskList.work_time_date_order.indexOf(desired_begin_date) === -1) {
            this.d_workTimeTaskList.work_time_date_order.push(desired_begin_date);
          }
        }
      } catch (err) {}
      try {
        if (task_data.data.general.desired_end_date.val.indexOf('T') !== -1) {
          desired_end_date = task_data.data.general.desired_end_date.val.split('T')[0];
        } else if (task_data.data.general.desired_end_date.val.indexOf(' ') !== -1) {
          desired_end_date = task_data.data.general.desired_end_date.val.split(' ')[0];
        }
        if (desired_end_date) {
          if (this.d_workTimeTaskList.work_time_date_order.indexOf(desired_end_date) === -1) {
            this.d_workTimeTaskList.work_time_date_order.push(desired_end_date);
          }
        }
      } catch (err) {}
      if (desired_begin_date) {
        /*
          if (target_date === desired_begin_date) {
            return true;
          }
        */
        let begin_target_diff = DateFunctions.day_dif(desired_begin_date, target_date)
        let end_target_diff = DateFunctions.day_dif(target_date, desired_end_date)
        let is_begin_and_end_day_target_day = begin_target_diff === 0 && end_target_diff === 0;
        if (is_begin_and_end_day_target_day) {
          return true;
        } else {
          if (begin_target_diff >= 0 && end_target_diff > 0) {
            return true;
          }
        }
      }
      return false;
    },
    f_isThisDateToday: function (dt) {
      let target_date = '';
      if (dt.indexOf('T') !== -1) {
        target_date = dt.split('T')[0];
      } else if (dt.indexOf(' ') !== -1) {
        target_date = dt.split(' ')[0];
      } else {
        target_date = dt;
      }
      let today_date = new Date();
      let today_date_string = moment(today_date).toISOString().split('T')[0];
      if (target_date === today_date_string) {
        return true;
      }
      return false;
    },
    f_addNewOperationWithThisTag: function (tag, task_data) {
      this.d_wisdomData.data_type = 'wdm17';
      this.d_selectedWdm22IdList = [];
      this.d_wisdomData.operation_user_type = 'same_operation_to_everyone';
      this.d_organizationWdm22List = [];
      this.d_newOperationW_id = '';
      this.d_wisdomData.w_id_list = [];
      for (let i in task_data.data.general.log.list) {
        if (this.d_selectedWdm22IdList.indexOf(task_data.data.general.log.list[i].which_user_log.val.value) === -1) {
          this.d_selectedWdm22IdList.push(task_data.data.general.log.list[i].which_user_log.val.value);
          this.d_organizationWdm22List.push({ 'w_id': task_data.data.general.log.list[i].which_user_log.val.value, 'label': task_data.data.general.log.list[i].which_user_log.val.label });
        }
      }
      let layer_type = 'operation';
      let new_operation_wdmr_data = {
        'data': {
          'general': {
            'tag': {
              'list': [{
                'tag_name': { 'val': tag }
              }]
            }
          }
        }
      };
      if (task_data.label) {
        new_operation_wdmr_data.label = task_data.label;
      }
      if (task_data.data.general.operation_name && task_data.data.general.operation_name.val) {
        new_operation_wdmr_data.data.general.operation_name = { 'val': task_data.data.general.operation_name.val };
      }
      if (task_data.data.general.note && task_data.data.general.note.val) {
        new_operation_wdmr_data.data.general.note = { 'val': task_data.data.general.note.val };
      }
      if (task_data.data.general.priority && task_data.data.general.priority.val && task_data.data.general.priority.val.label) {
        new_operation_wdmr_data.data.general.priority = { 'val': task_data.data.general.priority.val };
      }
      /*
        let parent_wdmr_id = '';
        if (this.d_selectedWdmr307 && this.d_selectedWdmr307.id) {
          parent_wdmr_id = this.d_selectedWdmr307.id;
        } else {
          parent_wdmr_id = task_data.id_tree[0]['id'][task_data.id_tree[0]['id'].length - 2].split('_')[1];
        }
      */
      let parent_wdmr_id = task_data.id_tree[0]['id'][task_data.id_tree[0]['id'].length - 2].split('_')[1];
      let w_id_list = [parent_wdmr_id];
      this.d_wisdomData.wisdom_op = 'new';
      this.d_wisdomData.url_iframe = '';
      let query = 'parent_wdmr_id=' + parent_wdmr_id;
      OrganizationService.operation_wdmr_token_get(query)
        .then(resp => {
          if (resp.data.status_code === '12000') {
            w_id_list.push(resp.data.wdmr_id);
            this.d_wisdomData.w_id_list = w_id_list;
            this.d_showWdmrPreview.url_show_iframe = resp.data.url_show_iframe;
            this.d_wisdomData.url_iframe = resp.data.url_record_iframe;
            this.d_wisdomData.show = true;
          } else {
            alert('error ' + resp.data.message);
          }
        });

      /*
        let query = 'organization_id=' + parent_wdmr_id;
        let data = {
          'operation_data': new_operation_wdmr_data
        };
        OrganizationService.operation_save(query, data)
          .then(resp => {
            if (resp.data.status_code === '12000') {
              let wmanager_local_storage = JSON.parse(localStorage.getItem('wmanager'));
              w_id_list.push(resp.data.operation_id);
              this.d_wisdomData.w_id_list = w_id_list;
              this.d_wisdomData.url_iframe = this.WDMR_LINKS['record'] + 'layer_id=' + this.d_WmanagerLayerData[layer_type].layer_id + '&w_id_list=' + w_id_list.join(',') + '&wisdom=' + wmanager_local_storage.access_token + '&modsiw=' + wmanager_local_storage.refresh_token;
              this.d_wisdomData.layer_type = layer_type;
              this.d_wisdomData.data_type = this.d_WmanagerLayerData[layer_type].wdm_type;
              this.d_wisdomData.show = true;

            } else {
              console.log('error :' + resp.data.message);
            }
          });
      */
    },
    f_isTaskHasWorkTimeInThisDay: function (task_data, target_date) {
      try {
        for (let i in task_data.data.general.work_time.list) {
          try {
            let work_time_start_date = '';
            if (task_data.data.general.work_time.list[i].start.val.indexOf('T') !== -1) {
              work_time_start_date = task_data.data.general.work_time.list[i].start.val.split('T')[0];
            } else if (task_data.data.general.work_time.list[i].start.val.indexOf(' ') !== -1) {
              work_time_start_date = task_data.data.general.work_time.list[i].start.val.split(' ')[0];
            }
            if (target_date === work_time_start_date) {
              return true;
            }
          } catch (err) {}
          try {
            let work_time_end_date = '';
            if (task_data.data.general.work_time.list[i].end.val.indexOf('T') !== -1) {
              work_time_end_date = task_data.data.general.work_time.list[i].end.val.split('T')[0];
            } else if (task_data.data.general.work_time.list[i].end.val.indexOf(' ') !== -1) {
              work_time_end_date = task_data.data.general.work_time.list[i].end.val.split(' ')[0];
            }
            if (target_date === work_time_end_date) {
              return true;
            }
          } catch (err) {}
        }
      } catch (err) {}
      return false;
    },
    f_isTaskIsCreatedInThisDay: function (task_data, target_date) {
      if (task_data && task_data.created_at) {
        let created_at = '';
        if (task_data.created_at.indexOf('T') !== -1) {
          created_at = task_data.created_at.split('T')[0];
        } else if (task_data.created_at.indexOf(' ') !== -1) {
          created_at = task_data.created_at.split(' ')[0];
        }
        if (created_at === target_date) {
          return true;
        }
      }
      return false;
    },
    f_addNewTagToOrganization: function () {
      if (this.d_tagOperation.new_tag) {
        let tag_det = false;
        let organization_data = JSON.parse(JSON.stringify(this.d_selectedWdmr307));
        if (organization_data.data.general && organization_data.data.general.tag && organization_data.data.general.tag.list && organization_data.data.general.tag.list.length > 0) {
          for (let i in organization_data.data.general.tag.list) {
            if (organization_data.data.general.tag.list[i].tag_name.val === this.d_tagOperation.new_tag) {
              tag_det = true;
              break;
            }
          }
        }
        if (!tag_det) {
          if (!organization_data.data.general.tag) {
            organization_data.data.general.tag = { 'list': [] };
          }
          let new_tag_name = { 'tag_name': { 'val': this.d_tagOperation.new_tag } };
          organization_data.data.general.tag.list.push(new_tag_name);
          this.f_saveOrganization(organization_data);
        } else {
          alert(this.d_tagOperation.new_tag + ' etiket listesinde mevcuttur.');
        }
      } else {
        alert('Lütfen etiketi yazınız.');
      }
    },
    f_saveOrganization: function (organization_data) {
      let query = 'organization_id=' + this.d_selectedWdmr307.id;
      // this.$store.commit('MutationLoading', { 'status': true, 'data': { 'label': 'Kayıt işlemi tamamlanıyor. Lütfen bekleyiniz.' } });
      OrganizationService.organization_save(query, organization_data)
        .then(resp => {
          if (resp.data.status_code === '12000') {
            this.d_selectedWdmr307 = organization_data;
            this.f_prepareTagList();
            this.$forceUpdate();
            // this.$store.commit('MutationLoading', { 'status': false, 'data': {} });
            let msg = 'İşlem tamamlandı.';
            let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'işlem hakkında' };
            this.$store.commit('MutationModal', modal_data);
            this.$store.commit('MutationModalShow', true);
          } else {
            console.log('error :' + resp.data.message);
          }
        }, resp => {
          // this.$store.commit('MutationLoading', { 'status': false, 'data': {} });
          console.log(resp);
          let msg = JSON.stringify(resp);
          let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'işlem hakkında' };
          this.$store.commit('MutationModal', modal_data);
          this.$store.commit('MutationModalShow', true);
        });
    },
    f_selectUserAssignedOperatonsRest: function () {
      this.d_workTimeDateFilter.all_list = [];
      this.d_workTimeDateFilter.selected_list = [];
      this.d_workTimeTaskIndexFilter.all_list = [];
      this.d_workTimeTaskIndexFilter.selected_list = [];
      this.d_workTimeTagFilter.all_list = [];
      this.d_workTimeTagFilter.selected_list = [];
      this.f_createMenuData();
      this.f_getWdm17ListByControllingOrganizationSelection();
      this.$forceUpdate();
    },
    f_selectAllOrganizations: function (process_type = 'assigned_to_me') {
      this.d_workTimeDateFilter.all_list = [];
      this.d_workTimeDateFilter.selected_list = [];
      this.d_workTimeTaskIndexFilter.all_list = [];
      this.d_workTimeTaskIndexFilter.selected_list = [];
      this.d_workTimeTagFilter.all_list = [];
      this.d_workTimeTagFilter.selected_list = [];
      this.f_createMenuData();
      this.d_selectedWdmr307 = '';
      this.d_organizationListData.selected_index = '';
      if (process_type === 'assigned_to_me') {
        this.d_menuData.list[2].list[0].list[0].selected_option_list = []
        this.d_menuData.list[2].list[1].list[0].selected_option_list = [this.user.id]
        this.d_menuData.list[3].list[1].list[0].selected_option_list = ['assigned', 'recommended', 'accepted', 'started', 'pending'];
      } else if (process_type === 'i_assigned_to_others') {
        this.d_menuData.list[2].list[0].list[0].selected_option_list = [this.user.id]
        this.d_menuData.list[2].list[1].list[0].selected_option_list = []
        this.d_menuData.list[3].list[1].list[0].selected_option_list = ['assigned', 'recommended', 'accepted', 'started', 'pending'];
      }
      this.f_getWdm22OfThisOrganization();
      // this.f_getWdm17ListByControllingOrganizationSelection();
      this.$forceUpdate();
    },
    f_changeTimeLineMode: function () {
      if (this.d_timeLineMode === 1) {
        this.d_timeLineMode = 2;
      } else if (this.d_timeLineMode === 2) {
        this.d_timeLineMode = 1;
      }
      this.f_createTimelineData();
    },
    f_createTimelineData: function () {
      if (this.d_timeLineMode === 1) {
        this.f_createTimelineData1();
      } else if (this.d_timeLineMode === 2) {
        this.f_createTimelineData2();
      }
    },
    f_createTimelineData1: function () {
      this.d_taskTimeLine = {
        'date_order': [],
        'date_list': {},
        'tr_count': 0,
        'table': []
      };
      for (let i in this.d_taskList) {
        let task_data = JSON.parse(JSON.stringify(this.d_taskList[i]));
        try {
          if (task_data && task_data.created_at && task_data.data && task_data.data.general) {
            let dt = task_data.created_at.split('T')[0];
            if (this.d_taskTimeLine.date_order.indexOf(dt) === -1) {
              this.d_taskTimeLine.date_order.push(dt);
            }
            if (this.d_taskTimeLine.date_list[dt] === undefined) {
              this.d_taskTimeLine.date_list[dt] = { 'task_count': 0, 'date_show': this.DateFormat(dt) };
            }
            this.d_taskTimeLine.date_list[dt].task_count += 1;
            if (this.d_taskTimeLine.date_list[dt].task_count > this.d_taskTimeLine.tr_count) {
              this.d_taskTimeLine.tr_count = this.d_taskTimeLine.date_list[dt].task_count;
            }
          }
        } catch (err) {
          console.log("task err :", err);
        }
      }
      this.d_taskTimeLine.date_order.sort();
      let last_date = this.d_taskTimeLine.date_order[this.d_taskTimeLine.date_order.length - 1];
      if (last_date) {
        // console.log("last_date : ", last_date);
        for (let i = 0; i < 3; i++) {
          // console.log('i : ', i);
          let date = new Date(last_date);
          date.setHours(8, 0, 0, 0);
          date.setDate(date.getDate() + i + 1);
          // console.log("moment(date).toISOString() : ", moment(date).toISOString());
          let dt = moment(date).toISOString().split('T')[0];
          this.d_taskTimeLine.date_order.push(dt);
          if (this.d_taskTimeLine.date_list[dt] === undefined) {
            this.d_taskTimeLine.date_list[dt] = { 'task_count': 0, 'date_show': this.DateFormat(dt) };
          }
        }
      }
      /*
        let first_date = this.d_taskTimeLine.date_order[0];
        for (let i = 0; i < 3; i++) {
          // console.log('i : ', i);
          let date = new Date(first_date);
          date.setHours(8, 0, 0, 0);
          date.setDate(date.getDate() - (i + 1));
          // console.log("moment(date).toISOString() : ", moment(date).toISOString());
          let dt = moment(date).toISOString().split('T')[0];
          this.d_taskTimeLine.date_order.splice(0, 0, dt);
          if (this.d_taskTimeLine.date_list[dt] === undefined) {
            this.d_taskTimeLine.date_list[dt] = { 'task_count': 0 };
          }
        }
      */

      for (let i = 0; i < this.d_taskTimeLine.tr_count; i++) {
        this.d_taskTimeLine.table.push([]);
      }

      for (let i in this.d_taskTimeLine.table) {
        for (let k in this.d_taskTimeLine.date_order) {
          this.d_taskTimeLine.table[i].push({ 'task_ind': '' });
        }
      }
      for (let i in this.d_taskList) {
        let task_data = JSON.parse(JSON.stringify(this.d_taskList[i]));
        if (task_data.created_at && task_data.data && task_data.data.general) {
          let dt = task_data.created_at.split('T')[0];
          let col_index = this.d_taskTimeLine.date_order.indexOf(dt);
          for (let row_index in this.d_taskTimeLine.table) {
            if (this.d_taskTimeLine.table[row_index][col_index].task_ind === '') {
              this.d_taskTimeLine.table[row_index][col_index].task_ind = parseInt(i);
              break;
            }
          }
        }
      }
    },
    f_createTimelineData2: function () {
      this.d_taskTimeLine = {
        'date_order': [],
        'date_list': {},
        'tr_count': 0,
        'table': []
      };
      for (let i in this.d_taskList) {
        let task_data = JSON.parse(JSON.stringify(this.d_taskList[i]));
        if (task_data.created_at && task_data.data && task_data.data.general) {
          this.d_taskTimeLine.table.push([]);
          let dt = task_data.created_at.split('T')[0];
          if (this.d_taskTimeLine.date_order.indexOf(dt) === -1) {
            this.d_taskTimeLine.date_order.push(dt);
          }
          if (this.d_taskTimeLine.date_list[dt] === undefined) {
            this.d_taskTimeLine.date_list[dt] = { 'task_count': 0, 'date_show': this.DateFormat(dt) };
          }
          /*
            this.d_taskTimeLine.date_list[dt].task_list.push(task_data);
            if (this.d_taskTimeLine.date_list[dt].task_list.length > this.d_taskTimeLine.tr_count) {
              this.d_taskTimeLine.tr_count = this.d_taskTimeLine.date_list[dt].task_list.length;
            }
          */
        }
      }
      this.d_taskTimeLine.date_order.sort();
      let last_date = this.d_taskTimeLine.date_order[this.d_taskTimeLine.date_order.length - 1];
      // console.log("last_date : ", last_date);
      for (let i = 0; i < 3; i++) {
        // console.log('i : ', i);
        let date = new Date(last_date);
        date.setHours(8, 0, 0, 0);
        date.setDate(date.getDate() + i + 1);
        // console.log("moment(date).toISOString() : ", moment(date).toISOString());
        let dt = moment(date).toISOString().split('T')[0];
        this.d_taskTimeLine.date_order.push(dt);
        if (this.d_taskTimeLine.date_list[dt] === undefined) {
          this.d_taskTimeLine.date_list[dt] = { 'task_count': 0, 'date_show': this.DateFormat(dt) };
        }
      }
      /*
        let first_date = this.d_taskTimeLine.date_order[0];
        for (let i = 0; i < 3; i++) {
          // console.log('i : ', i);
          let date = new Date(first_date);
          date.setHours(8, 0, 0, 0);
          date.setDate(date.getDate() - (i + 1));
          // console.log("moment(date).toISOString() : ", moment(date).toISOString());
          let dt = moment(date).toISOString().split('T')[0];
          this.d_taskTimeLine.date_order.splice(0, 0, dt);
          if (this.d_taskTimeLine.date_list[dt] === undefined) {
            this.d_taskTimeLine.date_list[dt] = { 'task_count': 0 };
          }
        }
      */
      for (let i in this.d_taskTimeLine.table) {
        for (let k in this.d_taskTimeLine.date_order) {
          this.d_taskTimeLine.table[i].push({ 'task_ind': '' });
        }
      }
      let new_table_row = '';
      for (let i in this.d_taskList) {
        let task_data = JSON.parse(JSON.stringify(this.d_taskList[i]));
        if (task_data.created_at && task_data.data && task_data.data.general) {
          if (new_table_row === '') {
            new_table_row = 0;
          } else {
            new_table_row += 1;
          }
          let dt = task_data.created_at.split('T')[0];
          let col_index = this.d_taskTimeLine.date_order.indexOf(dt);
          // this.d_taskTimeLine.table[new_table_row][col_index].task = task_data;
          this.d_taskTimeLine.table[new_table_row][col_index].task_ind = parseInt(i);
          this.d_taskTimeLine.date_list[dt].task_count += 1;
        }
      }
    },
    f_wdmList: function () {
      let data = {
        'key_list': ['wdm_wisdom_wdm307_v2.0', 'wdm_wisdom_wdm17_v2.0'],
        'bucket': 'option'
      };
      GlobalService.get_multiple_cb_documents(data)
        .then(resp => {
          if (resp.data.status_code === '12000') {
            for (let i in resp.data.result) {
              this.option_data[resp.data.result[i].parent] = resp.data.result[i];
              this.d_wisdomData.option_data[resp.data.result[i].parent] = resp.data.result[i];
            }
            this.f_prepareOrganizationFilterData();
          } else {
            console.log('error ', resp.data.message);
          }
        });
    },
    f_goToLink: function (url) {
      window.open(url, '_blank');
    },
    f_createMenuData: function () {
      /*
        BE CAREFUL !!!!!!!!!!!!!!!!!!!!!!1
        WHEN YOU CHANGED THE ITEMS ORDER, THERE MAY BE A PROBLEM IN CODES. IF YOU WANT TO CHANGE ORDER, CONTROL WHETHER YOU USED ITEMS AS ITEM INDEX IN SOME WHERE IN YOU CODE.
      */
      this.d_menuData = {
        'forceUpdate': 0,
        'selected_menu': 'organization', // your defined menu value below
        'style': {
          'height': '200',
          'backgroundColor': '#FFFFFF',
          'subBackgroundColor': '#FFFFFF',
          'subColor': '#000000',
          'fontSize': '14',
          'color': '#000000',
          'main_header': {
            'backgroundColor': '#FFFFFF',
            'color': '#000000'
          },
          'tab_header': {
            'backgroundColor': '#FFFFFF',
            'color': '#878484'
          }
        },
        'list': [{
            'label': 'Organizasyon',
            'icon': require('../../icon/1705440.png'),
            'value': 'organization',
            'list': [{
                'icon': '',
                'label': 'Genel',
                'value': 'general',
                'list': [{
                  'type': 'button', // button, buttongroup, color
                  'title': 'Organizasyon listesi',
                  'icon': require('../../icon/2504347.png'),
                  'label': 'liste',
                  'value': 'general',
                  'click': this.f_openOrganizationList
                }, {
                  'type': 'button', // button, buttongroup, color
                  'title': 'Organizasyon Seçimi Hepsi',
                  'icon': require('../../icon/1890926.png'),
                  'label': 'hepsi',
                  'value': 'all_organizations_selection',
                  'click': this.f_cancelOrganizationSelection
                }, ]
              }, {
                'icon': require('../../icon/1421585.png'),
                'label': 'Etiket',
                'value': 'tag',
                'list': [{
                  'type': 'button', // button, buttongroup, color
                  'title': 'Etiket listesi',
                  'icon': require('../../icon/2504347.png'),
                  'label': 'liste',
                  'value': 'tag',
                  'click': this.f_openTagList
                }, {
                  'type': 'button', // button, buttongroup, color
                  'title': 'Etiket Filtresi',
                  'icon': require('../../icon/1517831.png'),
                  'label': 'filtre',
                  'value': 'tag',
                  'click': this.f_openWorkTimeTagFilter
                }, ]
              },
              {
                'icon': require('../../icon/1421585.png'),
                'label': 'Destek',
                'value': 'tag',
                'list': [{
                  'type': 'button', // button, buttongroup, color
                  'title': 'Destek listesi',
                  'icon': require('../../icon/2504347.png'),
                  'label': 'destek yönetim',
                  'value': 'tag',
                  'click': this.f_openSupportManagement
                },]
              },
              {
                'icon': require('../../icon/950780.png'),
                'label': 'Yazdır',
                'value': 'tag',
                'list': [{
                  'type': 'button', // button, buttongroup, color
                  'title': 'Yazdırma Butonu',
                  'icon': require('../../icon/950780.png'),
                  'label': 'yazdır',
                  'value': 'tag',
                  'click': this.f_openPrintModal
                },]
              },
              /*{
                         'icon': '',
                         'label': 'Düzenle',
                         'value': 'edit',
                         'list': [{
                             'type': 'button', // button, buttongroup, color
                             'title': 'Seçili organizasyonu düzenle', // save
                             'icon': require('../../icon/3094236.png'),
                             'label': '',
                             'value': 'save_wdm',
                             'click': this.f_editOrganization
                           }
                           , {
                             'type': 'button', // button, buttongroup, color
                             'title': 'Organizasyonu sil', // 'Veri modelini sil'
                             'icon': require('../../icon/2960590.png'),
                             'label': '',
                             'value': 'deletewdm',
                             'click': this.f_deleteThisOrganization
                           }, {
                             'type': 'button', // button, buttongroup, color
                             'title': this.$t('wdm16.11318'),
                             'icon': require('../../icon/3532765.png'),
                             'label': '',
                             'value': 'generalwdmsettings',
                             'click': this.f_openOrganizationSettings
                           }
                         ]
                       }*/
            ]
          }, {
            'label': 'Operasyon',
            'icon': require('../../icon/3012411.png'),
            'value': 'operation',
            'list': [{
              'icon': '',
              'label': 'Genel',
              'value': 'general',
              'list': [{
                'type': 'button', // button, buttongroup, color
                'title': 'Yeni operasyon',
                'icon': require('../../icon/2766751.png'),
                'label': '',
                'value': 'general',
                'click': this.f_newOperation
              }]
            }, {
              'icon': require('../../icon/1517831.png'),
              'label': 'Filtre',
              'value': 'filter',
              'list': [{
                'type': 'button', // button, buttongroup, color
                'title': 'Operasyon Adı İle Filtrele',
                'icon': require('../../icon/1517831.png'),
                'label': 'ad',
                'value': 'general',
                'click': this.f_openWorkTimeTaskFilter
              }, {
                'type': 'button', // button, buttongroup, color
                'title': 'Tarih İle Filtrele',
                'icon': require('../../icon/1517831.png'),
                'label': 'tarih',
                'value': 'general',
                'click': this.f_openWorkTimeDateFilter
              }, {
                'type': 'button', // button, buttongroup, color
                'title': 'Tüm Organizasyonlarda Bana Atanan Operasyonlar [atandı, kabul edildi, başlandı, beklemede, önerildi]',
                'icon': require('../../icon/2209527.png'),
                'label': 'bana atanan',
                'value': 'all_organizations_assigned_to_me',
                'click': this.f_selectAllOrganizations_assigned_to_me
              }, {
                'type': 'button', // button, buttongroup, color
                'title': 'Tüm Organizasyonlarda Benim Atadığım Operasyonlar [atandı, kabul edildi, başlandı, beklemede, önerildi]',
                'icon': require('../../icon/2209527.png'),
                'label': 'atadığım',
                'value': 'all_organizations_i_assigned',
                'click': this.f_selectAllOrganizations_i_assigned
              }, {
                'type': 'button', // button, buttongroup, color
                'title': 'Tüm operasyonları filtresiz getirir',
                'icon': require('../../icon/2209527.png'),
                'label': 'hepsi',
                'value': 'list_all_operations',
                'click': this.f_listAllOperations
              }, ]
            }]
          }, {
            'label': 'Kullanıcı',
            'icon': require('@/icon/9370.png'),
            'value': 'user',
            'list': [{
              'icon': '',
              'label': '',
              'value': 'create_user',
              'list': [{
                'type': 'checklist', // button, buttongroup, color, checklist
                'checklist_type': 'all_selections', // only_one, all_selections
                'title': 'Oluşturan',
                'max_option_label_len': 20, // if not defined or defined as zero, all length will be shown. Must be defined as integer
                'icon': require('@/icon/1584256.png'),
                'label': 'Oluşturan',
                'value': 'create_user',
                'click': '',
                'option_list': [],
                'selected_option_list': []
              }, ]
            }, {
              'icon': '',
              'label': '',
              'value': 'assigned_user',
              'list': [{
                'type': 'checklist', // button, buttongroup, color, checklist
                'checklist_type': 'all_selections', // only_one, all_selections
                'title': 'Atanan',
                'max_option_label_len': 20, // if not defined or defined as zero, all length will be shown. Must be defined as integer
                'icon': require('@/icon/1584256.png'),
                'label': 'Atanan',
                'value': 'assigned_user',
                'click': '',
                'option_list': [],
                'selected_option_list': []
              }, ]
            }, {
              'icon': '',
              'label': '',
              'value': 'update',
              'list': [{
                'type': 'button', // button, buttongroup, color
                'title': 'Verileri Güncelle', // save
                'icon': require('@/icon/615577.png'),
                'label': '',
                'value': 'f_getWdm17ListByControllingOrganizationSelection',
                'click': this.f_getWdm17ListByControllingOrganizationSelection
              }, ]
            }, ]
          }, {
            'label': 'Operasyon Tipi',
            'icon': require('@/icon/1584256.png'),
            'value': 'filter',
            'list': [{
              'icon': '',
              'label': '',
              'value': 'priority_list',
              'list': [{
                'type': 'checklist', // button, buttongroup, color, checklist
                'checklist_type': 'all_selections', // only_one, all_selections
                'title': 'Öncelik',
                'max_option_label_len': 20, // if not defined or defined as zero, all length will be shown. Must be defined as integer
                'icon': require('@/icon/1584256.png'),
                'label': 'Öncelik',
                'value': 'priority_list',
                'click': '',
                'option_list': [],
                'selected_option_list': []
              }]
            }, {
              'icon': '',
              'label': '',
              'value': 'state_list',
              'list': [{
                'type': 'checklist', // button, buttongroup, color, checklist
                'checklist_type': 'all_selections', // only_one, all_selections
                'title': 'Durum',
                'max_option_label_len': 20, // if not defined or defined as zero, all length will be shown. Must be defined as integer
                'icon': require('@/icon/1584256.png'),
                'label': 'Durum',
                'value': 'state_list',
                'click': '',
                'option_list': [],
                'selected_option_list': ['started'] // ['assigned', 'recommended', 'accepted', 'started', 'pending']
              }]
            }, {
              'icon': '',
              'label': '',
              'value': 'task_type_list',
              'list': [{
                'type': 'checklist', // button, buttongroup, color, checklist
                'checklist_type': 'all_selections', // only_one, all_selections
                'title': 'Operasyon tipi',
                'max_option_label_len': 20, // if not defined or defined as zero, all length will be shown. Must be defined as integer
                'icon': require('@/icon/1584256.png'),
                'label': 'Operasyon tipi',
                'value': 'task_type_list',
                'click': '',
                'option_list': [],
                'selected_option_list': []
              }]
            }, {
              'icon': '',
              'label': 'Güncelle',
              'value': 'update',
              'list': [{
                'type': 'button', // button, buttongroup, color
                'title': 'Verileri Güncelle', // save
                'icon': require('@/icon/615577.png'),
                'label': '',
                'value': 'f_getWdm17ListByControllingOrganizationSelection',
                'click': this.f_getWdm17ListByControllingOrganizationSelection
              }]
            }]
          }, {
            'label': 'Takvim',
            'icon': require('@/icon/1585563.png'),
            'value': 'calendar',
            'list': [{
              'icon': '',
              'label': '',
              'value': 'calender_date_type',
              'list': [{
                'type': 'checklist', // button, buttongroup, color, checklist
                'checklist_type': 'only_one', // only_one, all_selections
                'title': 'Takvim Tipi',
                'max_option_label_len': 20, // if not defined or defined as zero, all length will be shown. Must be defined as integer
                'icon': require('@/icon/1585563.png'),
                'label': 'Takvim Tipi',
                'value': 'calender_date_type',
                'click': '',
                'option_list': [
                  { 'label': 'bugün', 'value': 'today' },
                  { 'label': 'bu hafta', 'value': 'this_week' },
                  { 'label': 'bu ay', 'value': 'this_month' },
                ],
                'selected_option_list': []
              }]
            }, {
              'icon': '',
              'label': 'Güncelle',
              'value': 'update',
              'list': [{
                'type': 'button', // button, buttongroup, color
                'title': 'Verileri Güncelle', // save
                'icon': require('@/icon/615577.png'),
                'label': '',
                'value': 'f_getWdm17ListByControllingOrganizationSelection',
                'click': this.f_getWdm17ListByControllingOrganizationSelection
              }]
            }, ]
          }, {
            'label': 'Rapor',
            'icon': require('@/icon/986801.png'),
            'value': 'report',
            'list': [{
              'icon': '',
              'label': '',
              'value': 'report_type',
              'list': [{
                'type': 'checklist', // button, buttongroup, color, checklist
                'checklist_type': 'only_one', // only_one, all_selections
                'title': 'Rapor Tipi',
                'max_option_label_len': 20, // if not defined or defined as zero, all length will be shown. Must be defined as integer
                'icon': require('@/icon/986801.png'),
                'label': 'Rapor Tipi',
                'value': 'report_type',
                'click': '',
                'option_list': [
                  { 'label': 'genel', 'value': 'general' },
                ],
                'selected_option_list': ['general']
              }]
            }, {
              'icon': '',
              'label': 'Güncelle',
              'value': 'update',
              'list': [{
                'type': 'button', // button, buttongroup, color
                'title': 'Raporu Güncelle', // save
                'icon': require('@/icon/615577.png'),
                'label': '',
                'value': 'f_prepareReportByWmanagerSql',
                'click': this.f_prepareReportByWmanagerSql
              }]
            }, ]
          },
          /*{
                   'label': 'Yardım',
                   'icon': require('@/icon/2249810.png'),
                   'value': 'help',
                   'list': [{
                     'icon': require('@/icon/2249810.png'),
                     'label': 'Genel',
                     'value': 'general',
                     'list': []
                   }]
                 },*/
        ]
      };
      if (this.p_settings) {
        if (this.p_settings.desired_date_type) {
          this.d_menuData.list[4].list[0].list[0].selected_option_list = [];
          this.d_menuData.list[4].list[0].list[0].selected_option_list.push(this.p_settings.desired_date_type);
        }
      }
      this.f_prepareOrganizationFilterData();
    },
    f_cancelOrganizationSelection: function () {
      this.d_organizationListData.selected_index = '';
      this.d_selectedWdmr307 = '';
      this.d_taskList = [];
      this.d_allTaskList = [];
      if (this.$route.query.organization_id) {
        let route_data = {
          'query': {
            // 'organization_id': organization_id
          }
        };
        this.$router.push(route_data);
      }
      this.f_getWdm22OfThisOrganization();
      // this.f_getWdm17ListByControllingOrganizationSelection();
      // this.f_getWdm17OfThisOrganization();
    },
    f_selectAllOrganizations_assigned_to_me: function () {
      if (this.$route.query.organization_id) {
        let route_data = {
          'query': {
            // 'organization_id': organization_id
          }
        };
        this.$router.push(route_data);
      }
      this.f_selectAllOrganizations('assigned_to_me');
    },
    f_selectAllOrganizations_i_assigned: function () {
      if (this.$route.query.organization_id) {
        let route_data = {
          'query': {
            // 'organization_id': organization_id
          }
        };
        this.$router.push(route_data);
      }
      this.f_selectAllOrganizations('i_assigned_to_others');
    },
    f_openWorkTimeDateFilter: function () {
      this.d_workTimeDateFilter.show = true;
    },
    f_openWorkTimeTaskFilter: function () {
      this.d_workTimeTaskIndexFilter.show = true;
    },
    f_openWorkTimeTagFilter: function () {
      this.d_workTimeTagFilter.show = true;
    },
    f_openTagList: function () {
      this.d_tagOperation.show = true;
    },
    f_openSupportManagement: function () {
      this.d_supportManagement.show = true;
      let organization_data = JSON.parse(JSON.stringify(this.d_selectedWdmr307))
      if (organization_data.data.general.support_system_url){
        this.d_supportManagement.support_system_url = organization_data.data.general.support_system_url.val;
      }
      if (organization_data.data.general.token){
        this.d_supportManagement.global_key = organization_data.data.general.token.val;
      }
    },
    f_openPrintModal: function () {
      this.d_showPrintModal.show = true;
      this.f_createTasksForPrint();
    },
    f_appendUser: function(){
      if (!this.d_supportManagement.subject_name) {
        alert('Lütfen konu adını doldurun.');
      } else if (!this.d_supportManagement.selected_user) {
        alert('Lütfen kullanıcı seçin.');
      } else {
        let det = false;
        try {
          for (let o_s_ind in this.d_selectedWdmr307.data.general.subject_list.list){
            let o_s_item = this.d_selectedWdmr307.data.general.subject_list.list[o_s_ind];
            //{
            //  "subject_id": {"val": ""},
            //  "subject_name": {"val": ""},
            //  "userid": {"val": ""},
            //  "username": {"val": ""}
            //}
            // this.d_supportManagement.selected_user = {
            //   "label": "levent korkmaz",
            //   "mail": "levent.korkmaz@wisdomera.io",
            //   "username": "levent.korkmaz"
            //   "w_id": "100"
            // }
            // aynı konu varsa ekleme, farklı konu varsa ekle
            if(o_s_item.subject_name.val === this.d_supportManagement.subject_name && o_s_item.userid.val === this.d_supportManagement.selected_user.w_id) {
              det = true;
              break;
            } else if(o_s_item.subject_name.val === this.d_supportManagement.subject_name && o_s_item.userid.val !== this.d_supportManagement.selected_user.w_id) {
              // konu adı aynı, kullanıcısı farklı ise veritabanından kullanıcıyı güncelle
              det = true;
              this.d_selectedWdmr307.data.general.subject_list.list[o_s_ind].userid.val = this.d_supportManagement.selected_user.w_id;
              this.d_selectedWdmr307.data.general.subject_list.list[o_s_ind].username.val = this.d_supportManagement.selected_user.username;
              // let new_user_item = {
              //   "subject_id": {"val": o_s_item.subject_id.val},
              //   "subject_name": {"val": o_s_item.subject_name.val},
              //   "userid": {"val": this.d_supportManagement.selected_user.w_id},
              //   "username": {"val": this.d_supportManagement.selected_user.username}
              // };
              // this.d_selectedWdmr307.data.general.subject_list.list.splice(o_s_ind, 1);
              // this.d_selectedWdmr307.data.general.subject_list.list.push(new_user_item);
              // console.log(this.d_selectedWdmr307.data.general.subject_list.list);
            }
          }
        } catch (err) {}
        if(!det){
          if (!this.d_selectedWdmr307.data.general.subject_list) {
            this.d_selectedWdmr307.data.general.subject_list = { "list": [] };
          }
          let new_subject_list_item = {
            "subject_name": {"val": this.d_supportManagement.subject_name},
            "userid": {"val": this.d_supportManagement.selected_user.w_id},
            "username": {"val": this.d_supportManagement.selected_user.username}
          };
          this.d_selectedWdmr307.data.general.subject_list.list.push(new_subject_list_item);
          // console.log(this.d_selectedWdmr307.data.general)
        }
      }

      this.$forceUpdate();
    },
    f_removeUser: function(user_item, user_item_index){
      this.d_selectedWdmr307.data.general.subject_list.list.splice(user_item_index, 1);
      // console.log("user remove: ", this.d_selectedWdmr307.data.general.subject_list.list);
      this.$forceUpdate();
    },
    f_supportManagementRecord: function () {
      let organization_data = JSON.parse(JSON.stringify(this.d_selectedWdmr307));
      // console.log("organization_data record:", organization_data)
      // this.$store.commit('MutationLoading', { 'status': true, 'data': { 'label': 'İşlem gerçekleştiriliyor. Lütfen bekleyiniz.' } });
      if (this.d_supportManagement.global_key) {
        organization_data.data.general.token = {"val": this.d_supportManagement.global_key};
      }
      else {
        try {
          this.$delete(organization_data.data.general, 'token');
        } catch (err) {}
      }
      if (this.d_supportManagement.support_system_url) {
        organization_data.data.general.support_system_url = {"val": this.d_supportManagement.support_system_url};
      }
      else {
        try {
          this.$delete(organization_data.data.general, 'support_system_url');
        } catch (err) {}
      }
      // this.$store.commit('MutationLoading', { 'status': true, 'data': { 'label': 'Kayıt işlemi tamamlanıyor. Lütfen bekleyiniz.' } });
      let query = 'organization_id=' + this.d_selectedWdmr307.id;
      let data = {
        "organization_data": organization_data
      };
      OrganizationService.organization_save(query, data)
        .then(resp => {
          if (resp.data.status_code === '12000') {
            this.d_supportManagement.show = false;
            this.d_selectedWdmr307 = organization_data;
            this.$forceUpdate();
            // this.$store.commit('MutationLoading', { 'status': false, 'data': {} });
            let msg = 'İşlem tamamlandı.';
            let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'işlem hakkında' };
            this.$store.commit('MutationModal', modal_data);
            this.$store.commit('MutationModalShow', true);
          } else {
            console.log('error :' + resp.data.message);
          }
        }, resp => {
          // this.$store.commit('MutationLoading', { 'status': false, 'data': {} });
          console.log(resp);
          let msg = JSON.stringify(resp);
          let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'işlem hakkında' };
          this.$store.commit('MutationModal', modal_data);
          this.$store.commit('MutationModalShow', true);
        });
    },
    f_wisdomShowUpdate: function () {
      if (this.change === "0") {
        this.change = "1";
      } else {
        this.change = "0";
      }
      this.$forceUpdate();
    },
    f_openStatusModal: function (task_ind) {
      this.d_applyStatusOperation.selected_index = '';
      this.d_applyStatusOperation.task_ind = task_ind;
      let now_date = new Date();
      let hour_string = now_date.getHours().toString();
      // console.log('now_date :', now_date);
      // console.log('now_date.getHours() :', now_date.getHours());
      let now_date_string = moment(now_date).toISOString();
      // console.log('now_date_string :', now_date_string);
      let minute_val = (parseInt((parseInt(now_date_string.split('T')[1].split(':')[1]) / 5).toString().split('.')[0]) * 5).toString();
      if (minute_val.length === 1) {
        minute_val = '0' + minute_val;
      }
      this.d_applyStatusOperation.log_date = now_date_string.split('T')[0] + ' ' + hour_string + ':' + minute_val;
      this.d_applyStatusOperation.show = true;
    },
    f_selectAssignedDateForLogStatusDate: function (task_ind, date_type) {
      if (date_type === 'assigned_date') {
        let task_data = this.d_taskList[task_ind];
        let this_date = new Date(task_data.data.general.log.list[0].log_datetime.val);
        let this_date_string = moment(this_date).toISOString();
        let minute_val = (parseInt((parseInt(this_date_string.split('T')[1].split(':')[1]) / 5).toString().split('.')[0]) * 5).toString();
        if (minute_val.length === 1) {
          minute_val = '0' + minute_val;
        }
        this.d_applyStatusOperation.log_date = this_date_string.split('T')[0] + ' ' + this_date_string.split('T')[1].split(':')[0] + ':' + minute_val;
      } else if (date_type === 'now_date') {
        let now_date = new Date();
        let hour_string = now_date.getHours().toString();
        let now_date_string = moment(now_date).toISOString();
        let minute_val = (parseInt((parseInt(now_date_string.split('T')[1].split(':')[1]) / 5).toString().split('.')[0]) * 5).toString();
        if (minute_val.length === 1) {
          minute_val = '0' + minute_val;
        }
        this.d_applyStatusOperation.log_date = now_date_string.split('T')[0] + ' ' + hour_string + ':' + minute_val;
      }
      this.$forceUpdate();
    },
    f_getOrganizationList: function (get_wdm17_list = false) {
      // console.log('11111 , get_wdm17_list', get_wdm17_list);
      // this.$store.commit('MutationLoading', { 'status': true, 'data': { 'label': 'Kullanıcı organizasyonları getirme işlemi gerçekleştiriliyor. Lütfen bekleyiniz.' } });
      OrganizationService.organization_list()
        .then(resp => {
          // this.$store.commit('MutationLoading', { 'status': false, 'data': {} });
          if (resp.data.status_code === '12000') {
            // console.log(resp);
            this.d_organizationListData.list = resp.data.list;
            /*
              for (let organization_index in this.d_organizationListData.list) {
                let organization_id = this.d_organizationListData.list[organization_index]['w_id'];
                this.f_getThisOrganizationUsers(organization_id, organization_index);
              }
            */
            // console.log('this.d_organizationListData : ', this.d_organizationListData);
            if (this.$route.query && this.$route.query.organization_id !== undefined && this.$route.query.organization_id) {
              if (this.$route.query.operation_no !== undefined) {
                this.d_filter.operation_no = this.$route.query.operation_no;
              }
              for (let organization_index in this.d_organizationListData.list) {
                if (this.d_organizationListData.list[organization_index].w_id === this.$route.query.organization_id) {
                  this.f_selectWdmr307(parseInt(organization_index));
                  break;
                }
              }
            } else {
              if (this.p_settings) {
                if (this.p_settings.user_type) {
                  if (this.p_settings.user_type === 'me') {
                    this.f_selectAllOrganizations_assigned_to_me();
                  } else {
                    this.f_getWdm22OfThisOrganization('', get_wdm17_list);
                    // this.f_getWdm17ListByControllingOrganizationSelection();
                  }
                } else {
                  this.f_getWdm22OfThisOrganization('', get_wdm17_list);
                  // this.f_getWdm17ListByControllingOrganizationSelection();
                }
              } else {
                this.f_getWdm22OfThisOrganization('', get_wdm17_list);
              }
            }
          } else {
            alert('error ', resp.data.status_code + ' ' + resp.data.status_message);
          }
        });
    },
    f_getThisOrganizationUsers: function (organization_id = '', organization_index) {
      let query = 'organization_id=' + organization_id;
      OrganizationService.organization_user_list_get(query)
        .then(resp => {
          if (resp.data.status_code === '12000') {
            if (!this.d_organizationListData.list[organization_index].users) {
              this.d_organizationListData.list[organization_index].users = [];
            }
            let uniq_user_list = [];
            for (let u in resp.data.list) {
              if (uniq_user_list.indexOf(resp.data.list[u].w_id) === -1) {
                uniq_user_list.push(resp.data.list[u].w_id);
                this.d_organizationListData.list[organization_index].users.push(resp.data.list[u]);
              }
            }
          } else {
            console.log('error :' + resp.data.message);
          }
        }, resp => {
          // this.$store.commit('MutationLoading', { 'status': false, 'data': {} });
          console.log(resp);
          let msg = JSON.stringify(resp);
          let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Organizasyon getirme işlemi hakkında' };
          this.$store.commit('MutationModal', modal_data);
          this.$store.commit('MutationModalShow', true);
        });
    },
    f_getWdm22OfThisOrganization: function (wdm307_id = '', get_wdm17_list = true) {
      // console.log('2222222 get_wdm17_list : ', get_wdm17_list);
      /*
        console.log('xxxxxxxxxxxxxxxxxxxxxxxxxxxxx');
        console.log('wdm307_id : ', wdm307_id);
      */

      this.d_organizationWdm22List = [];
      let query = '';
      if (wdm307_id) {
        query = 'organization_id=' + wdm307_id;
      }
      // this.$store.commit('MutationLoading', { 'status': true, 'data': { 'label': 'Organizasyon kullanıcılarını getirme işlemi gerçekleştiriliyor. Lütfen bekleyiniz.' } });
      OrganizationService.organization_user_list_get(query)
        .then(resp => {
          // this.$store.commit('MutationLoading', { 'status': false, 'data': {} });
          if (resp.data.status_code === '12000') {
            let uniq_x = [];
            for (let org_id in resp.data.list) {
              for (let u in resp.data.list[org_id]) {
                if (uniq_x.indexOf(resp.data.list[org_id][u].w_id) === -1) {
                  // console.log('user w_id : ', resp.data.list[org_id][u].w_id);
                  uniq_x.push(resp.data.list[org_id][u].w_id);
                  this.d_organizationWdm22List.push(resp.data.list[org_id][u]);
                }
              }
              for (let i in this.d_organizationListData.list) {
                if (this.d_organizationListData.list[i].w_id === org_id) {
                  this.d_organizationListData.list[i].users = [];
                  let uniq_user_list = [];
                  for (let u in resp.data.list[org_id]) {
                    if (uniq_user_list.indexOf(resp.data.list[org_id][u].w_id) === -1) {
                      uniq_user_list.push(resp.data.list[org_id][u].w_id);
                      this.d_organizationListData.list[i].users.push(resp.data.list[org_id][u]);
                    }
                  }

                }
              }
            }

            // console.log('this.d_organizationWdm22List ', this.d_organizationWdm22List);
            let id_list = [];
            for (let i in this.d_organizationWdm22List) {
              // console.log('this.d_organizationWdm22List[i] ', this.d_organizationWdm22List[i]);
              id_list.push(this.d_organizationWdm22List[i].w_id);
            }
            // console.log(id_list);
            if (id_list.length > 0) {
              let data = {
                'id_list': id_list,
              };
              OrganizationService.user_mail_list_get(data)
                .then(resp => {
                  if (resp.data.list && resp.data.list.length > 0) {
                    for (let user_index in resp.data.list) {
                      for (let wdm22_index in this.d_organizationWdm22List) {
                        if (this.d_organizationWdm22List[wdm22_index].w_id === resp.data.list[user_index].id) {
                          this.d_organizationWdm22List[wdm22_index].mail = resp.data.list[user_index].mail;
                          this.d_organizationWdm22List[wdm22_index].username = resp.data.list[user_index].username;
                          break;
                        }
                      }
                    }
                  }
                  // console.log('this.d_organizationWdm22List : ', this.d_organizationWdm22List);
                });
            }
            this.d_menuData.list[2].list[0].list[0].option_list = [];
            this.d_menuData.list[2].list[0].list[0].selected_option_list = [];
            // this.d_menuData.list[2].list[0].list[0].selected_option_list.push(this.user.id);
            this.d_menuData.list[2].list[1].list[0].option_list = [];
            this.d_menuData.list[2].list[1].list[0].selected_option_list = [];
            // console.log('this.user: ', this.user.id);
            this.d_menuData.list[2].list[1].list[0].selected_option_list.push(this.user.id);
            for (let i in this.d_organizationWdm22List) {
              let wdm22_id = this.d_organizationWdm22List[i].w_id;
              let user_label = this.d_organizationWdm22List[i].label;
              this.d_menuData.list[2].list[0].list[0].option_list.push({ 'label': user_label, 'value': wdm22_id });
              this.d_menuData.list[2].list[1].list[0].option_list.push({ 'label': user_label, 'value': wdm22_id });
            }
            this.f_sortObjectList(this.d_menuData.list[2].list[0].list[0].option_list, 'label', 1);
            this.f_sortObjectList(this.d_menuData.list[2].list[1].list[0].option_list, 'label', 1);
            this.f_topMenuUpdate();
            if (get_wdm17_list) {
              this.f_getWdm17ListByControllingOrganizationSelection();
            }
          } else {
            console.log('error :' + resp.data.message);
          }
        }, resp => {
          // this.$store.commit('MutationLoading', { 'status': false, 'data': {} });
          console.log(resp);
          let msg = JSON.stringify(resp);
          let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Organizasyon getirme işlemi hakkında' };
          this.$store.commit('MutationModal', modal_data);
          this.$store.commit('MutationModalShow', true);
        });
    },
    f_getAllWdm22List: function () {
      let data = {
        'parent_wdm': { 'key': 'wdm233_1', 'type': 'wdm233', 'bucket': 'wisdom' },
        'child_wdm': { 'type': 'wdm22', 'bucket': 'wisdom' },
        'relation_type': 'keys',
        'type': 'only_relation_list', // only_relation_list or get_wdmr_data
        'layer_type': 'first' // first or all
      };
      WdmService.get_wdmr_tree_list(data)
        .then(resp => {
          if (resp.data.status === "success") {
            this.d_allWdm22list = [];
            for (let i in resp.data.result.list) {
              if (resp.data.result.list[i].key !== 'wdm22_' + this.user.id) {
                this.d_allWdm22list.push({ 'label': resp.data.result.list[i].label, 'key': resp.data.result.list[i].key });
              }
            }
          } else {
            console.log('errorr get_wdmr_tree_list : ', resp.data.message);
          }
        });
    },
    f_selectWdmr307: function (x_ind) {
      this.d_organizationWdm22List = [];
      let wdmr307_id = this.d_organizationListData.list[x_ind].w_id;
      this.d_organizationListData.selected_index = x_ind;
      let organization_id = wdmr307_id;
      if (!this.$route.query === undefined || (this.$route.query !== undefined && (this.$route.query.organization_id === undefined || (this.$route.query.organization_id !== organization_id)))) {
        let route_data = {
          'query': {
            'organization_id': organization_id
          }
        };
        this.$router.push(route_data);
      }
      let query = 'organization_id=' + organization_id;
      // this.$store.commit('MutationLoading', { 'status': true, 'data': { 'label': 'Organizasyon verisi getirme işlemi gerçekleştiriliyor. Lütfen bekleyiniz.' } });
      OrganizationService.organization_get(query)
        .then(resp => {
          if (resp.data.status_code === '12000') {
            // this.$store.commit('MutationLoading', { 'status': false, 'data': {} });
            this.d_selectedWdmr307 = resp.data.organization_data;
            this.d_organizationListData.show = false;
            this.f_prepareTagList();
            this.f_getWdm22OfThisOrganization(this.d_organizationListData.list[x_ind].w_id);
            this.f_wisdomShowUpdate();
            // if (this.d_filter.operation_no) {
            // this.f_getWdm17ListByControllingOrganizationSelection();
            // }
          } else {
            console.log('error :' + resp.data.message);
          }
        }, resp => {
          // this.$store.commit('MutationLoading', { 'status': false, 'data': {} });
          console.log(resp);
          let msg = JSON.stringify(resp);
          let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Organizasyon getirme işlemi hakkında' };
          this.$store.commit('MutationModal', modal_data);
          this.$store.commit('MutationModalShow', true);
        });

    },
    f_clearSelectedOptionList: function () {
      this.d_menuData.list[2].list[0].list[0].selected_option_list = [];
      this.d_menuData.list[2].list[1].list[0].selected_option_list = [];
      this.d_menuData.list[3].list[0].list[0].selected_option_list = [];
      this.d_menuData.list[3].list[1].list[0].selected_option_list = [];
      this.d_menuData.list[3].list[2].list[0].selected_option_list = [];
    },
    f_listAllOperations: function () {
      this.d_workTimeDateFilter.all_list = [];
      this.d_workTimeDateFilter.selected_list = [];
      this.d_workTimeTaskIndexFilter.all_list = [];
      this.d_workTimeTaskIndexFilter.selected_list = [];
      this.d_workTimeTagFilter.all_list = [];
      this.d_workTimeTagFilter.selected_list = [];
      this.d_filter.operation_no = '';
      this.f_clearSelectedOptionList();
      if (this.d_organizationListData.selected_index !== '') {
        this.f_getWdm17ListByControllingOrganizationSelection();
      } else {
        for (let i in this.d_organizationListData.list) {
          let wdm307_id = this.d_organizationListData.list[i].w_id;
          this.f_getWdm17OfThisOrganization([wdm307_id]);
        }
      }
    },
    f_getWdm17ListByControllingOrganizationSelection: function () {
      this.d_allTaskList = [];
      this.d_taskList = [];
      if (this.d_organizationListData.selected_index !== '') {
        this.d_allTaskList = [];
        this.d_taskList = [];
        let wdm307_id = this.d_organizationListData.list[this.d_organizationListData.selected_index].w_id;
        this.f_getWdm17OfThisOrganization([wdm307_id]);
      } else {
        if (this.d_filter.operation_no) {
          this.f_getWdm17OfThisOrganization();
        } else {
          let wdm307_id_list = [];
          for (let i in this.d_organizationListData.list) {
            let wdm307_id = this.d_organizationListData.list[i].w_id;
            wdm307_id_list.push(wdm307_id);
          }
          if (wdm307_id_list.length > 0) {
            this.f_getWdm17OfThisOrganization(wdm307_id_list);
          }
        }
      }
    },
    f_getWdm17OfThisOrganizationProcess: function () {
      this.d_updateOperationsInterval = setInterval(function () {
        this.f_getWdm17OfThisOrganizationProcess();
      }.bind(this), 10000);
    },
    f_getWdm17OfThisOrganization: function (wdm307_id_list = []) {
      // let wdm307_id = this.d_selectedWdmr307.id;
      // console.log('wdm307_id: ', wdm307_id);
      // console.log('w_id_list: ', w_id_list);
      /*
        d_pagination: {
          'start': 0,
          'end': 49,
          'current': 1,
          'perpage': 50
        },
      */
      if (!this.d_loading.status) {
        this.d_loading.status = true;
        this.d_allTaskList = [];
        this.d_taskList = [];
        let limit = '';
        let offset = '';
        if (this.d_organizationListData.selected_index !== '') {
          limit = this.d_pagination.perpage.toString();
          offset = this.d_pagination.start.toString();
        }
        let order = ''; // asc or desc
        // let last_case_point = this.d_wdmrListByAlgorithmResponse.last_case_point;
        let last_case_point = '';
        let start_type = 'end';
        let layer_x = 'layer' + this.d_WmanagerLayerData['operation'].layer_id;
        let sql_text = 'SELECT ' + layer_x + ' FROM ' + layer_x;
        let where_sql = '';
        if (this.d_filter.operation_no !== '') {
          sql_text += '[key in wdm17_' + this.d_filter.operation_no + ']';
          limit = '';
          offset = '';
        } else {
          where_sql = this.f_createSqlWhere();
          // if (wdm307_id_list.length > 0) {
          //   sql_text += '.start([' + wdm307_id_list.join(',') + '])';
          // }
        }
        sql_text += where_sql
        let data = {
          'sql_text': sql_text,
          'last_case_point': last_case_point,
          'limit': limit,
          'offset': offset,
          'order': order,
        };
        if (this.d_filter.operation_no !== '') {
          //
        } else {
          data.organization_id_list = wdm307_id_list;
        }
        OrganizationService.operation_get_by_wmanager_sql(data)
          .then(resp => {
            this.d_loading.status = false;
            // this.$store.commit('MutationLoading', { 'status': false, 'data': {} });
            if (resp.data.wmanager_response.next !== undefined){
              this.d_wdmrListByAlgorithmResponse = {
                'end_log_operation_id': resp.data.wmanager_response.end_log_operation_id,
                'last_case_point': resp.data.wmanager_response.last_case_point,
                'last_log_operation_id': resp.data.wmanager_response.last_log_operation_id,
                'last_root_layer_point': resp.data.wmanager_response.last_root_layer_point,
                'next': resp.data.wmanager_response.next,
                'status_code': resp.data.wmanager_response.status_code,
                'status_message': resp.data.wmanager_response.status_message,
                'total_root_layer_point': resp.data.wmanager_response.total_root_layer_point,
                'wdmr_count_list': resp.data.wmanager_response.wdmr_count_list
              };
            };
            let wdmr17_id_list = [];
            for (let i in resp.data.wmanager_response.list) {
              if (resp.data.wmanager_response.list[i].fields && resp.data.wmanager_response.list[i].fields.length > 0 && resp.data.wmanager_response.list[i].fields[0].data && resp.data.wmanager_response.list[i].fields[0].data.length > 0) {
                let wdmr17_data = resp.data.wmanager_response.list[i].fields[0].data[0];
                // console.log(i);
                if (wdmr17_id_list.indexOf(wdmr17_data.id) === -1) {
                  this.d_allTaskList.push(wdmr17_data);
                  this.d_taskList.push(wdmr17_data);
                  let cell_style_with_border = this.f_calculateStatusCellStyle(this.d_taskList.length - 1, '');
                  let cell_style_without_border = this.f_calculateStatusCellStyle(this.d_taskList.length - 1, '', 'no');
                  this.d_taskCellStyleList[wdmr17_data['id']] = { 'with_border': cell_style_with_border, 'without_border': cell_style_without_border };
                  this.d_taskCommentSeenList[wdmr17_data['id']] = this.f_calculateCountOfSeenComments(wdmr17_data);
                  wdmr17_id_list.push(wdmr17_data.id);
                }
              }
            }
            this.f_createTimelineData();
            this.f_createWorkTimeTaskList();
            this.f_createTasksForPrint();
            // this.f_updateOperations();
          }, resp => {
            let msg = resp.status_code + ' ' + resp.status_message;
            let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Hata mesajı' };
            this.$store.commit('MutationModal', modal_data);
            this.$store.commit('MutationModalShow', true);
            // this.$store.commit('MutationLoading', { 'status': false, 'data': {} });
            console.log(resp);
          });
      } else {
        alert('İşlem devam ediyor.');
      }
    },
    f_createSqlWhere: function () {
      // console.log(JSON.stringify(this.d_menuData));
      let where_sql = '';
      let layer_x = 'layer' + this.d_WmanagerLayerData['operation'].layer_id;
      // console.log("this.d_menuData ", this.d_menuData);
      if (this.d_filter.search_text) {
        if (where_sql === '') {
          where_sql = ' WHERE ';
        } else {
          where_sql += ' and ';
        }
        where_sql += layer_x + '[(general.operation_name in ' + this.d_filter.search_text + ') or (general.note in ' + this.d_filter.search_text + ')] ';
      }
      // console.log(JSON.stringify(this.d_menuData.list[2]));
      let user_id_list_who_assigned = this.d_menuData.list[2].list[0].list[0].selected_option_list;
      if (user_id_list_who_assigned.length > 0) {
        if (where_sql === '') {
          where_sql = ' WHERE ';
        } else {
          where_sql += ' and ';
        }
        where_sql += layer_x + '[(general.log.__lp__.log_user = ' + user_id_list_who_assigned.join(',') + ') and (general.log.__lp__.log_status = assigned)] ';
      }
      let assigned_user_id_list = this.d_menuData.list[2].list[1].list[0].selected_option_list;
      if (assigned_user_id_list.length > 0) {
        if (where_sql === '') {
          where_sql = ' WHERE ';
        } else {
          where_sql += ' and ';
        }
        where_sql += layer_x + '[(general.log.__lp__.which_user_log = ' + assigned_user_id_list.join(',') + ') and (general.log.__lp__.log_status = assigned)] ';
      }
      let priority_list = this.d_menuData.list[3].list[0].list[0].selected_option_list;
      if (priority_list.length > 0) {
        if (where_sql === '') {
          where_sql = ' WHERE ';
        } else {
          where_sql += ' and ';
        }
        where_sql += layer_x + '[(general.priority = ' + priority_list.join(',') + ')] ';
      }
      let status_list = this.d_menuData.list[3].list[1].list[0].selected_option_list;
      if (status_list.length > 0) {
        if (where_sql === '') {
          where_sql = ' WHERE ';
        } else {
          where_sql += ' and ';
        }
        where_sql += layer_x + '[(general.status = ' + status_list.join(',') + ')] ';
      }
      let task_type_list = this.d_menuData.list[3].list[2].list[0].selected_option_list;
      if (task_type_list.length > 0) {
        if (where_sql === '') {
          where_sql = ' WHERE ';
        } else {
          where_sql += ' and ';
        }
        where_sql += layer_x + '[(general.task_type = ' + task_type_list.join(',') + ')] ';
      }
      let calender_date_type = '';
      try {
        calender_date_type = this.d_menuData.list[4].list[0].list[0].selected_option_list[0];
      } catch (err) {}
      if (calender_date_type) {
        let d_list = [];
        if (calender_date_type === 'this_month') {
          d_list = DateFunctions.get_this_month_begin_and_end_days();
        } else if (calender_date_type === 'today') {
          d_list = DateFunctions.get_today_begin_end_dates();
        } else if (calender_date_type === 'this_week') {
          d_list = DateFunctions.get_week_begin_end_dates();
        }
        // console.log("d_list: ", d_list);
        if (d_list.length === 2) {
          if (where_sql === '') {
            where_sql = ' WHERE ';
          } else {
            where_sql += ' and ';
          }
          where_sql += '( ';
          where_sql += layer_x + '[(general.desired_begin_date >= ' + d_list[0] + ') and (general.desired_begin_date <= ' + d_list[1] + ')]';
          where_sql += ' or '
          where_sql += layer_x + '[(general.desired_end_date >= ' + d_list[0] + ') and (general.desired_end_date <= ' + d_list[1] + ')]';
          where_sql += ' or '
          where_sql += layer_x + '[(general.desired_begin_date <= ' + d_list[0] + ') and (general.desired_end_date >= ' + d_list[0] + ')]';
          where_sql += ' or '
          where_sql += layer_x + '[(general.desired_begin_date <= ' + d_list[1] + ') and (general.desired_end_date >= ' + d_list[1] + ')]';
          where_sql += ' )';
        }
      }
      // where_sql += " order = desc ";
      // console.log("where_sql : ", where_sql);
      return where_sql;
    },
    f_prepareReportByWmanagerSql: function (wdm307_id_list = []) {
      if (!this.d_loading.status) {
        let organization_id_list = [];
        // console.log('this.d_organizationListData : ', this.d_organizationListData);
        // console.log('wdm307_id_list : ', wdm307_id_list);
        if (this.d_organizationListData.selected_index !== '') {
          organization_id_list.push(this.d_organizationListData.list[this.d_organizationListData.selected_index].w_id);
        } else {
          for (let i in this.d_organizationListData.list) {
            let wdm307_id = this.d_organizationListData.list[i].w_id;
            // console.log('wdm307_id : ', wdm307_id);
            organization_id_list.push(wdm307_id);
          }
          // console.log('organization_id_list : ', organization_id_list);
        }
        if (organization_id_list.length > 0) {
          this.d_loading.status = true;
          let limit = '';
          let offset = '';
          let order = ''; // asc or desc
          let last_case_point = '';
          let start_type = 'end';
          let layer_x = 'layer' + this.d_WmanagerLayerData['operation'].layer_id;
          let sql_text = 'SELECT ' + layer_x + ' FROM ' + layer_x;
          let where_sql = '';
          if (this.d_filter.operation_no !== '') {
            sql_text += '[key in wdm17_' + this.d_filter.operation_no + ']';
            limit = '';
            offset = '';
          } else {
            where_sql = this.f_createSqlWhere();
            if (organization_id_list.length > 0) {
              sql_text += '.start([' + organization_id_list.join(',') + '])';
            }
          }
          sql_text += where_sql
          let data = {
            'sql_text': sql_text,
            'last_case_point': last_case_point,
            'limit': limit,
            'offset': offset,
            'order': order,
          };
          this.d_reports = { 'parameters': {}, 'paramorder': [] };
          OrganizationService.report_get_by_wmanager_sql(data)
            .then(resp => {
              this.d_loading.status = false;
              if (resp.data.status_code === '12000') {
                this.d_reports = resp.data.report_list;
              } else {
                alert('error ' + resp.data.status_code + ' / ' + resp.data.status_message);
              }
            }, resp => {
              let msg = resp.status_code + ' ' + resp.status_message;
              let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Hata mesajı' };
              this.$store.commit('MutationModal', modal_data);
              this.$store.commit('MutationModalShow', true);
              // this.$store.commit('MutationLoading', { 'status': false, 'data': {} });
              console.log(resp);
            });
        }
      } else {
        alert('İşlem devam ediyor.');
      }
    },
    f_createWorkTimeTaskList: function () {
      let sort_type = 'reversed';
      // this.d_workTimeTaskList.selected_task_index_list = [];
      // this.d_workTimeTaskList.selected_date_list = [];
      this.d_taskPriorityTextStyle = { 'task_list': {} };
      this.d_workTimeDateFilter.selected_list = [];
      this.d_workTimeTaskList.task_all_date_match = {};
      this.d_workTimeTaskList.not_tag_including_task_list = [];
      this.d_workTimeTaskList.work_time_date_view_match_list = [];
      this.d_workTimeTaskList.work_time_date_order = [];
      this.d_workTimeTaskList.log_status_list = {};
      this.d_workTimeTaskList.tag_order = [];
      this.d_workTimeTaskList.tags = {};
      this.d_workTimeTaskList.count = 0;
      this.d_taskOrganizationMatch = { 'task_list': {} };

      // console.log('this.d_organizationListData.list ', this.d_organizationListData.list);
      for (let task_ind in this.d_taskList) {
        let task_all_date_list = [];
        // console.log('task_ind : ', task_ind);
        let task_data = this.d_taskList[task_ind];
        let use_this_task = false;
        if (this.d_filter.search_text) {
          let operation_name = '';
          try {
            operation_name = task_data.data.general.name.val;
          } catch (err) {}
          if (operation_name && operation_name.toLocaleLowerCase().indexOf(this.d_filter.search_text.toLocaleLowerCase()) !== -1) {
            use_this_task = true;
          }
          if (!use_this_task) {
            let task_note = '';
            try {
              task_note = task_data.data.general.note.val;
            } catch (err) {}
            if (task_note && task_note.toLocaleLowerCase().indexOf(this.d_filter.search_text.toLocaleLowerCase()) !== -1) {
              use_this_task = true;
            }
          }
          if (!use_this_task) {
            let task_priority_label = '';
            try {
              task_priority_label = task_data.data.general.priority.val.label;
            } catch (err) {}
            if (task_priority_label && task_priority_label.toLocaleLowerCase().indexOf(this.d_filter.search_text.toLocaleLowerCase()) !== -1) {
              use_this_task = true;
            }
          }
          if (!use_this_task) {
            if (task_data.id) {
              let organizaton_id = '';
              try {
                if (!this.d_selectedWdmr307) {
                  organizaton_id = task_data.id_tree[0]['id'][task_data.id_tree[0]['id'].length - 2].split('_')[1];
                }
              } catch (err) {}
              // console.log('organizaton_id : ', organizaton_id);
              if (organizaton_id) {
                for (let organization_index in this.d_organizationListData.list) {
                  if (this.d_organizationListData.list[organization_index].w_id === organizaton_id) {
                    let organization_name = this.d_organizationListData.list[organization_index].label;
                    if (organization_name.toLocaleLowerCase().indexOf(this.d_filter.search_text.toLocaleLowerCase()) !== -1) {
                      use_this_task = true;
                      break;
                    }
                  }
                }
              }
            }
          }
        } else {
          use_this_task = true;
        }
        if (use_this_task) {
          if (task_data && task_data.data && task_data.data.general && task_data.data.general.priority && task_data.data.general.priority.val && task_data.data.general.priority.val.value) {
            if (task_data.data.general.priority.val.value === 'urgent') {
              this.d_taskPriorityTextStyle.task_list[task_data.id] = 'color: red; padding: 2px; font-size: 10px; float: left; min-width: 75px; font-weight: bold;';
            } else if (task_data.data.general.priority.val.value === 'high') {
              this.d_taskPriorityTextStyle.task_list[task_data.id] = 'color: #f154ec; padding: 2px; font-size: 10px; float: left; min-width: 75px; font-weight: bold;';
            } else if (task_data.data.general.priority.val.value === 'medium') {
              this.d_taskPriorityTextStyle.task_list[task_data.id] = 'color: #00c4ff; padding: 2px; font-size: 10px; float: left; min-width: 75px; font-weight: bold;';
            } else if (task_data.data.general.priority.val.value === 'low') {
              this.d_taskPriorityTextStyle.task_list[task_data.id] = 'color: #67e667; padding: 2px; font-size: 10px; float: left; min-width: 75px; font-weight: bold;';
            } else if (task_data.data.general.priority.val.value === 'none') {
              this.d_taskPriorityTextStyle.task_list[task_data.id] = 'color: black; padding: 2px; font-size: 10px; float: left; min-width: 75px; font-weight: bold;';
            }
          }
          if (task_data.id) {
            let organizaton_id = '';
            try {
              if (this.d_selectedWdmr307 && this.d_selectedWdmr307.id) {
                organizaton_id = this.d_selectedWdmr307.id;
              } else {
                organizaton_id = task_data.id_tree[0]['id'][task_data.id_tree[0]['id'].length - 2].split('_')[1];
              }
            } catch (err) {}
            // console.log('organizaton_id : ', organizaton_id);
            if (organizaton_id) {
              for (let organization_index in this.d_organizationListData.list) {
                if (this.d_organizationListData.list[organization_index].w_id === organizaton_id) {
                  this.d_taskOrganizationMatch['task_list'][task_data.id] = this.d_organizationListData.list[organization_index];
                  break;
                }
              }
            }
          }
          let is_minimum_one_date_eligible = this.f_isTaskDatesEligibleForAddingWorkTimeList(task_data);
          /*
            We have some filters: tag, date, task index.
          */
          // console.log('this.d_workTimeTaskIndexFilter.selected_list.length : ', this.d_workTimeTaskIndexFilter.selected_list.length);
          if (is_minimum_one_date_eligible) {
            if (this.d_workTimeTaskIndexFilter.selected_list.length === 0 || (this.d_workTimeTaskIndexFilter.selected_list.length > 0 && this.d_workTimeTaskIndexFilter.selected_list.indexOf(parseInt(task_ind)) !== -1)) {
              let is_task_eligible_for_tag_control = false;
              let is_tag_including = false;
              try {
                for (let i in task_data.data.general.tag.list) {
                  let tag_name = task_data.data.general.tag.list[i].tag_name.val;
                  if (tag_name) {
                    is_tag_including = true;
                    if (this.d_workTimeTagFilter.selected_list.length === 0 || (this.d_workTimeTagFilter.selected_list.length > 0 && this.d_workTimeTagFilter.selected_list.indexOf(tag_name) !== -1)) {
                      is_task_eligible_for_tag_control = true;

                      if (this.d_workTimeTaskList.tag_order.indexOf(tag_name) === -1) {
                        this.d_workTimeTaskList.tag_order.push(tag_name);
                        this.d_workTimeTaskList.tags[tag_name] = { 'task_list': [], 'sub_tag_list': {}, 'sub_tag_order': [] };
                      }

                      let is_sub_tag_found = false;
                      try {
                        for (let sub_tag_index in task_data.data.general.tag.list) {
                          let sub_tag_name = task_data.data.general.tag.list[sub_tag_index].tag_name.val;
                          if (sub_tag_name && sub_tag_name !== tag_name) {
                            is_sub_tag_found = true;
                            if (this.d_workTimeTaskList.tags[tag_name].sub_tag_order.indexOf(sub_tag_name) === -1) {
                              this.d_workTimeTaskList.tags[tag_name].sub_tag_order.push(sub_tag_name);
                              this.d_workTimeTaskList.tags[tag_name].sub_tag_list[sub_tag_name] = { 'task_list': [] };
                            }
                            let task_det = false;
                            for (let t_index in this.d_workTimeTaskList.tags[tag_name].sub_tag_list[sub_tag_name].task_list) {
                              if (this.d_workTimeTaskList.tags[tag_name].sub_tag_list[sub_tag_name].task_list[t_index].task_id === task_data.id) {
                                task_det = true;
                                break;
                              }
                            }
                            if (!task_det) {
                              this.d_workTimeTaskList.tags[tag_name].sub_tag_list[sub_tag_name].task_list.push({ 'task_index': task_ind, 'task_id': task_data.id });
                            }
                          }
                        }
                      } catch (err) {}

                      if (!is_sub_tag_found) {
                        let task_det = false;
                        for (let t_index in this.d_workTimeTaskList.tags[tag_name].task_list) {
                          if (this.d_workTimeTaskList.tags[tag_name].task_list[t_index].task_id === task_data.id) {
                            task_det = true;
                            break;
                          }
                        }
                        if (!task_det) {
                          this.d_workTimeTaskList.tags[tag_name].task_list.push({ 'task_index': task_ind, 'task_id': task_data.id });
                        }
                      }
                    }
                  }
                }
              } catch (err) {}

              if (!is_tag_including && this.d_workTimeTagFilter.selected_list.length === 0) {
                is_task_eligible_for_tag_control = true;
                this.d_workTimeTaskList.not_tag_including_task_list.push({ 'task_index': task_ind, 'task_id': task_data.id });
              }
              if (is_task_eligible_for_tag_control) {
                this.d_workTimeTaskList.count += 1;
                try {
                  for (let i = 0; i < task_data.data.general.log.list.length; i++) {
                    if (task_data.data.general.log.list[i].log_datetime && task_data.data.general.log.list[i].log_datetime.val) {
                      let assigned_date = '';
                      if (task_data.data.general.log.list[i].log_datetime.val.indexOf('T') !== -1) {
                        assigned_date = task_data.data.general.log.list[i].log_datetime.val.split('T')[0];
                      } else if (task_data.data.general.log.list[i].log_datetime.val.indexOf(' ') !== -1) {
                        assigned_date = task_data.data.general.log.list[i].log_datetime.val.split(' ')[0];
                      }
                      // console.log('assigned_date : ', assigned_date);
                      if (assigned_date) {
                        if (this.d_workTimeDateFilter.selected_list.length === 0 || (this.d_workTimeDateFilter.selected_list.length > 0 && this.d_workTimeDateFilter.selected_list.indexOf(assigned_date) !== -1)) {
                          task_all_date_list.push(assigned_date);
                          if (this.d_workTimeTaskList.work_time_date_order.indexOf(assigned_date) === -1) {
                            this.d_workTimeTaskList.work_time_date_order.push(assigned_date);
                          }
                          if (this.d_workTimeTaskList.log_status_list[assigned_date] === undefined) {
                            this.d_workTimeTaskList.log_status_list[assigned_date] = {};
                          }
                          if (this.d_workTimeTaskList.log_status_list[assigned_date][task_data.id] === undefined) {
                            this.d_workTimeTaskList.log_status_list[assigned_date][task_data.id] = { 'log_status_list': [] };
                          }
                          let log_det = false;
                          for (let log_s_ind in this.d_workTimeTaskList.log_status_list[assigned_date][task_data.id].log_status_list) {
                            if (task_data.data.general.log.list[i].log_status.val.value === this.d_workTimeTaskList.log_status_list[assigned_date][task_data.id].log_status_list[log_s_ind].value) {
                              log_det = true;
                              break;
                            }
                          }
                          if (!log_det) {
                            this.d_workTimeTaskList.log_status_list[assigned_date][task_data.id].log_status_list.push(task_data.data.general.log.list[i].log_status.val);
                          }
                        }
                      }
                    }
                  }
                } catch (err) {}

                /*
                  let created_date = '';
                  if (task_data.created_at.indexOf('T') !== -1) {
                    created_date = task_data.created_at.split('T')[0];
                  } else if (task_data.created_at.indexOf(' ') !== -1) {
                    created_date = task_data.created_at.split(' ')[0];
                  }
                  if (this.d_workTimeTaskList.work_time_date_order.indexOf(created_date) === -1) {
                    this.d_workTimeTaskList.work_time_date_order.push(created_date);
                  }
                */
                try {
                  let desired_begin_date = '';
                  if (task_data.data.general.desired_begin_date.val.indexOf('T') !== -1) {
                    desired_begin_date = task_data.data.general.desired_begin_date.val.split('T')[0];
                  } else if (task_data.data.general.desired_begin_date.val.indexOf(' ') !== -1) {
                    desired_begin_date = task_data.data.general.desired_begin_date.val.split(' ')[0];
                  }
                  if (desired_begin_date) {
                    if (this.d_workTimeDateFilter.selected_list.length === 0 || (this.d_workTimeDateFilter.selected_list.length > 0 && this.d_workTimeDateFilter.selected_list.indexOf(desired_begin_date) !== -1)) {
                      task_all_date_list.push(desired_begin_date);
                      if (this.d_workTimeTaskList.work_time_date_order.indexOf(desired_begin_date) === -1) {
                        this.d_workTimeTaskList.work_time_date_order.push(desired_begin_date);
                      }
                    }
                  }
                } catch (err) {}
                try {
                  let desired_end_date = '';
                  if (task_data.data.general.desired_end_date.val.indexOf('T') !== -1) {
                    desired_end_date = task_data.data.general.desired_end_date.val.split('T')[0];
                  } else if (task_data.data.general.desired_end_date.val.indexOf(' ') !== -1) {
                    desired_end_date = task_data.data.general.desired_end_date.val.split(' ')[0];
                  }
                  if (desired_end_date) {
                    if (this.d_workTimeDateFilter.selected_list.length === 0 || (this.d_workTimeDateFilter.selected_list.length > 0 && this.d_workTimeDateFilter.selected_list.indexOf(desired_end_date) !== -1)) {
                      task_all_date_list.push(desired_end_date);
                      if (this.d_workTimeTaskList.work_time_date_order.indexOf(desired_end_date) === -1) {
                        this.d_workTimeTaskList.work_time_date_order.push(desired_end_date);
                      }
                    }
                  }
                } catch (err) {}

                try {
                  for (let i in task_data.data.general.work_time.list) {
                    try {
                      let work_time_start_date = '';
                      if (task_data.data.general.work_time.list[i].start.val.indexOf('T') !== -1) {
                        work_time_start_date = task_data.data.general.work_time.list[i].start.val.split('T')[0];
                      } else if (task_data.data.general.work_time.list[i].start.val.indexOf(' ') !== -1) {
                        work_time_start_date = task_data.data.general.work_time.list[i].start.val.split(' ')[0];
                      }
                      if (work_time_start_date) {
                        if (this.d_workTimeDateFilter.selected_list.length === 0 || (this.d_workTimeDateFilter.selected_list.length > 0 && this.d_workTimeDateFilter.selected_list.indexOf(work_time_start_date) !== -1)) {
                          task_all_date_list.push(work_time_start_date);
                          if (this.d_workTimeTaskList.work_time_date_order.indexOf(work_time_start_date) === -1) {
                            this.d_workTimeTaskList.work_time_date_order.push(work_time_start_date);
                          }
                        }
                      }
                    } catch (err) {}
                    try {
                      let work_time_end_date = '';
                      if (task_data.data.general.work_time.list[i].end.val.indexOf('T') !== -1) {
                        work_time_end_date = task_data.data.general.work_time.list[i].end.val.split('T')[0];
                      } else if (task_data.data.general.work_time.list[i].end.val.indexOf(' ') !== -1) {
                        work_time_end_date = task_data.data.general.work_time.list[i].end.val.split(' ')[0];
                      }
                      if (work_time_end_date) {
                        if (this.d_workTimeDateFilter.selected_list.length === 0 || (this.d_workTimeDateFilter.selected_list.length > 0 && this.d_workTimeDateFilter.selected_list.indexOf(work_time_end_date) !== -1)) {
                          task_all_date_list.push(work_time_end_date);
                          if (this.d_workTimeTaskList.work_time_date_order.indexOf(work_time_end_date) === -1) {
                            this.d_workTimeTaskList.work_time_date_order.push(work_time_end_date);
                          }
                        }
                      }
                    } catch (err) {}
                  }
                } catch (err) {}
                task_all_date_list.sort();
                this.d_workTimeTaskList.task_all_date_match[task_data.id] = task_all_date_list;
              }
            }
          }
        }
      }
      /*
        if (this.d_workTimeDateFilter.selected_list.length === 0) {
        }
      */
      let date = new Date();
      let today_date = moment(date).toISOString().split('T')[0];
      if (this.d_workTimeTaskList.work_time_date_order.indexOf(today_date) === -1) {
        this.d_workTimeTaskList.work_time_date_order.push(today_date);
      }
      this.d_workTimeTaskList.work_time_date_order.sort();
      if (sort_type === 'reversed') {
        this.d_workTimeTaskList.work_time_date_order.reverse();
      }
      this.d_workTimeTaskList.tag_order.sort();
      for (let i in this.d_workTimeTaskList.tags) {
        if (this.d_workTimeTaskList.tags[i].sub_tag_order && this.d_workTimeTaskList.tags[i].sub_tag_order.length > 0) {
          for (let sub_tag_index in this.d_workTimeTaskList.tags[i].sub_tag_order) {
            let sub_tag_name = this.d_workTimeTaskList.tags[i].sub_tag_order[sub_tag_index];
            let new_sub_task_list = [];
            let new_added_sub_task_list = []
            for (let dt_index in this.d_workTimeTaskList.work_time_date_order) {
              let date_x = this.d_workTimeTaskList.work_time_date_order[dt_index];
              for (let t_index in this.d_workTimeTaskList.tags[i].sub_tag_list[sub_tag_name].task_list) {

                let task_item = this.d_workTimeTaskList.tags[i].sub_tag_list[sub_tag_name].task_list[t_index];
                if (new_added_sub_task_list.indexOf(task_item.task_id) === -1 && this.d_workTimeTaskList.task_all_date_match[task_item.task_id] !== undefined && this.d_workTimeTaskList.task_all_date_match[task_item.task_id].indexOf(date_x) !== -1) {
                  new_added_sub_task_list.push(task_item.task_id);
                  new_sub_task_list.push(task_item);
                }

              }
              if (this.d_workTimeTaskList.tags[i].sub_tag_list[sub_tag_name].task_list.length === new_sub_task_list.length) {
                this.d_workTimeTaskList.tags[i].sub_tag_list[sub_tag_name].task_list = new_sub_task_list;
              }
            }
          }
        }
        let new_task_list = [];
        let added_task_list = []
        for (let dt_index in this.d_workTimeTaskList.work_time_date_order) {
          let date_x = this.d_workTimeTaskList.work_time_date_order[dt_index];
          for (let t in this.d_workTimeTaskList.tags[i].task_list) {

            let task_item = this.d_workTimeTaskList.tags[i].task_list[t];
            if (added_task_list.indexOf(task_item.task_id) === -1 && this.d_workTimeTaskList.task_all_date_match[task_item.task_id] !== undefined && this.d_workTimeTaskList.task_all_date_match[task_item.task_id].indexOf(date_x) !== -1) {
              added_task_list.push(task_item.task_id);
              new_task_list.push(task_item);
            }

          }
          if (this.d_workTimeTaskList.tags[i].task_list.length === new_task_list.length) {
            this.d_workTimeTaskList.tags[i].task_list = new_task_list;
          }
        }
      }
      let not_tag_new_task_list = [];
      let not_tag_added_task_list = []
      for (let dt_index in this.d_workTimeTaskList.work_time_date_order) {
        let date_x = this.d_workTimeTaskList.work_time_date_order[dt_index];
        for (let t_index in this.d_workTimeTaskList.not_tag_including_task_list) {
          let task_item = this.d_workTimeTaskList.not_tag_including_task_list[t_index];
          if (not_tag_added_task_list.indexOf(task_item.task_id) === -1 && this.d_workTimeTaskList.task_all_date_match[task_item.task_id] !== undefined && this.d_workTimeTaskList.task_all_date_match[task_item.task_id].indexOf(date_x) !== -1) {
            not_tag_added_task_list.push(task_item.task_id);
            not_tag_new_task_list.push(task_item);
          }

        }
        if (this.d_workTimeTaskList.not_tag_including_task_list.length === not_tag_new_task_list.length) {
          this.d_workTimeTaskList.not_tag_including_task_list = not_tag_new_task_list;
        }
      }
      // *********************************
      // console.log('this.d_workTimeTaskList.work_time_date_order ', this.d_workTimeTaskList.work_time_date_order);
      this.d_taskBetweenDesiredStartAndEndList = { 'task_list': {} };
      this.d_taskDesiredDayToday = { 'task_list': {} };
      this.d_workTimeTaskList.work_time_date_view_match_list = {};
      for (let dt_index in this.d_workTimeTaskList.work_time_date_order) {
        let date_x = this.d_workTimeTaskList.work_time_date_order[dt_index];
        this.d_workTimeTaskList.work_time_date_view_match_list[date_x] = this.DateFormat(date_x);
        for (let task_ind in this.d_taskList) {
          let task_data = this.d_taskList[task_ind];
          if (task_data.id) {
            let is_between_desired_start_and_end_day = this.f_isBetweenDesiredBeginAndEndDateToday(task_data, date_x);
            if (is_between_desired_start_and_end_day) {
              if (!this.d_taskBetweenDesiredStartAndEndList['task_list'][task_data.id]) {
                this.d_taskBetweenDesiredStartAndEndList['task_list'][task_data.id] = { 'date_list': [] };
              }
              this.d_taskBetweenDesiredStartAndEndList['task_list'][task_data.id].date_list.push(date_x);
            }
            let is_desired_end_day_this_day = this.f_isDesiredEndDateToday(task_data, date_x);
            if (is_desired_end_day_this_day) {
              this.d_taskDesiredDayToday['task_list'][task_data.id] = date_x;
            }
          }
        }
      }
      // console.log('this.d_taskBetweenDesiredStartAndEndList : ', this.d_taskBetweenDesiredStartAndEndList);
      // console.log('this.d_taskDesiredDayToday : ', this.d_taskDesiredDayToday);
      // console.log('this.d_taskOrganizationMatch : ', this.d_taskOrganizationMatch);
      // console.log('this.d_workTimeDateFilter.all_list : ', this.d_workTimeDateFilter.all_list);
      // console.log('this.d_taskPriorityTextStyle : ', this.d_taskPriorityTextStyle);
      if (this.d_workTimeTaskList.work_time_date_order.length > this.d_workTimeDateFilter.all_list.length) {
        this.d_workTimeDateFilter.all_list = this.d_workTimeTaskList.work_time_date_order;
      }
      if (this.d_workTimeTaskList.tag_order.length > this.d_workTimeTagFilter.all_list.length) {
        this.d_workTimeTagFilter.all_list = this.d_workTimeTaskList.tag_order;
      }
      this.f_taskDateFilter(sort_type);
    },
    f_taskDateFilter: function (type = 'sort') {
      for (let i = 0; i < this.d_workTimeDateFilter.selected_range_count; i++) {
        if (type === 'sort') {
          let target_index = this.d_workTimeDateFilter.all_list.length - 1 - i;
          if (target_index > 0) {
            this.d_workTimeDateFilter.selected_list.push(this.d_workTimeDateFilter.all_list[target_index]);
          }
        } else if (type === 'reversed') {
          if (i < this.d_workTimeDateFilter.all_list.length - 1) {
            this.d_workTimeDateFilter.selected_list.push(this.d_workTimeDateFilter.all_list[i]);
          }
        }
      }
    },
    f_isTaskDatesEligibleForAddingWorkTimeList: function (task_data) {
      try {
        for (let i = 0; i < task_data.data.general.log.list.length; i++) {
          if (task_data.data.general.log.list[i].log_datetime && task_data.data.general.log.list[i].log_datetime.val) {
            let assigned_date = '';
            if (task_data.data.general.log.list[i].log_datetime.val.indexOf('T') !== -1) {
              assigned_date = task_data.data.general.log.list[i].log_datetime.val.split('T')[0];
            } else if (task_data.data.general.log.list[i].log_datetime.val.indexOf(' ') !== -1) {
              assigned_date = task_data.data.general.log.list[i].log_datetime.val.split(' ')[0];
            }
            // console.log('assigned_date : ', assigned_date);
            if (assigned_date) {
              if (this.d_workTimeDateFilter.selected_list.length === 0 || (this.d_workTimeDateFilter.selected_list.length > 0 && this.d_workTimeDateFilter.selected_list.indexOf(assigned_date) !== -1)) {
                return true;
              }
            }
          }
        }
      } catch (err) {}

      try {
        let desired_begin_date = '';
        if (task_data.data.general.desired_begin_date.val.indexOf('T') !== -1) {
          desired_begin_date = task_data.data.general.desired_begin_date.val.split('T')[0];
        } else if (task_data.data.general.desired_begin_date.val.indexOf(' ') !== -1) {
          desired_begin_date = task_data.data.general.desired_begin_date.val.split(' ')[0];
        }
        if (desired_begin_date) {
          if (this.d_workTimeDateFilter.selected_list.length === 0 || (this.d_workTimeDateFilter.selected_list.length > 0 && this.d_workTimeDateFilter.selected_list.indexOf(desired_begin_date) !== -1)) {
            return true;
          }
        }
      } catch (err) {}
      try {
        let desired_end_date = '';
        if (task_data.data.general.desired_end_date.val.indexOf('T') !== -1) {
          desired_end_date = task_data.data.general.desired_end_date.val.split('T')[0];
        } else if (task_data.data.general.desired_end_date.val.indexOf(' ') !== -1) {
          desired_end_date = task_data.data.general.desired_end_date.val.split(' ')[0];
        }
        if (desired_end_date) {
          if (this.d_workTimeDateFilter.selected_list.length === 0 || (this.d_workTimeDateFilter.selected_list.length > 0 && this.d_workTimeDateFilter.selected_list.indexOf(desired_end_date) !== -1)) {
            return true;
          }
        }
      } catch (err) {}

      try {
        for (let i in task_data.data.general.work_time.list) {
          try {
            let work_time_start_date = '';
            if (task_data.data.general.work_time.list[i].start.val.indexOf('T') !== -1) {
              work_time_start_date = task_data.data.general.work_time.list[i].start.val.split('T')[0];
            } else if (task_data.data.general.work_time.list[i].start.val.indexOf(' ') !== -1) {
              work_time_start_date = task_data.data.general.work_time.list[i].start.val.split(' ')[0];
            }
            if (work_time_start_date) {
              if (this.d_workTimeDateFilter.selected_list.length === 0 || (this.d_workTimeDateFilter.selected_list.length > 0 && this.d_workTimeDateFilter.selected_list.indexOf(work_time_start_date) !== -1)) {
                return true;
              }
            }
          } catch (err) {}
        }
      } catch (err) {}
      try {
        let work_time_end_date = '';
        if (task_data.data.general.work_time.list[i].end.val.indexOf('T') !== -1) {
          work_time_end_date = task_data.data.general.work_time.list[i].end.val.split('T')[0];
        } else if (task_data.data.general.work_time.list[i].end.val.indexOf(' ') !== -1) {
          work_time_end_date = task_data.data.general.work_time.list[i].end.val.split(' ')[0];
        }
        if (work_time_end_date) {
          if (this.d_workTimeDateFilter.selected_list.length === 0 || (this.d_workTimeDateFilter.selected_list.length > 0 && this.d_workTimeDateFilter.selected_list.indexOf(work_time_end_date) !== -1)) {
            return true;
          }
        }
      } catch (err) {}

      return false;
    },
    f_updateOperations: function () {
      return;
      let new_task_list = [];
      for (let i in this.d_allTaskList) {
        let x = this.f_applyFilterOperationRule(this.d_allTaskList[i]);
        if (x) {
          new_task_list.push(this.d_allTaskList[i]);
        }
      }
      this.d_taskList = new_task_list;
      this.$forceUpdate();
    },
    f_applyFilterOperationRule: function (wdmr17) {
      // kullanıcı 0, öncelik, 1, durum, 2, operasyon tipi 3
      if (wdmr17 && wdmr17.data && wdmr17.data.general) {
        if (this.d_filter.operation_no !== '') {
          if (wdmr17.id !== this.d_filter.operation_no) {
            return false;
          }
        } else {
          if (this.d_filter.search_text) {
            let x = true;
            let y = true;
            try {
              x = wdmr17.data.general.operation_name.val.toLocaleLowerCase().indexOf(this.d_filter.search_text.toLocaleLowerCase()) !== -1;
            } catch (err) {}
            try {
              y = wdmr17.data.general.note.val.toLocaleLowerCase().indexOf(this.d_filter.search_text.toLocaleLowerCase()) !== -1;
            } catch (err) {}
            if (!x && !y) {
              return false;
            }
          } else {
            let user_id_list_who_assigned = this.d_menuData.list[2].list[0].list[0].selected_option_list;
            // console.log('user_id_list_who_assigned', user_id_list_who_assigned);
            if (user_id_list_who_assigned.length > 0) {
              // if we find any user who is assigned to this task, we will accept this operation det_one_user is true.
              let det_one_user = false;
              try {
                for (let i in wdmr17.data.general.log.list) {
                  let log_user_id = wdmr17.data.general.log.list[i].log_user.val.value;
                  let log_status = wdmr17.data.general.log.list[i].log_status.val.value;
                  // console.log('log_user_id: ', log_user_id);
                  if (user_id_list_who_assigned.indexOf(log_user_id) !== -1 && log_status === 'assigned') {
                    det_one_user = true;
                    break;
                  }
                }
              } catch (err) {}
              if (!det_one_user) {
                return false;
              }
            }
            let assigned_user_id_list = this.d_menuData.list[2].list[1].list[0].selected_option_list;
            // console.log('assigned_user_id_list', assigned_user_id_list);
            if (assigned_user_id_list.length > 0) {
              // if we find any user who is assigned to this task, we will accept this operation det_one_user is true.
              let det_one_user = false;
              try {
                for (let i in wdmr17.data.general.log.list) {
                  let which_user_log_id = wdmr17.data.general.log.list[i].which_user_log.val.value;
                  let log_status = wdmr17.data.general.log.list[i].log_status.val.value;
                  // console.log('which_user_log_id: ', which_user_log_id);
                  if (assigned_user_id_list.indexOf(which_user_log_id) !== -1 && log_status === 'assigned') {
                    det_one_user = true;
                    break;
                  }
                }
              } catch (err) {}
              if (!det_one_user) {
                return false;
              }
            }
            let priority_list = this.d_menuData.list[3].list[0].list[0].selected_option_list;
            if (priority_list.length > 0) {
              if (!wdmr17.data.general.priority || (wdmr17.data.general.priority && priority_list.indexOf(wdmr17.data.general.priority.val.value) === -1)) {
                return false;
              }
            }
            let status_list = this.d_menuData.list[3].list[1].list[0].selected_option_list;
            if (status_list.length > 0) {
              if (!wdmr17.data.general.status || (wdmr17.data.general.status && status_list.indexOf(wdmr17.data.general.status.val.value) === -1)) {
                return false;
              }
            }
            let task_type_list = this.d_menuData.list[3].list[2].list[0].selected_option_list;
            if (task_type_list.length > 0) {
              if (!wdmr17.data.general.task_type || (wdmr17.data.general.task_type && task_type_list.indexOf(wdmr17.data.general.task_type.val.value) === -1)) {
                return false;
              }
            }
          }
        }
        return true;
      } else {
        return false;
      }
    },
    f_openOrganizationList: function () {
      this.d_organizationListData.show = true;
      setTimeout(function () {
        let doc = document.getElementById('organization_search');
        doc.focus();
      }, 50);
    },
    f_addRemoveUser: function (user) {
      if (this.d_selectedWdm22IdList.indexOf(user.w_id) === -1) {
        this.d_selectedWdm22IdList.push(user.w_id);
      } else {
        this.d_selectedWdm22IdList.splice(this.d_selectedWdm22IdList.indexOf(user.w_id), 1);
      }
      this.$forceUpdate();
    },
    f_newOperation: function () {
      if (this.d_organizationListData.selected_index !== '' && this.d_selectedWdmr307 && this.d_selectedWdmr307.id) {
        this.d_newOperationW_id = '';
        this.d_wisdomData.w_id_list = [];
        this.d_wisdomData.wisdom_op = 'new';
        this.d_wisdomData.data_type = 'wdm17';
        this.d_wisdomData.url_iframe = '';
        let w_id_list = [this.d_selectedWdmr307.id];
        /*
          let layer_type = 'operation';
          let query = 'organization_id=' + this.d_selectedWdmr307.id;
          let data = {
            'operation_data': { 'data': {} }
          };
          OrganizationService.operation_save(query, data)
            .then(resp => {
              if (resp.data.status_code === '12000') {
                let wmanager_local_storage = JSON.parse(localStorage.getItem('wmanager'));
                w_id_list.push(resp.data.operation_id);
                this.d_wisdomData.w_id_list = w_id_list;
                this.d_wisdomData.url_iframe = this.WDMR_LINKS['record'] + 'layer_id=' + this.d_WmanagerLayerData[layer_type].layer_id + '&w_id_list=' + w_id_list.join(',') + '&wisdom=' + wmanager_local_storage.access_token + '&modsiw=' + wmanager_local_storage.refresh_token;
                this.d_wisdomData.layer_type = layer_type;
                this.d_wisdomData.data_type = this.d_WmanagerLayerData[layer_type].wdm_type;
                this.d_wisdomData.show = true;
              } else {
                console.log('error :' + resp.data.message);
              }
            });
        */
        let query = 'parent_wdmr_id=' + this.d_selectedWdmr307.id;
        OrganizationService.operation_wdmr_token_get(query)
          .then(resp => {
            if (resp.data.status_code === '12000') {
              w_id_list.push(resp.data.wdmr_id);
              this.d_wisdomData.w_id_list = w_id_list;
              this.d_showWdmrPreview.url_show_iframe = resp.data.url_show_iframe;
              this.d_wisdomData.url_iframe = resp.data.url_record_iframe;
              this.d_wisdomData.show = true;
            } else {
              alert('error ' + resp.data.message);
            }
          });

      } else {
        let msg = 'Lütfen öncelikle organizasyon seçimi yapınız';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Organizasyon düzenleme işlemi hakkında' };
        this.$store.commit('MutationModal', modal_data);
        this.$store.commit('MutationModalShow', true);
      }
    },
    f_saveOperationStatus: function (selected_status, task_ind) {
      let which_user_log = {
        'val': {
          'label': this.user.first_name + ' ' + this.user.last_name,
          'value': this.user.id
        }
      };
      let operation_data = this.d_taskList[task_ind];
      this.f_addStatusAsLogToTask(operation_data, selected_status, which_user_log);
      let layer_type = 'operation';
      /*
        let parent_wdmr_id = '';
        if (this.d_selectedWdmr307 && this.d_selectedWdmr307.id) {
          parent_wdmr_id = this.d_selectedWdmr307.id;
        } else {
          parent_wdmr_id = operation_data.id_tree[0]['id'][operation_data.id_tree[0]['id'].length - 2].split('_')[1];
        }
      */
      let parent_wdmr_id = operation_data.id_tree[0]['id'][operation_data.id_tree[0]['id'].length - 2].split('_')[1];
      this.$store.commit('MutationLoading', { 'status': true, 'data': { 'label': 'Operasyon kayıt işlemi tamamlanıyor. Lütfen bekleyiniz.' } });
      let query = 'organization_id=' + parent_wdmr_id;
      query += '&operation_id=' + operation_data.id;
      let data = {
        'operation_status': selected_status.value,
      };

      OrganizationService.operation_status_change(query, data)
        .then(resp => {
          this.$store.commit('MutationLoading', { 'status': false, 'data': {} });
          if (resp.data.status_code === '12000') {
            // let operation_text = ' *** Durum değişimi: ' + selected_status.label + ' *** ';
            let cell_style_with_border = this.f_calculateStatusCellStyle(task_ind, '');
            let cell_style_without_border = this.f_calculateStatusCellStyle(task_ind, '', 'no');
            this.d_taskCellStyleList[operation_data['id']] = { 'with_border': cell_style_with_border, 'without_border': cell_style_without_border };
            this.d_taskCommentSeenList[operation_data['id']] = this.f_calculateCountOfSeenComments(operation_data);
            // this.f_sendMessageOverWconnectToOtherUsers(operation_data, operation_text);
            // this.f_sendAllUsersMailAboutThisOperation(operation_data, 'no', operation_text);
            this.d_applyStatusOperation.show = false;
            this.f_updateOperations()
            let msg = 'İşlem tamamlandı.';
            let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Operasyon durum değişim işlemi hakkında' };
            this.$store.commit('MutationModal', modal_data);
            this.$store.commit('MutationModalShow', true);
          } else {
            console.log('error :' + resp.data.message);
          }
        }, resp => {
          console.log(resp);
          let msg = JSON.stringify(resp);
          let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Operasyon durum değişim işlemi hakkında' };
          this.$store.commit('MutationModal', modal_data);
          this.$store.commit('MutationModalShow', true);
        });
    },
    f_saveOperationStatusOld: function (selected_status, task_ind) {
      let which_user_log = {
        'val': {
          'label': this.user.first_name + ' ' + this.user.last_name,
          'value': this.user.id
        }
      };
      let operation_data = this.d_taskList[task_ind];
      this.f_addStatusAsLogToTask(operation_data, selected_status, which_user_log);
      let layer_type = 'operation';
      /*
        let parent_wdmr_id = '';
        if (this.d_selectedWdmr307 && this.d_selectedWdmr307.id) {
          parent_wdmr_id = this.d_selectedWdmr307.id;
        } else {
          parent_wdmr_id = operation_data.id_tree[0]['id'][operation_data.id_tree[0]['id'].length - 2].split('_')[1];
        }
      */
      let parent_wdmr_id = operation_data.id_tree[0]['id'][operation_data.id_tree[0]['id'].length - 2].split('_')[1];
      this.$store.commit('MutationLoading', { 'status': true, 'data': { 'label': 'Operasyon kayıt işlemi tamamlanıyor. Lütfen bekleyiniz.' } });
      let query = 'organization_id=' + parent_wdmr_id;
      query += '&operation_id=' + operation_data.id;
      let data = {
        'operation_data': operation_data,
        'send_mail': 'yes'
      };

      OrganizationService.operation_save(query, data)
        .then(resp => {
          this.$store.commit('MutationLoading', { 'status': false, 'data': {} });
          if (resp.data.status_code === '12000') {
            let operation_text = ' *** Durum değişimi: ' + selected_status.label + ' *** ';
            let cell_style_with_border = this.f_calculateStatusCellStyle(task_ind, '');
            let cell_style_without_border = this.f_calculateStatusCellStyle(task_ind, '', 'no');
            this.d_taskCellStyleList[operation_data['id']] = { 'with_border': cell_style_with_border, 'without_border': cell_style_without_border };
            this.d_taskCommentSeenList[operation_data['id']] = this.f_calculateCountOfSeenComments(operation_data);
            // this.f_sendMessageOverWconnectToOtherUsers(operation_data, operation_text);
            // this.f_sendAllUsersMailAboutThisOperation(operation_data, 'no', operation_text);
            this.d_applyStatusOperation.show = false;
            this.f_updateOperations()
            let msg = 'İşlem tamamlandı.';
            let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Operasyon durum değişim işlemi hakkında' };
            this.$store.commit('MutationModal', modal_data);
            this.$store.commit('MutationModalShow', true);
          } else {
            console.log('error :' + resp.data.message);
          }
        }, resp => {
          console.log(resp);
          let msg = JSON.stringify(resp);
          let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Operasyon durum değişim işlemi hakkında' };
          this.$store.commit('MutationModal', modal_data);
          this.$store.commit('MutationModalShow', true);
        });
    },
    f_sendMessageOverWconnectToOtherUsers: function (wdmr17, operation_text) {
      let uniq_wdm22_id_list = [];
      for (let i in wdmr17.data.general.log.list) {
        let log_user_id = wdmr17.data.general.log.list[i].log_user.val.value;
        if (this.user.id !== log_user_id && uniq_wdm22_id_list.indexOf(log_user_id) === -1) {
          uniq_wdm22_id_list.push(log_user_id);
        }
        let which_user_log_id = wdmr17.data.general.log.list[i].which_user_log.val.value;
        if (this.user.id !== which_user_log_id && uniq_wdm22_id_list.indexOf(which_user_log_id) === -1) {
          uniq_wdm22_id_list.push(which_user_log_id);
        }
      }
      /*
        let parent_wdmr_id = '';
        if (this.d_selectedWdmr307 && this.d_selectedWdmr307.id) {
          parent_wdmr_id = this.d_selectedWdmr307.id;
        } else {
          parent_wdmr_id = wdmr17.id_tree[0]['id'][wdmr17.id_tree[0]['id'].length - 2].split('_')[1];
        }
      */
      let parent_wdmr_id = wdmr17.id_tree[0]['id'][wdmr17.id_tree[0]['id'].length - 2].split('_')[1];
      let organization_name = '';
      if (this.d_selectedWdmr307 && this.d_selectedWdmr307.id) {
        organization_name = this.d_selectedWdmr307.label;
      } else {
        for (let i in this.d_organizationListData.list) {
          if (this.d_organizationListData.list[i].w_id === parent_wdmr_id) {
            organization_name = this.d_organizationListData.list[i].label;
            break;
          }
        }
      }
      if (uniq_wdm22_id_list.length > 0) {
        let subject = this.user.username + ', Op No: ' + wdmr17.id;
        if (organization_name) {
          subject += ' / ' + organization_name;
        }

        let url = WORGANIZATION_FRONTEND_URL + '/#/operations?organization_id=' + this.d_selectedWdmr307.id + '&operation_no=' + wdmr17.id;
        let mail_text = '';
        mail_text += '<div>' + operation_text + '</div>';
        mail_text += this.f_prepareOperationMailDetailed(wdmr17);
        this.f_sendMail(subject, uniq_wdm22_id_list, mail_text);
        for (let i in uniq_wdm22_id_list) {
          let sender_id = this.user.id;
          let to_id = uniq_wdm22_id_list[i];
          let msg = 'Worganization: <<< ' + this.d_selectedWdmr307.label + ' >>> <<< ' + wdmr17.label + ' >>> ' + operation_text +
            WORGANIZATION_FRONTEND_URL + '/#/operations?organization_id=' + this.d_selectedWdmr307.id + '&operation_no=' + wdmr17.id;
          // this.f_sendMessageToWconnect(sender_id, to_id, msg);
        }
      } else {
        let subject = this.user.username + ', Op No: ' + wdmr17.id;
        if (organization_name) {
          subject += ' / ' + organization_name;
        }
        let url = WORGANIZATION_FRONTEND_URL + '/#/operations?organization_id=' + this.d_selectedWdmr307.id + '&operation_no=' + wdmr17.id;
        let mail_text = '';
        mail_text += '<div>' + operation_text + '</div>';
        mail_text += this.f_prepareOperationMailDetailed(wdmr17);
        this.f_sendMail(subject, [this.user.id], mail_text);
      }
    },
    f_sendAllUsersMailAboutThisOperation: function (wdmr17, include_this_user = 'no', top_text = '') {
      let organization_id = '';
      if (this.d_selectedWdmr307 && this.d_selectedWdmr307.id) {
        organization_id = this.d_selectedWdmr307.id;
      } else {
        organization_id = wdmr17.id_tree[0]['id'][wdmr17.id_tree[0]['id'].length - 2].split('_')[1];
      }
      let organization_name = '';
      if (this.d_selectedWdmr307 && this.d_selectedWdmr307.id) {
        organization_name = this.d_selectedWdmr307.label;
      } else {
        for (let i in this.d_organizationListData.list) {
          if (this.d_organizationListData.list[i].w_id === organization_id) {
            organization_name = this.d_organizationListData.list[i].label;
            break;
          }
        }
      }
      let uniq_wdm22_id_list = [];
      // Below code gets the users of wdmr17 data.
      // let uniq_wdm22_id_list = this.f_getAllLogUserIdListAsUniq(wdmr17);

      for (let organization_index in this.d_organizationListData.list) {
        if (this.d_organizationListData.list[organization_index].w_id === organization_id) {
          if (this.d_organizationListData.list[organization_index].users) {
            for (let user_index in this.d_organizationListData.list[organization_index].users) {
              let user_item = this.d_organizationListData.list[organization_index].users[user_index];
              if (include_this_user === 'no') {
                if (this.user.id !== user_item['w_id']) {
                  uniq_wdm22_id_list.push(user_item['w_id']);
                }
              } else {
                uniq_wdm22_id_list.push(user_item['w_id']);
              }
            }
          }
          break;
        }
      }
      if (uniq_wdm22_id_list.length > 0) {
        let subject = this.user.username + ', Op, No: ' + wdmr17.id;
        if (organization_name) {
          subject += ' / ' + organization_name;
        }
        let top_html = '';
        if (top_text) {
          top_html += '<div><strong style="color: blue;">' + top_text + '</strong></div>';
          top_html += '<br>';
          top_html += '<br>';
        }
        let mail_text = this.f_prepareOperationMailDetailed(wdmr17);
        if (top_html) {
          mail_text = top_html + mail_text;
        }
        let document_list = [];
        if (wdmr17.document_list && wdmr17.document_list.length > 0) {
          document_list = wdmr17.document_list;
        }
        this.f_sendMail(subject, uniq_wdm22_id_list, mail_text, document_list);
      }
    },
    f_getAllLogUserIdListAsUniq: function (wdmr17) {
      let uniq_wdm22_id_list = [];
      for (let i in wdmr17.data.general.log.list) {
        let log_user_id = wdmr17.data.general.log.list[i].log_user.val.value;
        if (uniq_wdm22_id_list.indexOf(log_user_id) === -1) {
          uniq_wdm22_id_list.push(log_user_id);
        }
        let which_user_log_id = wdmr17.data.general.log.list[i].which_user_log.val.value;
        if (uniq_wdm22_id_list.indexOf(which_user_log_id) === -1) {
          uniq_wdm22_id_list.push(which_user_log_id);
        }
      }
      return uniq_wdm22_id_list;
    },
    f_prepareOperationMailDetailed: function (wdmr17) {
      /*
        let parent_wdmr_id = '';
        if (this.d_selectedWdmr307 && this.d_selectedWdmr307.id) {
          parent_wdmr_id = this.d_selectedWdmr307.id;
        } else {
          parent_wdmr_id = wdmr17.id_tree[0]['id'][wdmr17.id_tree[0]['id'].length - 2].split('_')[1];
        }
      */
      let parent_wdmr_id = wdmr17.id_tree[0]['id'][wdmr17.id_tree[0]['id'].length - 2].split('_')[1];
      let organization_name = '';
      if (this.d_selectedWdmr307 && this.d_selectedWdmr307.id) {
        organization_name = this.d_selectedWdmr307.label;
      } else {
        for (let i in this.d_organizationListData.list) {
          if (this.d_organizationListData.list[i].w_id === parent_wdmr_id) {
            organization_name = this.d_organizationListData.list[i].label;
            break;
          }
        }
      }
      let mail_text = '<div><strong>Operasyon Genel Bilgileri</strong></div>';
      mail_text += '<div><strong>Organizasyon:</strong><span>' + organization_name + '</span></div>';
      mail_text += '<div><strong>Operasyon Adı:</strong><span>' + wdmr17.data.general.operation_name.val + '</span></div>';
      mail_text += '<div><strong>Operasyon Notu:</strong><span>' + wdmr17.data.general.note.val + '</span></div>';
      mail_text += '<div><strong>Öncelik:</strong><span>' + wdmr17.data.general.priority.val.label + '</span></div>';
      mail_text += '<div><strong>Operasyon Tipi:</strong><span>' + wdmr17.data.general.task_type.val.label + '</span></div>';
      mail_text += '<div><strong>Durum:</strong><span>' + wdmr17.data.general.status.val.label + '</span></div>';
      try {
        mail_text += '<div><strong>İstenen Başlama Tarihi:</strong><span>' + this.DateFormatWithTime(wdmr17.data.general.desired_begin_date.val) + '</span></div>';
      } catch (err) {}
      try {
        mail_text += '<div><strong>İstenen Bitiş Tarihi:</strong><span>' + this.DateFormatWithTime(wdmr17.data.general.desired_end_date.val) + '</span></div>';
      } catch (err) {}
      if (wdmr17.data.general.comment && wdmr17.data.general.comment.list && wdmr17.data.general.comment.list.length > 0) {
        mail_text += '<div> <strong>--- Yorumlar ---</strong> </div>';
        for (let i in wdmr17.data.general.comment.list) {
          mail_text += '<div>' + wdmr17.data.general.comment.list[i].who_commented.val.label + ' ( ' + this.DateFormatWithTime(wdmr17.data.general.comment.list[i].comment_datetime.val) + ' ) ' + ': </div>';
          mail_text += '<div>' + wdmr17.data.general.comment.list[i].message.val + '</div>';
        }
      }
      if (wdmr17.data.general.log && wdmr17.data.general.log.list && wdmr17.data.general.log.list.length > 0) {
        mail_text += '<div> <strong>--- Loglar ---</strong> </div>';
        for (let i in wdmr17.data.general.log.list) {
          mail_text += '<div>' + wdmr17.data.general.log.list[i].log_user.val.label + ' ( ' + this.DateFormatWithTime(wdmr17.data.general.log.list[i].log_datetime.val) + ' - ' + wdmr17.data.general.log.list[i].log_status.val.label + ' ) -> ' + wdmr17.data.general.log.list[i].which_user_log.val.label + ' </div>';
        }
      }
      if (wdmr17.data.general.work_time && wdmr17.data.general.work_time.list && wdmr17.data.general.work_time.list.length > 0) {
        mail_text += '<div> <strong>--- Çalışma Notları ---</strong> </div>';
        for (let i in wdmr17.data.general.work_time.list) {
          mail_text += '<div><strong>Not - ' + (parseInt(i) + 1).toString() + '</strong></div>';
          mail_text += '<div>başlama:' + this.DateFormatWithTime(wdmr17.data.general.work_time.list[i].start.val) + '</div>';
          mail_text += '<div>bitiş:' + this.DateFormatWithTime(wdmr17.data.general.work_time.list[i].end.val) + '</div>';
          mail_text += '<div>' + wdmr17.data.general.work_time.list[i].work_time_note.val + '</div>';
        }
      }
      let url = WORGANIZATION_FRONTEND_URL + '/#/operations?organization_id=' + parent_wdmr_id + '&operation_no=' + wdmr17.id;
      mail_text += '<div><a href="' + url + '">Operasyonuna gitmek için tıklayınız.</a></div>';
      return mail_text;
    },
    f_getAssignedListOfThisOperation: function (wdmr17) {
      let wdm22_keys_list = [];
      for (let i in wdmr17.data.general.log.list) {
        if (wdmr17.data.general.log.list[i].log_status.val.value === 'assigned') {
          let wdm22_key = 'wdm22_' + wdmr17.data.general.log.list[i].which_user_log.val.value;
          wdm22_keys_list.push(wdm22_key);
        }
      }
      return wdm22_keys_list;
    },
    f_saveNewOperation: function () {
      if (this.d_selectedWdm22IdList.length === 0) {
        let msg = 'Lütfen öncelikle kullanıcı seçimi yapınız';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Operasyon kaydı hakkında' };
        this.$store.commit('MutationModal', modal_data);
        this.$store.commit('MutationModalShow', true);
      } else {
        let query = 'organization_id=' + this.d_wisdomData.w_id_list[0];
        query += '&operation_id=' + this.d_wisdomData.w_id_list[1];
        OrganizationService.operation_get(query)
          .then(resp => {
            if (resp.data.status_code === '12000') {
              this.d_wisdomData.show = false;
              // console.log(resp);
              let operation_data = resp.data.operation_data;
              if (this.d_wisdomData.operation_user_type === 'same_operation_to_everyone' || this.d_selectedWdm22IdList.length === 1) {
                this.d_allTaskList.push(operation_data);
                this.f_updateOperations();
                this.f_createWorkTimeTaskList();
                this.f_addUserListToOperation(operation_data, this.d_selectedWdm22IdList);
                let operation_note = '';
                try {
                  operation_note = operation_data.data.general.note.val;
                } catch (err) {}
                if (operation_note) {
                  // this.f_controlEtAndSendMail(operation_data, operation_note);
                }
                let operation_id = operation_data.id;
                let operation_label = operation_data.label;
                this.f_saveOperationFast(this.d_allTaskList.length - 1, operation_data, 'yes');
                this.f_addOperationToUserAndOrganizations(operation_data, this.d_selectedWdm22IdList);
              } else if (this.d_wisdomData.operation_user_type === 'copy_to_everyone') {
                let new_operation_data = JSON.parse(JSON.stringify(operation_data));
                if (new_operation_data.id) {
                  this.$delete(new_operation_data, 'id');
                }
                if (new_operation_data.cas) {
                  this.$delete(new_operation_data, 'cas');
                }
                for (let i in this.d_selectedWdm22IdList) {
                  if (parseInt(i) === 0) {
                    this.d_allTaskList.push(operation_data);
                    this.f_updateOperations();
                    this.f_createWorkTimeTaskList();
                    this.f_addUserListToOperation(operation_data, [this.d_selectedWdm22IdList[i]]);
                    let operation_note = '';
                    try {
                      operation_note = operation_data.data.general.note.val;
                    } catch (err) {}
                    if (operation_note) {
                      // this.f_controlEtAndSendMail(operation_data, operation_note);
                    }

                    let operation_id = operation_data.id;
                    let operation_label = operation_data.label;
                    this.f_saveOperationFast(this.d_allTaskList.length - 1, operation_data, 'yes');
                    this.f_addOperationToUserAndOrganizations(operation_data, [this.d_selectedWdm22IdList[i]]);
                  } else {
                    let new_user_list = [];
                    let prepare_operation_data = JSON.parse(JSON.stringify(new_operation_data));
                    new_user_list.push(this.d_selectedWdm22IdList[i]);
                    // console.log(new_user_list);
                    this.f_addUserListToOperation(prepare_operation_data, new_user_list);
                    let operation_note = '';
                    try {
                      operation_note = operation_data.data.general.note.val;
                    } catch (err) {}
                    if (operation_note) {
                      // this.f_controlEtAndSendMail(operation_data, operation_note);
                    }
                    let query = 'organization_id=' + this.d_selectedWdmr307.id;
                    let data = {
                      'operation_data': prepare_operation_data,
                      'send_mail': 'yes'
                    };
                    OrganizationService.operation_save(query, data)
                      .then(resp => {
                        if (resp.data.status_code === '12000') {
                          prepare_operation_data.id = resp.data.operation_id;
                          this.d_allTaskList.push(prepare_operation_data);
                          this.f_updateOperations();
                          this.f_addOperationToUserAndOrganizations(prepare_operation_data, new_user_list);
                          this.f_createWorkTimeTaskList();
                        } else {
                          console.log('error :' + resp.data.message);
                        }
                      });
                  }
                }
              }
              let msg = 'Operasyon kayıt işlemi tamamlandı.';
              let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Operasyon kaydı hakkında' };
              this.$store.commit('MutationModal', modal_data);
              this.$store.commit('MutationModalShow', true);
            }
          }, resp => {
            this.f_addOperationToUserAndOrganizations(operation_data, this.d_selectedWdm22IdList);
          });
      }
    },
    f_controlEtAndSendMail: function (operation_data, control_text) {
      /*
        let parent_wdmr_id = '';
        if (this.d_selectedWdmr307 && this.d_selectedWdmr307.id) {
          parent_wdmr_id = this.d_selectedWdmr307.id;
        } else {
          parent_wdmr_id = operation_data.id_tree[0]['id'][operation_data.id_tree[0]['id'].length - 2].split('_')[1];
        }
      */
      let parent_wdmr_id = operation_data.id_tree[0]['id'][operation_data.id_tree[0]['id'].length - 2].split('_')[1];
      let organization_name = '';
      if (this.d_selectedWdmr307 && this.d_selectedWdmr307.id) {
        organization_name = this.d_selectedWdmr307.label;
      } else {
        for (let i in this.d_organizationListData.list) {
          if (this.d_organizationListData.list[i].w_id === parent_wdmr_id) {
            organization_name = this.d_organizationListData.list[i].label;
            break;
          }
        }
      }

      if (control_text.indexOf('@') !== -1 && this.d_organizationWdm22List.length > 0) {
        let user_regx = /@[a-z0-9A-Z_\.]+\s/g;
        let match_list = control_text.match(user_regx);
        // console.log('match_list : ', match_list);
        if (match_list.length > 0) {
          let subject = this.user.username + ', Op, No: ' + operation_data.id;
          if (organization_name) {
            subject += ' / ' + organization_name;
          }
          let mail_text = this.f_prepareOperationMailDetailed(operation_data);
          let document_list = [];
          if (operation_data.document_list && operation_data.document_list.length > 0) {
            document_list = operation_data.document_list;
          }
          let uniq_wdm22_id_list = [];
          for (let i in match_list) {
            if (match_list[i]) {
              // console.log('match_list[i] : ', match_list[i]);
              let target_user_name = match_list[i].replace('@', '');
              target_user_name = target_user_name.replace(/[\s]+/g, "");
              let user_id = '';
              for (let k in this.d_organizationWdm22List) {
                if (this.d_organizationWdm22List[k].username === target_user_name) {
                  uniq_wdm22_id_list.push(this.d_organizationWdm22List[k].w_id);
                  break;
                }
              }
            }
          }
          if (uniq_wdm22_id_list.length > 0) {
            this.f_sendMail(subject, uniq_wdm22_id_list, mail_text, document_list);
          }
        }
      }
    },
    f_addUserListToOperation: function (operation_data, user_list) {
      let selected_status = { 'value': 'assigned', 'label': 'Atandı' };
      for (let i in user_list) {
        let which_user_log = '';
        for (let k in this.d_organizationWdm22List) {
          if (user_list[i] === this.d_organizationWdm22List[k].w_id) {
            // console.log("this.d_organizationWdm22List[k].key.split('wdm22_')", this.d_organizationWdm22List[k].key.split('wdm22_'));
            which_user_log = {
              'val': {
                'label': this.d_organizationWdm22List[k].label,
                'value': this.d_organizationWdm22List[k].w_id
              }
            };
            break;
          }
        }
        // console.log('which_user_log', which_user_log);
        this.f_addStatusAsLogToTask(operation_data, selected_status, which_user_log);
        // console.log('this.d_wisdomData.wisdom_data', this.d_wisdomData.wisdom_data);
      }
    },
    f_addOperationToUserAndOrganizations: function (operation_data, user_list) {
      let data = { 'list': [] };
      let parent_wdm307 = {};
      parent_wdm307.parent_type = 'wdm307';
      parent_wdm307.parent_id_list = [];
      parent_wdm307.child_type = 'wdm17';
      parent_wdm307.child_info_list = [];

      parent_wdm307.parent_id_list.push(this.d_selectedWdmr307.id);
      parent_wdm307.child_info_list.push({ 'key': 'wdm17_' + operation_data.id, 'label': operation_data.label });

      let parent_wdm22 = {};
      parent_wdm22.parent_type = 'wdm22';
      parent_wdm22.parent_id_list = [];
      parent_wdm22.child_type = 'wdm17';
      parent_wdm22.child_info_list = [];
      parent_wdm22.child_info_list.push({ 'key': 'wdm17_' + operation_data.id, 'label': operation_data.label })

      for (let i in user_list) {
        parent_wdm22.parent_id_list.push(user_list[i]);
      }

      data['list'].push(parent_wdm307);
      data['list'].push(parent_wdm22);
      // console.log(data);
      // let operation_text = 'Operasyon ataması yapılmıştır.';
      // this.f_sendMessageOverWconnectToOtherUsers(operation_data, operation_text);
      // this.f_sendAllUsersMailAboutThisOperation(operation_data, 'no', operation_text);
      this.d_wisdomData.show = false;
    },
    f_saveWdmr17OfOrganization: function (wdmr17) {
      this.d_wisdomData.wisdom_data = wdmr17;
      let parent_wdm = '';
      let child_wdm = '';
      let relation_type = 'keys';
      let both_parent = 'no';
      let wdmr307_key = this.d_organizationListData.list[this.d_organizationListData.selected_index].key;
      parent_wdm = {
        'label': '',
        'key_list': [wdmr307_key],
        'bucket': 'wisdom',
        'type': 'wdm22'
      };
      child_wdm = {
        'label': '',
        'key': 'wdm_wisdom_wdm17_v2.0',
        'bucket': 'wisdom',
        'type': 'wdm17'
      };
      ClsWdmrManage.save_child_wdmr(this.d_wisdomData, parent_wdm, child_wdm, relation_type, both_parent)
        .then(resp => {
          // this.d_selectedWdmrData = resp.wdmr;
          // this.wisdom_show_update();
          this.f_updateOperations();
          // We control whether this user is the assigned user. himself ?.
          let operation_text = 'operasyon ataması yapılmıştır.';
          // this.f_sendMessageOverWconnectToOtherUsers(wdmr17, operation_text);
          this.f_sendAllUsersMailAboutThisOperation(wdmr17, 'no', operation_text);
          let msg = 'Operasyon kayıt işlemi tamamlandı.';
          let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Operasyon kaydı hakkında' };
          this.$store.commit('MutationModal', modal_data);
          this.$store.commit('MutationModalShow', true);
        }, resp => {
          alert('error ', resp);
        });
    },
    f_sendMessageToWconnect: function (sender_id, to_id, msg) {
      let data = {
        'sender': sender_id,
        'to': to_id,
        'message': msg
      };
      // console.log(data);
      WconnectService.send_message(data)
        .then(resp => {
          if (resp.data.status === "success") {
            console.log('message sent');
          } else {
            console.log('errorr get_one_cb_document : ', resp.data.message);
          }
        });
    },
    f_sendMail: function (subject, to_whom_user_id_list, mail_text, document_list = [], from_user = { 'value': '1', 'label': 'wisdom' }, from_mail = 'worganization@wisdomera.io') {
      let mail_wdmr = {
        'data': {
          'general': {
            'subject': { 'val': subject },
            'from_mail': { 'val': from_mail },
            'from_user': { 'val': from_user },
            'to_whom': { 'list': [] },
            'mail_text': { 'val': mail_text },
          }
        }
      };
      if (document_list.length > 0) {
        mail_wdmr.data.general.attachment = { 'list': [] };
        for (let i in document_list) {
          let x = {
            'attachment_name': { 'val': document_list[i].file_name },
            'file': { 'val': document_list[i].document }
          };
          mail_wdmr.data.general.attachment.list.push(x);
        }
      }
      for (let to_whom_index in to_whom_user_id_list) {
        let user_mail = '';
        for (let wdm22_index in this.d_organizationWdm22List) {
          if (this.d_organizationWdm22List[wdm22_index].w_id === to_whom_user_id_list[to_whom_index]) {
            user_mail = this.d_organizationWdm22List[wdm22_index].mail;
            break
          }
        }
        // console.log('user_mail: ', user_mail);
        if (user_mail) {
          let to_whom = { 'to_whom_mail': { 'val': user_mail } };
          mail_wdmr.data.general.to_whom.list.push(to_whom);
        }
      }
      if (mail_wdmr.data.general.to_whom.list.length > 0) {
        WmailApi.mail_send(mail_wdmr)
          .then(resp => {
            console.log(resp);
          });
      }
    },
    f_saveEditOrganization: function () {
      let query = 'organization_id=' + this.d_selectedWdmr307.id;
      // this.$store.commit('MutationLoading', { 'status': true, 'data': { 'label': 'Organizasyon verisi getirme işlemi gerçekleştiriliyor. Lütfen bekleyiniz.' } });
      OrganizationService.organization_get(query)
        .then(resp => {
          // this.$store.commit('MutationLoading', { 'status': false, 'data': {} });
          if (resp.data.status_code === '12000') {
            this.d_selectedWdmr307 = resp.data.organization_data;
            this.d_organizationListData.list[this.d_organizationListData.selected_index].label = this.d_selectedWdmr307.label;
            this.f_prepareTagList();
            this.d_wisdomData.show = false;
            this.$forceUpdate();
          } else {
            console.log('error :' + resp.data.message);
          }
        }, resp => {
          console.log(resp);
          let msg = JSON.stringify(resp);
          let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Organizasyon getirme işlemi hakkında' };
          this.$store.commit('MutationModal', modal_data);
          this.$store.commit('MutationModalShow', true);
        });
    },
    f_editOrganization: function () {
      if (this.d_organizationListData.selected_index !== '') {
        let layer_type = 'organization';
        let w_id_list = [this.d_selectedWdmr307.id];
        let wmanager_local_storage = JSON.parse(localStorage.getItem('wmanager'));
        this.d_wisdomData.url_iframe = this.WDMR_LINKS['record'] + 'layer_id=' + this.d_WmanagerLayerData[layer_type].layer_id + '&w_id_list=' + w_id_list.join(',') + '&wisdom=' + wmanager_local_storage.access_token + '&modsiw=' + wmanager_local_storage.refresh_token;
        this.d_wisdomData.wisdom_op = 'edit';
        this.d_wisdomData.data_type = this.d_WmanagerLayerData[layer_type].wdm_type;
        this.d_wisdomData.show = true;
      } else {
        let msg = 'Lütfen öncelikle organizasyon seçimi yapınız';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Organizasyon düzenleme işlemi hakkında' };
        this.$store.commit('MutationModal', modal_data);
        this.$store.commit('MutationModalShow', true);
      }
    },
    f_editOperation: function (operation_ind) {
      let operation_id = this.d_taskList[operation_ind].id;
      this.d_editingOperationIndex = operation_ind;
      this.d_wisdomData.wisdom_op = 'edit';
      /*
        let parent_wdmr_id = '';
        if (this.d_selectedWdmr307 && this.d_selectedWdmr307.id) {
          parent_wdmr_id = this.d_selectedWdmr307.id;
        } else {
          parent_wdmr_id = this.d_taskList[operation_ind].id_tree[0]['id'][this.d_taskList[operation_ind].id_tree[0]['id'].length - 2].split('_')[1];
        }
      */
      let parent_wdmr_id = this.d_taskList[operation_ind].id_tree[0]['id'][this.d_taskList[operation_ind].id_tree[0]['id'].length - 2].split('_')[1];
      /*
        let layer_type = 'operation';
        let w_id_list = [parent_wdmr_id, this.d_taskList[operation_ind].id];
        let wmanager_local_storage = JSON.parse(localStorage.getItem('wmanager'));
        this.d_wisdomData.url_iframe = this.WDMR_LINKS['record'] + 'layer_id=' + this.d_WmanagerLayerData[layer_type].layer_id + '&w_id_list=' + w_id_list.join(',') + '&wisdom=' + wmanager_local_storage.access_token + '&modsiw=' + wmanager_local_storage.refresh_token;
        this.d_wisdomData.data_type = this.d_WmanagerLayerData[layer_type].wdm_type;
        this.d_wisdomData.show = true;
      */
      let query = 'wdmr_id=' + operation_id;
      OrganizationService.operation_wdmr_token_get(query)
        .then(resp => {
          if (resp.data.status_code === '12000') {
            this.d_showWdmrPreview.url_show_iframe = resp.data.url_show_iframe;
            this.d_wisdomData.url_iframe = resp.data.url_record_iframe;
            this.d_wisdomData.show = true;
            this.$forceUpdate();
          } else {
            alert('error ' + resp.data.message);
          }
        });
    },
    f_saveEditOperation: function () {
      let query = 'organization_id=' + this.d_selectedWdmr307.id;
      query += '&operation_id=' + this.d_taskList[this.d_editingOperationIndex].id;
      OrganizationService.operation_get(query)
        .then(resp => {
          if (resp.data.status_code === '12000') {
            for (let i in this.d_allTaskList) {
              if (this.d_allTaskList[i].id === resp.data.operation_data.id) {
                this.d_allTaskList[i] = resp.data.operation_data;
                break;
              }
            }
            this.$forceUpdate();
            this.d_wisdomData.show = false;
            this.f_updateOperations();
          }
        }, resp => {});
    },
    f_deleteThisOrganization: function () {
      //
    },
    f_openOrganizationSettings: function () {
      //
    },
    f_calculatePriorityStyle: function (task) {
      let task_priority = '';
      try {
        task_priority = task.data.general.priority.val.value;
      } catch (err) {
        task_priority = '';
      }
      if (task_priority === 'urgent') {
        return 'float: left; background: red; height: 90%; width: 5px; margin: 1px; border-radius: 7px; box-shadow: -1px -1px 3px;';
      } else if (task_priority === 'high') {
        return 'float: left; background: #f154ec; height: 90%; width: 5px; margin: 1px; border-radius: 7px; box-shadow: -1px -1px 3px;';
      } else if (task_priority === 'medium') {
        return 'float: left; background: #00c4ff; height: 90%; width: 5px; margin: 1px; border-radius: 7px; box-shadow: -1px -1px 3px;';
      } else if (task_priority === 'low') {
        return 'float: left; background: #67e667; height: 90%; width: 5px; margin: 1px; border-radius: 7px; box-shadow: -1px -1px 3px;';
      }
    },
    f_calculateTaskStyle: function (task) {
      let task_priority = '';
      try {
        task_priority = task.data.general.priority.val.value;
      } catch (err) {
        task_priority = '';
      }
      if (task_priority === 'urgent') {
        return 'background: red; border-radius: 7px; margin: 1px; padding: 3px min-height: 20px;';
      } else if (task_priority === 'high') {
        return 'background: #f154ec; border-radius: 7px; margin: 1px; padding: 3px min-height: 20px;';
      } else if (task_priority === 'medium') {
        return 'background: #00c4ff; border-radius: 7px; margin: 1px; padding: 3px min-height: 20px;';
      } else if (task_priority === 'low') {
        return 'background: #67e667; border-radius: 7px; margin: 1px; padding: 3px min-height: 20px;';
      }
    },
    f_topMenuUpdate: function () {
      if (this.d_menuData.forceUpdate) {
        this.d_menuData.forceUpdate = 0;
      } else {
        this.d_menuData.forceUpdate = 1;
      }
    },
    f_prepareOrganizationFilterData: function () {
      // priority
      try {
        this.d_menuData.list[3].list[0].list[0].option_list = this.option_data['wdm17'].general.parameters.priority.options;
      } catch (err) {
        // console.log(err);
        this.d_menuData.list[3].list[0].list[0].option_list = [];
      }
      // status
      try {
        this.d_menuData.list[3].list[1].list[0].option_list = this.option_data['wdm17'].general.parameters.status.options;
        this.d_wdm17StateOptions = this.option_data['wdm17'].general.parameters.status.options;
        this.f_sortObjectList(this.d_menuData.list[3].list[1].list[0].option_list, 'label', 1);
      } catch (err) {
        // console.log(err);
        this.d_menuData.list[3].list[1].list[0].option_list = [];
      }
      // operation type
      try {
        this.d_menuData.list[3].list[2].list[0].option_list = this.option_data['wdm17'].general.parameters.task_type.options;
        this.f_sortObjectList(this.d_menuData.list[3].list[2].list[0].option_list, 'label', 1);
      } catch (err) {
        // console.log(err);
        this.d_menuData.list[3].list[2].list[0].option_list = [];
      }
      this.f_topMenuUpdate();
    },
    f_sortObjectList: function (object_list, param, asc_or_desc = 1) {
      // sort object list which includes like
      /*
        object_list =
        [
          {'value': 'sdfas', 'label': '5mglld'},
          {'value': 'k5kfm', 'label': 'jfjt8'}
        ]

        param is the key variable in object list. Like example object list "value" or "label"

        asc_or_desc = 1 or -1:
          Sort as ascending (1) or descending (-1)
      */
      function compare(a, b) {
        // Use toUpperCase() to ignore character casing
        let param_a = '';
        let param_b = '';
        try {
          param_a = a[param].toLocaleLowerCase();
          param_b = b[param].toLocaleLowerCase();
        } catch (err) {
          param_a = a[param];
          param_b = b[param];
        }

        let comparison = 0;
        if (param_a > param_b) {
          comparison = 1;
        } else if (param_a < param_b) {
          comparison = -1;
        }
        return comparison * asc_or_desc;
      }
      object_list.sort(compare);
    },
    f_dataSetStyle: function (x) {
      let height = window.innerHeight - x;
      let overflow_x = 'auto';
      return 'overflow-y: auto; height:' + height.toString() + 'px; overflow-x:' + overflow_x + '; margin-bottom: 0px; border: none;';
    },
    f_calculateTabStyle: function () {
      let style = '';
      let width = '';
      if (this.StoreDevice.isMobile) {
        width = (window.innerWidth).toString() + 'px;';
      } else {
        // width = (window.innerWidth - 350).toString() + 'px;';
        width = (window.innerWidth - 30).toString() + 'px;';
      }
      style = 'width: ' + width;
      return style;
    },
    f_calculateBottomTabStyle: function () {
      // console.log('this.d_fullTopMenu        : ', this.d_fullTopMenu);
      // console.log('this.StoreDevice.isMobile : ', this.StoreDevice.isMobile);
      let style = '';
      let navbar_height = 30;
      // let menu_height = 210;
      let menu_height = 35;
      if (this.d_fullTopMenu) {
        let width = '';
        let height = '';
        let overflow_x = '';
        if (this.d_fullScreenOperationPage) {
          height = window.innerHeight - (navbar_height + 10);
          overflow_x = 'auto';
        } else {
          // width = (window.innerWidth - 425).toString() + 'px;';
          width = (window.innerWidth - 50).toString() + 'px;';
          // console.log('width : ', width);
          if (this.d_selectedTab === 'up_and_down') {
            overflow_x = 'hidden';
            height = window.innerHeight - (parseInt(this.d_menuData.style.height) + menu_height);
          } else if (this.d_selectedTab === 'date_and_card') {
            height = window.innerHeight - (parseInt(this.d_menuData.style.height) + menu_height);
          } else if (this.d_selectedTab === 'date_line') {
            height = window.innerHeight - (parseInt(this.d_menuData.style.height) + menu_height);
          }
          // overflow_x = 'hidden';
          // console.log('height: ', height);
        }
        if (this.StoreDevice.isMobile) {
          style = 'background-color: white; border: none; width: ' + width;
        } else {
          style = 'background-color: white; overflow-y: auto; height:' + height.toString() + 'px; overflow-x:' + overflow_x + '; border: none; width: ' + width;
        }
      } else {
        let height = '';
        let width = '';
        let overflow_x = 'auto';
        if (this.d_fullScreenOperationPage) {
          height = window.innerHeight - (navbar_height + 10);
          overflow_x = 'auto';
        } else {
          width = (window.innerWidth - 425).toString() + 'px;';
          if (this.d_selectedTab === 'up_and_down') {
            height = window.innerHeight - menu_height
            overflow_x = 'hidden';
          } else if (this.d_selectedTab === 'date_and_card') {
            height = window.innerHeight - menu_height
          } else if (this.d_selectedTab === 'date_line') {
            height = window.innerHeight - menu_height
          }
          overflow_x = 'auto';
        }
        // console.log('height: ', height);
        if (this.StoreDevice.isMobile) {
          style = 'background-color: white; border: none; width: ' + width;
        } else {
          style = 'background-color: white; overflow-y: auto; height:' + height.toString() + 'px; overflow-x:' + overflow_x + '; border: none; width: ' + width;
        }
      }
      // console.log('style : ', style);
      return style;
    },
    f_calculateStatusStyle: function (status_val) {
      if (status_val === 'completed') {
        return 'background: #b6f7c5; border-radius: 3px; border: solid 1px #c6cfd4; text-align: center; padding: 2px; margin: 2px; font-size: 10px; min-width: 75px; white-space: nowrap; float: left; cursor: pointer;';
      } else if (status_val === 'cancelled') {
        return 'background: #f7959e; border-radius: 3px; border: solid 1px #c6cfd4; text-align: center; padding: 2px; margin: 2px; font-size: 10px; min-width: 75px; white-space: nowrap; float: left; cursor: pointer;';
      } else if (status_val === 'assigned') {
        return 'background: #f6f9b9; border-radius: 3px; border: solid 1px #c6cfd4; text-align: center; padding: 2px; margin: 2px; font-size: 10px; min-width: 75px; white-space: nowrap; float: left; cursor: pointer;';
      } else if (status_val === 'not_assigned') {
        return 'background: #a3afb6; border-radius: 3px; border: solid 1px #c6cfd4; text-align: center; padding: 2px; margin: 2px; font-size: 10px; min-width: 75px; white-space: nowrap; float: left; cursor: pointer;';
      } else if (status_val === 'recommended') {
        return 'background: #e08be7; border-radius: 3px; border: solid 1px #c6cfd4; text-align: center; padding: 2px; margin: 2px; font-size: 10px; min-width: 75px; white-space: nowrap; float: left; cursor: pointer;';
      } else if (status_val === 'refused') {
        return 'background: #c1a4fc; border-radius: 3px; border: solid 1px #c6cfd4; text-align: center; padding: 2px; margin: 2px; font-size: 10px; min-width: 75px; white-space: nowrap; float: left; cursor: pointer;';
      } else if (status_val === 'accepted') {
        return 'background: #01ffe4; border-radius: 3px; border: solid 1px #c6cfd4; text-align: center; padding: 2px; margin: 2px; font-size: 10px; min-width: 75px; white-space: nowrap; float: left; cursor: pointer;';
      } else {
        return 'background: orange; border-radius: 3px; border: solid 1px #c6cfd4; text-align: center; padding: 2px; margin: 2px; font-size: 10px; min-width: 75px; white-space: nowrap; float: left; cursor: pointer;';
      }
    },
    f_calculateStatusStyleText: function (status_val) {
      if (status_val === 'completed') {
        return 'background: #b6f7c5; padding: 2px; margin: 2px; min-width: 75px; white-space: nowrap; cursor: pointer;';
      } else if (status_val === 'cancelled') {
        return 'background: #f7959e; padding: 2px; margin: 2px; min-width: 75px; white-space: nowrap; cursor: pointer;';
      } else if (status_val === 'assigned') {
        return 'background: #f6f9b9; padding: 2px; margin: 2px; min-width: 75px; white-space: nowrap; cursor: pointer;';
      } else if (status_val === 'not_assigned') {
        return 'background: #a3afb6; padding: 2px; margin: 2px; min-width: 75px; white-space: nowrap; cursor: pointer;';
      } else if (status_val === 'recommended') {
        return 'background: #e08be7; padding: 2px; margin: 2px; min-width: 75px; white-space: nowrap; cursor: pointer;';
      } else if (status_val === 'refused') {
        return 'background: #c1a4fc; padding: 2px; margin: 2px; min-width: 75px; white-space: nowrap; cursor: pointer;';
      } else if (status_val === 'accepted') {
        return 'background: #01ffe4; padding: 2px; margin: 2px; min-width: 75px; white-space: nowrap; cursor: pointer;';
      } else {
        return 'background: orange; padding: 2px; margin: 2px; min-width: 75px; white-space: nowrap; cursor: pointer;';
      }
    },
    f_calculateStatusCellStyle: function (task_ind, status_val = '', show_border = 'no', width_value = '') {
      /*
        accepted
        answered
        approved_task_completion
        assigned
        cancelled
        cannot_be_completed
        checking_task_completion
        completed
        error
        none
        not_assigned
        pending
        postponed
        read
        recommended
        refused
        started
      */
      let status_style = '';
      if (!status_val) {
        try {
          let task = this.d_taskList[task_ind];
          status_val = task.data.general.status.val.value;
        } catch (err) {}
      }


      if (status_val === 'completed') {
        status_style = 'color: black; border-radius: 2px 2px; cursor: pointer; text-align: center; box-shadow: -1px -1px 2px #85ffbd; background-color: #85FFBD; background-image: linear-gradient(45deg, #85FFBD 0%, #FFFB7D 100%); min-width: 50px; margin: 5px;';
        if (show_border === 'yes') {
          status_style += 'border: solid 1px black;';
        }
        if (width_value) {
          status_style += ' width: ' + width_value + 'px;';
        }

      } else if (status_val === 'answered') {
        status_style = 'color: black; border-radius: 2px 2px; cursor: pointer; text-align: center; box-shadow: -1px -1px 2px #85ffbd; background-color: #85FFBD; background-image: linear-gradient(45deg, #85FFBD 0%, #FFFB7D 100%); min-width: 50px; margin: 5px;';
        if (show_border === 'yes') {
          status_style += 'border: solid 1px black;';
        }
        if (width_value) {
          status_style += ' width: ' + width_value + 'px;';
        }

      } else if (status_val === 'read') {
        status_style = 'color: black; border-radius: 2px 2px; cursor: pointer; text-align: center; box-shadow: -1px -1px 2px #85ffbd; background-color: #85FFBD; background-image: linear-gradient(45deg, #85FFBD 0%, #FFFB7D 100%); min-width: 50px; margin: 5px;';
        if (show_border === 'yes') {
          status_style += 'border: solid 1px black;';
        }
        if (width_value) {
          status_style += ' width: ' + width_value + 'px;';
        }

      } else if (status_val === 'approved_task_completion') {
        status_style = 'color: black; border-radius: 2px 2px; cursor: pointer; text-align: center; box-shadow: -1px -1px 2px #85ffbd; background-color: #85FFBD; background-image: linear-gradient(45deg, #85FFBD 0%, #FFFB7D 100%); min-width: 50px; margin: 5px;';
        if (show_border === 'yes') {
          status_style += 'border: solid 1px black;';
        }
        if (width_value) {
          status_style += ' width: ' + width_value + 'px;';
        }

      } else if (status_val === 'cancelled') {
        status_style = 'color: black; border-radius: 2px 2px; cursor: pointer; text-align: center; box-shadow: -1px 1px -2px #FF2525; background-color: #FFE53B; background-image: linear-gradient(147deg, #FFE53B 0%, #FF2525 74%); min-width: 50px; margin: 5px;';
        if (show_border === 'yes') {
          status_style += 'border: solid 1px black;';
        }
        if (width_value) {
          status_style += ' width: ' + width_value + 'px;';
        }

      } else if (status_val === 'assigned') {
        status_style = 'color: black; border-radius: 2px 2px; cursor: pointer; text-align: center; box-shadow: -1px -1px 2px #F76B1C; background-color: #FAD961; background-image: linear-gradient(90deg, #FAD961 0%, #F76B1C 100%); min-width: 50px; margin: 5px;';
        if (show_border === 'yes') {
          status_style += 'border: solid 1px black;';
        }
        if (width_value) {
          status_style += ' width: ' + width_value + 'px;';
        }

      } else if (status_val === 'not_assigned') {
        status_style = 'color: #e0e0e0; border-radius: 2px 2px; cursor: pointer; text-align: center; box-shadow: -1px -1px 2px #B721FF; background-color: #21D4FD; background-image: linear-gradient(19deg, #21D4FD 0%, #B721FF 100%); min-width: 50px; margin: 5px;';
        if (show_border === 'yes') {
          status_style += 'border: solid 1px black;';
        }
        if (width_value) {
          status_style += ' width: ' + width_value + 'px;';
        }

      } else if (status_val === 'recommended') {
        status_style = 'color: black; border-radius: 2px 2px; cursor: pointer; text-align: center; box-shadow: -1px -1px 2px #FF5ACD; background-color: #FBDA61; background-image: linear-gradient(45deg, #FBDA61 0%, #FF5ACD 100%); min-width: 50px; margin: 5px;';
        if (show_border === 'yes') {
          status_style += 'border: solid 1px black;';
        }
        if (width_value) {
          status_style += ' width: ' + width_value + 'px;';
        }

      } else if (status_val === 'refused') {
        status_style = 'color: black; border-radius: 2px 2px; cursor: pointer; text-align: center; box-shadow: -1px -1px 2px #DDD6F3; background-color: #FAACA8; background-image: linear-gradient(19deg, #FAACA8 0%, #DDD6F3 100%); min-width: 50px; margin: 5px;';
        if (show_border === 'yes') {
          status_style += 'border: solid 1px black;';
        }
        if (width_value) {
          status_style += ' width: ' + width_value + 'px;';
        }

      } else if (status_val === 'cannot_be_completed') {
        status_style = 'color: #e0e0e0; border-radius: 2px 2px; cursor: pointer; text-align: center; box-shadow: -1px -1px 2px #784BA0; background-color: #FF3CAC; background-image: linear-gradient(225deg, #FF3CAC 0%, #784BA0 50%, #2B86C5 100%); min-width: 50px; margin: 5px;';
        if (show_border === 'yes') {
          status_style += 'border: solid 1px black;';
        }
        if (width_value) {
          status_style += ' width: ' + width_value + 'px;';
        }

      } else if (status_val === 'checking_task_completion') {
        status_style = 'color: black; border-radius: 2px 2px; cursor: pointer; text-align: center; box-shadow: -1px -1px 2px #EE74E1; background-color: #3EECAC; background-image: linear-gradient(19deg, #3EECAC 0%, #EE74E1 100%); min-width: 50px; margin: 5px;';
        if (show_border === 'yes') {
          status_style += 'border: solid 1px black;';
        }
        if (width_value) {
          status_style += ' width: ' + width_value + 'px;';
        }

      } else if (status_val === 'accepted') {
        status_style = 'color: black; border-radius: 2px 2px; cursor: pointer; text-align: center; box-shadow: -1px -1px 2px #2BD2FF; background-color: #FA8BFF; background-image: linear-gradient(45deg, #FA8BFF 0%, #2BD2FF 52%, #2BFF88 90%); min-width: 50px; margin: 5px;';
        if (show_border === 'yes') {
          status_style += 'border: solid 1px black;';
        }
        if (width_value) {
          status_style += ' width: ' + width_value + 'px;';
        }

      } else if (status_val === 'started') {
        status_style = 'color: black; border-radius: 2px 2px; cursor: pointer; text-align: center; box-shadow: -1px -1px 2px #7dc0ff; background-color: #5096e2; background-image: linear-gradient(45deg, #5096e2 7%, #7dc0ff 60%, #ffffff 100%); min-width: 50px; margin: 5px;';
        if (show_border === 'yes') {
          status_style += 'border: solid 1px black;';
        }
        if (width_value) {
          status_style += ' width: ' + width_value + 'px;';
        }

      } else if (status_val === 'postponed') {
        status_style = 'color: black; border-radius: 2px 2px; cursor: pointer; text-align: center; box-shadow: -1px -1px 2px #FF6A88; background-color: #FF9A8B; background-image: linear-gradient(90deg, #FF9A8B 0%, #FF6A88 55%, #FF99AC 100%); min-width: 50px; margin: 5px;';
        if (show_border === 'yes') {
          status_style += 'border: solid 1px black;';
        }
        if (width_value) {
          status_style += ' width: ' + width_value + 'px;';
        }

      } else if (status_val === 'pending') {
        status_style = 'color: black; border-radius: 2px 2px; cursor: pointer; text-align: center; box-shadow: -1px -1px 2px #FA709A; background-color: #FEE140; background-image: linear-gradient(90deg, #FEE140 0%, #FA709A 100%); min-width: 50px; margin: 5px;';
        if (show_border === 'yes') {
          status_style += 'border: solid 1px black;';
        }
        if (width_value) {
          status_style += ' width: ' + width_value + 'px;';
        }

      } else if (status_val === 'error') {
        status_style = 'color: black; border-radius: 2px 2px; cursor: pointer; text-align: center; box-shadow: -1px -1px 2px #ff0000; background-color: #ff0000; background-image: linear-gradient(135deg, #ff0000 0%, #F7CE68 100%);; min-width: 50px; margin: 5px;';
        if (show_border === 'yes') {
          status_style += 'border: solid 1px black;';
        }
        if (width_value) {
          status_style += ' width: ' + width_value + 'px;';
        }

      } else if (status_val === 'operation_moved') {
        status_style = 'color: #e0e0e0; border-radius: 2px 2px; cursor: pointer; text-align: center; box-shadow: -1px -1px 2px #6284FF; background-color: #181818; background-image: linear-gradient(135deg, #fe40ac 0%, #181818 100%); min-width: 50px; margin: 5px;';
        if (show_border === 'yes') {
          status_style += 'border: solid 1px black;';
        }
        if (width_value) {
          status_style += ' width: ' + width_value + 'px;';
        }

      } else if (status_val === 'none') {
        status_style = 'color: #e0e0e0; border-radius: 2px 2px; cursor: pointer; text-align: center; box-shadow: -1px -1px 2px #621a00; background-color: #ff0000; background-image: linear-gradient(135deg, #ff0000 0%, #621a00 100%); min-width: 50px; margin: 5px;';
        if (show_border === 'yes') {
          status_style += 'border: solid 1px black;';
        }
        if (width_value) {
          status_style += ' width: ' + width_value + 'px;';
        }

      }
      return status_style;
    },
    f_getIndexListAsString: function (index_list) {
      let str = '';
      for (let i in index_list) {
        let x = index_list[i].split(',');
        for (let k in x) {
          str += (parseInt(x[k]) + 1).toString();
          str += '.';
        }
        if (parseInt(i) !== index_list.length - 1) {
          str += ' - ';
        }
      }
      return str;
    },
    f_getAssignDate: function (task, user) {
      // console.log('user', user);
      try {
        for (let i = 0; i < task.data.general.log.list.length; i++) {
          if (task.data.general.log.list[i].which_user_log.val.value === user.value) {
            let k = this.DateFormatWithTime(task.data.general.log.list[i].log_datetime.val);
            if (k) {
              return k;
            } else {
              return '';
            }
          }
        }
      } catch (err) {}
      // console.log(task.created_at);
      let k = this.DateFormatWithTime(task.created_at);
      if (k) {
        return k;
      } else {
        return '';
      }
    },
    f_getAssignedBy: function (task, list_type = 'by_commas') {
      // console.log('user', user);
      let user_text = '';
      // let assigned_user_title = '';
      let to_which_user_assigned_list = [];
      /*
        try {
          assigned_user_title = task.data.general.log.assigned_user_title.val.label;
        } catch(err) {}
      */

      try {
        for (let i = 0; i < task.data.general.log.list.length; i++) {
          if (task.data.general.log.list[i].log_status.val.value === 'assigned') {
            if (user_text) {
              user_text += ' / ';
            }
            let log_datetime = '';
            if (task.data.general.log.list[i].log_datetime && task.data.general.log.list[i].log_datetime.val) {
              let x = this.DateFormatWithTime(task.data.general.log.list[i].log_datetime.val);
              if (x && x !== 'Invalid date') {
                log_datetime = x;
              }
            }
            if (to_which_user_assigned_list.indexOf(task.data.general.log.list[i].which_user_log.val.label) === -1) {
              to_which_user_assigned_list.push(task.data.general.log.list[i].which_user_log.val.label);
            }
            // console.log('log_datetime', log_datetime);
            /*
              if (log_datetime) {
                user_text += task.data.general.log.list[i].log_user.val.label + ' -> ' + task.data.general.log.list[i].which_user_log.val.label + ' (' + log_datetime + ')';
              } else {
                user_text += task.data.general.log.list[i].log_user.val.label + ' -> ' + task.data.general.log.list[i].which_user_log.val.label;
              }
            */
          }
        }
        if (to_which_user_assigned_list.length > 0) {
          user_text = to_which_user_assigned_list.join(', ');
        }
      } catch (err) {}
      // console.log(task.created_at);
      if (list_type == 'by_commas') {
        return user_text;
      } else if (list_type == 'by_array') {
        return to_which_user_assigned_list;
      }
    },
    f_calculateLastAssignedDate: function (task) {
      try {
        for (let i = 0; i < task.data.general.log.list.length; i++) {
          if (task.data.general.log.list[i].log_status.val.value === 'assigned') {
            let k = this.DateFormatWithTime(task.data.general.log.list[i].log_datetime.val);
            if (k) {
              return k;
            } else {
              return '';
            }
          }
        }
      } catch (err) {}
      return '';
    },
    DateFormatWithTime: function (date, time_mode = 'all') {
      if (time_mode === 'all') {
        return moment(date, 'YYYY-MM-DDThh:mm:ss').format('DD/MM/YYYY HH:mm');
      } else if (time_mode === 'hour_and_minute') {
        return moment(date, 'YYYY-MM-DDThh:mm:ss').format('HH:mm');
      }
    },
    DateFormat: function (date) {
      return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY');
    },
    f_calculateCountOfSeenComments: function (task) {
      if (task.data && task.data.general && task.data.general.comment && task.data.general.comment.list) {
        let count = 0;
        for (let c in task.data.general.comment.list) {
          if (task.data.general.comment.list[c].who_saw && task.data.general.comment.list[c].who_saw.list) {
            for (let w in task.data.general.comment.list[c].who_saw.list) {
              if (task.data.general.comment.list[c].who_saw.list[w].user_saw.val && task.data.general.comment.list[c].who_saw.list[w].user_saw.val.value === this.user.id) {
                count += 1;
              }
            }
          }
        }
        return count.toString() + ' / ' + task.data.general.comment.list.length.toString();
      } else {
        return '0 / 0';
      }
    },
    f_showComments: function (task_ind, type) {
      this.d_newTaskComment = '';
      this.selected_task_list_type = type;
      this.selected_task_index = task_ind;
      // this.f_whoCanSeeThisTask();
      this.d_selectedTaskForComment = this.d_taskList[task_ind];
      let task = this.d_selectedTaskForComment;
      let task_updated = false;
      let comment_users = [];
      let user_pic_keys = [];
      if (task.data && task.data.general && task.data.general.comment && task.data.general.comment.list) {
        for (let c in task.data.general.comment.list) {
          let user_value = task.data.general.comment.list[c].who_commented.val.value;
          if (!this.d_allOrganizationUsers[user_value] || (this.d_allOrganizationUsers[user_value] && !this.d_allOrganizationUsers[user_value].pic)) {
            comment_users.push(user_value);
            user_pic_keys.push('user_' + user_value + '_picture');
          }
          let comment_seen = false;
          if (task.data.general.comment.list[c].who_saw && task.data.general.comment.list[c].who_saw.list) {
            for (let w in task.data.general.comment.list[c].who_saw.list) {
              if (task.data.general.comment.list[c].who_saw.list[w].user_saw.val && task.data.general.comment.list[c].who_saw.list[w].user_saw.val.value === this.user.id) {
                comment_seen = true;
              }
            }
          }
          let dt = new Date();
          if (!comment_seen) {
            task_updated = true;
            if (!task.data.general.comment.list[c].who_saw) {
              task.data.general.comment.list[c].who_saw = { 'list': [] };
            }
            let prepare_seen_data = {
              'user_saw': {
                'val': {
                  'value': this.user.id,
                  'label': this.user.first_name + ' ' + this.user.last_name
                }
              },
              'seen_datetime': {
                'val': dt.toISOString()
              }
            }
            task.data.general.comment.list[c].who_saw.list.push(prepare_seen_data);
          }
        }
      }
      if (task_updated) {
        this.f_saveOperationFast(task_ind, task);
      }
      this.d_showTaskCommentsModal = true;
      /*      if (user_pic_keys.length > 0) {
              GlobalService.get_multiple_cb_documents({ 'key_list': user_pic_keys, 'bucket': 'uploads' })
                .then(resp => {
                  if (resp.data.status_code === '12000') {
                    for (let i in resp.data.result) {
                      if (!this.d_allOrganizationUsers[resp.data.result[i].username]) {
                        this.d_allOrganizationUsers[resp.data.result[i].username] = { 'pic': '' };
                      }
                      this.d_allOrganizationUsers[resp.data.result[i].username].pic = resp.data.result[i].picture;
                    }
                    this.d_showTaskCommentsModal = true;
                    this.addEventListenerToWriting();
                  } else {
                    console.log('error:  ' + resp.data.message);
                  }
                });
            } else {
              this.d_showTaskCommentsModal = true;
              this.addEventListenerToWriting();
            }*/
    },
    addEventListenerToWriting: function () {
      const handler = (evt) => {
        setTimeout(function () {
          d_newTaskCommentKeyLastLocation = parseInt(evt.srcElement.selectionStart);
          // console.log('handler: ', d_newTaskCommentKeyLastLocation);
        }, 0);
      };
      setTimeout(function () {
        var div = document.getElementById("write-comment");
        div.addEventListener("keydown", handler);
      }, 50);
    },
    f_addNewComment: function () {
      if (!this.d_newTaskComment) {
        alert('Lütfen yorum alanına birşeyler yazınız.');
        return;
      }
      if (!this.d_selectedTaskForComment.data.general.comment) {
        this.d_selectedTaskForComment.data.general.comment = { 'list': [] };
      }
      let dt = new Date()
      let x = {
        'who_saw': {
          'list': []
        },
        'message': {
          'val': this.d_newTaskComment
        },
        'comment_datetime': {
          'val': dt.toISOString()
        },
        'who_commented': {
          'val': {
            'label': this.user.first_name + ' ' + this.user.last_name,
            'value': this.user.id
          }
        }
      };
      this.d_selectedTaskForComment.data.general.comment.list.push(JSON.parse(JSON.stringify(x)));
      let prepare_seen_data = {
        'user_saw': {
          'val': {
            'value': this.user.id,
            'label': this.user.first_name + ' ' + this.user.last_name
          }
        },
        'seen_datetime': {
          'val': dt.toISOString()
        }
      };

      this.d_selectedTaskForComment.data.general.comment.list[this.d_selectedTaskForComment.data.general.comment.list.length - 1].who_saw.list.push(prepare_seen_data);
      // let layer_type = 'operation';
      /*
        let parent_wdmr_id = '';
        if (this.d_selectedWdmr307 && this.d_selectedWdmr307.id) {
          parent_wdmr_id = this.d_selectedWdmr307.id;
        } else {
          parent_wdmr_id = this.d_selectedTaskForComment.id_tree[0]['id'][this.d_selectedTaskForComment.id_tree[0]['id'].length - 2].split('_')[1];
        }
      */
      let parent_wdmr_id = this.d_selectedTaskForComment.id_tree[0]['id'][this.d_selectedTaskForComment.id_tree[0]['id'].length - 2].split('_')[1];
      let operation_data = this.d_selectedTaskForComment;

      let query = 'organization_id=' + parent_wdmr_id;
      query += '&operation_id=' + operation_data.id
        // this.$store.commit('MutationLoading', { 'status': true, 'data': { 'label': 'Operasyon kayıt işlemi tamamlanıyor. Lütfen bekleyiniz.' } });
      let data = {
        'data': {
          'new_comment': this.d_newTaskComment,
        }
      };

      OrganizationService.operation_comment_new(query, data)
        .then(resp => {
          // this.$store.commit('MutationLoading', { 'status': false, 'data': {} });
          if (resp.data.status_code === '12000') {
            // console.log(resp);
            this.d_taskList[this.selected_task_index] = operation_data;
            this.d_selectedTaskForComment = operation_data;
            let cell_style_with_border = this.f_calculateStatusCellStyle(this.selected_task_index, '');
            let cell_style_without_border = this.f_calculateStatusCellStyle(this.selected_task_index, '', 'no');
            this.d_taskCellStyleList[operation_data['id']] = { 'with_border': cell_style_with_border, 'without_border': cell_style_without_border };
            this.d_taskCommentSeenList[operation_data['id']] = this.f_calculateCountOfSeenComments(operation_data);

            this.d_taskList[this.selected_task_index].cas = resp.cas;
            // let operation_text = ' *** Yorum: ' + this.d_newTaskComment + ' *** ';
            // this.f_sendMessageOverWconnectToOtherUsers(operation_data, operation_text);
            // this.f_sendAllUsersMailAboutThisOperation(operation_data, 'no', operation_text);
            this.d_newTaskComment = '';
            this.$forceUpdate();
            let msg = 'İşlem tamamlandı.';
            let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Operasyon yorum işlemi hakkında' };
            this.$store.commit('MutationModal', modal_data);
            this.$store.commit('MutationModalShow', true);
          } else {
            // this.$store.commit('MutationLoading', { 'status': false, 'data': {} });
            // console.log(resp);
            let msg = JSON.stringify(resp);
            let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Operasyon yorum işlemi hakkında' };
            this.$store.commit('MutationModal', modal_data);
            this.$store.commit('MutationModalShow', true);
          }
        }, resp => {
          let msg = JSON.stringify(resp);
          let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Operasyon yorum işlemi hakkında' };
          this.$store.commit('MutationModal', modal_data);
          this.$store.commit('MutationModalShow', true);
        });
    },
    f_addNewCommentOld: function () {
      if (!this.d_newTaskComment) {
        alert('Lütfen yorum alanına birşeyler yazınız.');
        return;
      }
      if (!this.d_selectedTaskForComment.data.general.comment) {
        this.d_selectedTaskForComment.data.general.comment = { 'list': [] };
      }
      let dt = new Date()
      let x = {
        'who_saw': {
          'list': []
        },
        'message': {
          'val': this.d_newTaskComment
        },
        'comment_datetime': {
          'val': dt.toISOString()
        },
        'who_commented': {
          'val': {
            'label': this.user.first_name + ' ' + this.user.last_name,
            'value': this.user.id
          }
        }
      };
      this.d_selectedTaskForComment.data.general.comment.list.push(JSON.parse(JSON.stringify(x)));
      let prepare_seen_data = {
        'user_saw': {
          'val': {
            'value': this.user.id,
            'label': this.user.first_name + ' ' + this.user.last_name
          }
        },
        'seen_datetime': {
          'val': dt.toISOString()
        }
      };

      this.d_selectedTaskForComment.data.general.comment.list[this.d_selectedTaskForComment.data.general.comment.list.length - 1].who_saw.list.push(prepare_seen_data);
      let layer_type = 'operation';
      /*
        let parent_wdmr_id = '';
        if (this.d_selectedWdmr307 && this.d_selectedWdmr307.id) {
          parent_wdmr_id = this.d_selectedWdmr307.id;
        } else {
          parent_wdmr_id = this.d_selectedTaskForComment.id_tree[0]['id'][this.d_selectedTaskForComment.id_tree[0]['id'].length - 2].split('_')[1];
        }
      */
      let parent_wdmr_id = this.d_selectedTaskForComment.id_tree[0]['id'][this.d_selectedTaskForComment.id_tree[0]['id'].length - 2].split('_')[1];
      let operation_data = this.d_selectedTaskForComment;
      let organization_name = '';
      if (this.d_selectedWdmr307 && this.d_selectedWdmr307.id) {
        organization_name = this.d_selectedWdmr307.label;
      } else {
        for (let i in this.d_organizationListData.list) {
          if (this.d_organizationListData.list[i].w_id === parent_wdmr_id) {
            organization_name = this.d_organizationListData.list[i].label;
            break;
          }
        }
      }
      /*
        if (this.d_newTaskComment.indexOf('@') !== -1 && this.d_organizationWdm22List.length > 0) {
          let user_regx = /@[a-z0-9A-Z_\.]+\s/g;
          let match_list = this.d_newTaskComment.match(user_regx);
          // console.log('match_list : ', match_list);
          if (match_list.length > 0) {
            let subject = this.user.username + ', Op, No: ' + operation_data.id;
            if (organization_name) {
              subject += ' / ' + organization_name;
            }
            let mail_text = this.f_prepareOperationMailDetailed(operation_data);
            let document_list = [];
            if (operation_data.document_list && operation_data.document_list.length > 0) {
              document_list = operation_data.document_list;
            }
            let uniq_wdm22_id_list = [];
            for (let i in match_list) {
              if (match_list[i]) {
                // console.log('match_list[i] : ', match_list[i]);
                let target_user_name = match_list[i].replace('@', '');
                target_user_name = target_user_name.replace(/[\s]+/g, "");
                let user_id = '';
                for (let k in this.d_organizationWdm22List) {
                  if (this.d_organizationWdm22List[k].username === target_user_name) {
                    uniq_wdm22_id_list.push(this.d_organizationWdm22List[k].w_id);
                    break;
                  }
                }
              }
            }
            if (uniq_wdm22_id_list.length > 0) {
              this.f_sendMail(subject, uniq_wdm22_id_list, mail_text, document_list);
            }
          }
        }
      */

      let query = 'organization_id=' + parent_wdmr_id;
      query += '&operation_id=' + operation_data.id
        // this.$store.commit('MutationLoading', { 'status': true, 'data': { 'label': 'Operasyon kayıt işlemi tamamlanıyor. Lütfen bekleyiniz.' } });
      let data = {
        'operation_data': operation_data,
        'send_mail': 'yes'
      };

      OrganizationService.operation_save(query, data)
        .then(resp => {
          // this.$store.commit('MutationLoading', { 'status': false, 'data': {} });
          if (resp.data.status_code === '12000') {
            // console.log(resp);
            this.d_taskList[this.selected_task_index] = operation_data;
            this.d_selectedTaskForComment = operation_data;

            let cell_style_with_border = this.f_calculateStatusCellStyle(this.selected_task_index, '');
            let cell_style_without_border = this.f_calculateStatusCellStyle(this.selected_task_index, '', 'no');
            this.d_taskCellStyleList[operation_data['id']] = { 'with_border': cell_style_with_border, 'without_border': cell_style_without_border };
            this.d_taskCommentSeenList[operation_data['id']] = this.f_calculateCountOfSeenComments(operation_data);

            this.d_taskList[this.selected_task_index].cas = resp.cas;
            let operation_text = ' *** Yorum: ' + this.d_newTaskComment + ' *** ';
            // this.f_sendMessageOverWconnectToOtherUsers(operation_data, operation_text);
            // this.f_sendAllUsersMailAboutThisOperation(operation_data, 'no', operation_text);
            this.d_newTaskComment = '';
            this.$forceUpdate();
            let msg = 'İşlem tamamlandı.';
            let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Operasyon yorum işlemi hakkında' };
            this.$store.commit('MutationModal', modal_data);
            this.$store.commit('MutationModalShow', true);
          } else {
            // this.$store.commit('MutationLoading', { 'status': false, 'data': {} });
            // console.log(resp);
            let msg = JSON.stringify(resp);
            let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Operasyon yorum işlemi hakkında' };
            this.$store.commit('MutationModal', modal_data);
            this.$store.commit('MutationModalShow', true);
          }
        }, resp => {
          let msg = JSON.stringify(resp);
          let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Operasyon yorum işlemi hakkında' };
          this.$store.commit('MutationModal', modal_data);
          this.$store.commit('MutationModalShow', true);
        });
    },
    f_showDocuments: function (task, task_ind) {
      this.d_taskDocuments.task = task;
      this.d_taskDocuments.task_ind = task_ind;
      this.d_taskDocuments.show = true;
    },
    f_createAcceptedDocumentTypes: function () {
      let accept = '';
      let file_types = ["jpg", "jpeg", "png", "docx", "doc", "pdf", "xlsx", "xls", "zip"];
      for (let i in file_types) {
        accept += '.' + file_types[i] + ',';
      }
      return accept;
    },
    f_addDocumentFastToTask: function (task_ind) {
      let task_data = this.d_taskList[task_ind];
      let file_name = this.d_tmpFile.name;
      let arr = this.d_tmpFile.name.split('.');
      let fileExtension = arr[arr.length - 1];
      if (["jpg", "jpeg", "png", "docx", "doc", "pdf", "xls", "xlsx", "html", "zip"].indexOf(fileExtension.toLowerCase()) === -1) {
        this.d_tmpFile = '';
        this.$forceUpdate();
        alert('yasaklı dosya tipi');
        return;
      }
      if (this.d_tmpFile.size > 10485760) {
        this.d_tmpFile = '';
        this.$forceUpdate();
        alert(this.$t('wdm16.12730'));
        return;
      }
      var reader = new FileReader();
      var vm = this;
      if (!task_data.document_list) {
        task_data.document_list = [];
      }
      let doc_data = {
        'record_status': 'add_edit',
        'status': 'upload',
        'user': this.user.username,
        'document': '',
        'information': '',
        'fileExtension': fileExtension,
        'file_name': file_name
      };
      task_data.document_list.push(doc_data);
      var reader = new FileReader();
      reader.addEventListener("load", function () {
        // console.log(reader.result);
        task_data.document_list[task_data.document_list.length - 1].document = reader.result;
        vm.f_saveOperationFast(task_ind, task_data);
      }, false);
      reader.readAsDataURL(this.d_tmpFile);
      this.d_tmpFile = '';
    },
    f_deleteDocumentInTask: function (task_ind, doc_ind) {
      let task_data = this.d_taskList[task_ind];
      task_data.document_list.splice(doc_ind, 1);
      // task_data.document_list[doc_ind]['record_status'] = 'deleted';
      this.f_saveOperationFast(task_ind, task_data);
    },
    f_saveOperationFast: function (task_ind, operation_data, send_mail = 'no') {
      let layer_type = 'operation';
      /*
        let parent_wdmr_id = '';
        if (this.d_selectedWdmr307 && this.d_selectedWdmr307.id) {
          parent_wdmr_id = this.d_selectedWdmr307.id;
        } else {
          parent_wdmr_id = operation_data.id_tree[0]['id'][operation_data.id_tree[0]['id'].length - 2].split('_')[1];
        }
      */
      let parent_wdmr_id = operation_data.id_tree[0]['id'][operation_data.id_tree[0]['id'].length - 2].split('_')[1];
      let query = 'organization_id=' + parent_wdmr_id;
      query += '&operation_id=' + operation_data.id;
      // this.$store.commit('MutationLoading', { 'status': true, 'data': { 'label': 'Operasyon kayıt işlemi tamamlanıyor. Lütfen bekleyiniz.' } });
      let data = {
        'operation_data': operation_data,
        'send_mail': send_mail,
      };

      OrganizationService.operation_save(query, data)
        .then(resp => {
          if (resp.data.status_code === '12000') {
            // this.$store.commit('MutationLoading', { 'status': false, 'data': {} });
            // console.log(resp);
            this.d_taskList[task_ind] = operation_data;
            this.d_taskList[task_ind].cas = resp.cas;

            let cell_style_with_border = this.f_calculateStatusCellStyle(task_ind, '');
            let cell_style_without_border = this.f_calculateStatusCellStyle(task_ind, '', 'no');
            this.d_taskCellStyleList[operation_data['id']] = { 'with_border': cell_style_with_border, 'without_border': cell_style_without_border };
            this.d_taskCommentSeenList[operation_data['id']] = this.f_calculateCountOfSeenComments(operation_data);

            this.f_showTrueFalseTaskDocumentField();
            if (this.selected_task_index !== '' && this.d_workTimeEditMode) {
              this.f_calculateTotalWorkTime(this.d_taskList[this.selected_task_index]);
              this.d_workTimeEditMode = false;
              this.d_newWorkTime = {
                'start': '',
                'end': '',
                'work_time_note': ''
              };
            }

            if (this.d_taskDocuments.show) {
              this.d_taskDocuments.task = operation_data;
            }
            this.$forceUpdate();
            let msg = 'İşlem tamamlandı.';
            alert(msg);
            // let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Operasyon yorum işlemi hakkında' };
            // this.$store.commit('MutationModal', modal_data);
            // this.$store.commit('MutationModalShow', true);

          } else {
            console.log('error :' + resp.data.message);
          }
        }, resp => {
          // this.$store.commit('MutationLoading', { 'status': false, 'data': {} });
          console.log(resp);
          let msg = JSON.stringify(resp);
          let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Operasyon yorum işlemi hakkında' };
          this.$store.commit('MutationModal', modal_data);
          this.$store.commit('MutationModalShow', true);
        });
    },
    f_showTrueFalseTaskDocumentField: function () {
      this.d_showTaskDocumentField = false;
      setTimeout(function () {
        this.d_showTaskDocumentField = true;
      }.bind(this), 5)
    },
    f_previewImage: function (wdmr, doc_ind, file_name) {
      if (wdmr.document_list[doc_ind].document) {
        this.document_show_data.file_name = wdmr.document_list[doc_ind].file_name;
        this.document_show_data.file = wdmr.document_list[doc_ind].document;
        this.show_image_show_modal = true;
      } else {
        let data = { 'key_list': [] };
        let key = wdmr.type + '_' + wdmr.id + '_doc' + doc_ind.toString();
        data.key_list.push(key);
        FileTransferService.get_file_with_key(data)
          .then(resp => {
            if (resp.data.status_code === '12000') {
              if (resp.data.result[key].file) {
                this.document_show_data.file_name = file_name;
                this.document_show_data.file = resp.data.result[key].file;
                this.show_image_show_modal = true;
              }
            } else {
              alert('error: ', resp.data.message);
            }
          });
      }
    },
    f_rotateImage: function () {
      if (this.rotate_degree === 360) {
        this.rotate_degree = 90;
      } else {
        this.rotate_degree += 90;
      }
    },
    f_scaleImageBig: function () {
      // this.f_showTrueFalse();
      this.d_scale += 0.1;
    },
    f_scaleImageSmall: function () {
      // this.f_showTrueFalse();
      this.d_scale -= 0.1;
    },
    f_downloadDocument: function (wdmr, doc_ind, file_name) {
      if (wdmr.document_list[doc_ind].document) {
        FileSaver.saveAs(wdmr.document_list[doc_ind].document, file_name);
      } else {
        let data = { 'key_list': [] };
        let key = wdmr.type + '_' + wdmr.id + '_doc' + doc_ind.toString();
        data.key_list.push(key);
        FileTransferService.get_file_with_key(data)
          .then(resp => {
            if (resp.data.status_code === '12000') {
              FileSaver.saveAs(resp.data.result[key].file, file_name);
            } else {
              alert('error: ', resp.data.message);
            }
          });
      }
    },
    f_controlOnlyMyPermission: function (task, perm) {
      if (task['organization_data']) {
        for (let i in task['organization_data']['perms_and_index_list']) {
          if (task['organization_data']['perms_and_index_list'][i].perms.indexOf(perm) !== -1) {
            return true;
          }
        }
      }
      return false;
    },
    f_isActiveUserEqualOrUpperOfTaskLayerList: function (task, can_use_me = false, team_member = false) {
      // wca_start
      // task.index_list => ['0,1,2', '4,2,1']; if a task assigned to at least one layer, we have this parameter
      // wca_end
      if (!task.index_list) {
        return true;
      }
      let task_layer_list = task.index_list;
      if (!task_layer_list || (task_layer_list && task_layer_list.length === 0)) {
        try {
          if (task.created_by === this.user.username && task.data.general.status.val.value === 'not_assigned') {
            return true;
          }
        } catch (err) {
          return false;
        }
        return false;
      }
      let my_item_index_list = [];
      if (task['organization_data']) {
        for (let i in task['organization_data']['perms_and_index_list']) {
          my_item_index_list.push(task['organization_data']['perms_and_index_list'][i].index);
        }
      }
      if (my_item_index_list.length === 0) {
        return false;
      }
      for (let i in my_item_index_list) {
        let one_back_index = '';
        if (team_member) {
          let arr = my_item_index_list[i].split(',');
          arr.splice(arr.length - 1, 1);
          one_back_index = arr.join(',');
        }
        for (let k in task_layer_list) {
          let layer_index = task_layer_list[i];
          // TODO layer_index split length ile my irem index list split length eşitliğ veyanın içinde kontrol edilecek
          if (layer_index.indexOf(my_item_index_list[i]) === 0 || (team_member && layer_index.indexOf(one_back_index) === 0)) {
            if (!can_use_me) {
              if (my_item_index_list[i] !== layer_index) {
                return true;
              }
            } else {
              return true;
            }
          }
        }
      }
      return false;
    },
    f_giveTaskToSelectedLayer: function (type, task_ind) {
      let task_data = JSON.parse(JSON.stringify(this.d_taskList[task_ind]));
      let task_key = 'organization_' + task_data['organization_id'] + '_task_' + task_data['id'];
      if (!this.selected_organization_item.task_list || (this.selected_organization_item.task_list && this.selected_organization_item.task_list.indexOf(task_key) === -1)) {
        if (type === 'transfer') {
          // console.log(this.organization_data);
          this.f_deleteTaskFromAllUsers(this.organization_data.list, task_key);
          this.$delete(this.d_taskList[task_ind], 'user_index_list');
        }
        if (!this.selected_organization_item.task_list) {
          this.selected_organization_item.task_list = [];
        }
        this.selected_organization_item.task_list.push(task_key);
        if (!this.d_taskList[task_ind].user_index_list) {
          this.d_taskList[task_ind].user_index_list = {};
        }
        this.d_taskList[task_ind].user_index_list[this.selected_organization_item.value] = {
          'index_list': [],
          'label': this.selected_organization_item.label,
          'value': this.selected_organization_item.value
        };
        if (this.d_taskList[task_ind].user_index_list[this.selected_organization_item.value].index_list.indexOf(this.selected_organization_item.index) === -1) {
          this.d_taskList[task_ind].user_index_list[this.selected_organization_item.value].index_list.push(this.selected_organization_item.index);
        }
        this.$forceUpdate();
        this.f_sendParentInfo('save_organization');
      } else {
        alert('Bu görev bu kullanıcıda zaten eklidir.');
      }
    },
    f_deleteTaskFromAllUsers: function (list_data, task_key) {
      for (let i in list_data) {
        if (list_data[i].task_list && list_data[i].task_list.indexOf(task_key) !== -1) {
          list_data[i].task_list.splice(list_data[i].task_list.indexOf(task_key), 1);
        }
        if ('list' in list_data[i] && list_data[i].list.length > 0) {
          this.f_deleteTaskFromAllUsers(list_data[i].list, task_key);
        }
      }
    },
    f_addStatusAsLogToTask: function (task_data, selected_status, which_user_log) {
      task_data.data.general.status = { 'val': selected_status };
      if (!task_data.data.general.log) {
        task_data.data.general.log = { 'list': [] };
      }
      let log_datetime = '';
      log_datetime = new Date();
      if (this.d_applyStatusOperation.log_date) {
        log_datetime = this.d_applyStatusOperation.log_date;
      }
      let x = {
        'log_user': {
          'val': {
            'label': this.user.first_name + ' ' + this.user.last_name,
            'value': this.user.id
          }
        },
        'which_user_log': which_user_log,
        'log_status': {
          'val': selected_status
        },
        'log_datetime': {
          'val': log_datetime
        }
      };
      task_data.data.general.log.list.push(JSON.parse(JSON.stringify(x)));
    },
    f_sendParentInfo: function (op) {
      if (this.watch_task_list[op] === 0) {
        this.watch_task_list[op] = 1;
      } else {
        this.watch_task_list[op] = 0;
      }
    },
    f_showTaskPreviewModal: function (operation_id) {
      let query = 'wdmr_id=' + operation_id;
      OrganizationService.operation_wdmr_token_get(query)
        .then(resp => {
          if (resp.data.status_code === '12000') {
            this.d_showWdmrPreview.url_show_iframe = resp.data.url_show_iframe;
            this.d_showWdmrPreview.show = true;
            this.$forceUpdate();
          } else {
            alert('error ' + resp.data.message);
          }
        });
    },
    f_taskStatusThis: function (status_list, task_data) {
      try {
        if (status_list.indexOf(task_data.data.general.status.val.value) !== -1) {
          return true;
        }
      } catch (err) {
        return false;
      }
    },
    f_controlState: function (type, status, task_data) {
      let task_status = task_data.data.general.status.val.value;
      // if the same status, return false
      if (task_status === status) {
        return false;
      }
      if (['assigned', 'recommended'].indexOf(status) !== -1) {
        return false;
      }
      if (['not_assigned'].indexOf(status) !== -1 && type === 'all_task_list') {
        return false;
      }
      if (['completed'].indexOf(task_status) === -1 && ['checking_task_completion'].indexOf(status) !== -1) {
        return false;
      }
      if (['checking_task_completion'].indexOf(task_status) === -1 && ['approved_task_completion'].indexOf(status) !== -1) {
        return false;
      }
      return true;
    },
    f_showWorkTime: function (task_ind, date_type = 'now', this_date = '') {
      this.d_workTimeEditMode = false;
      this.d_newWorkTime = {
        'start': '',
        'end': '',
        'work_time_note': ''
      };
      if (date_type === 'now') {
        let now_date = new Date();
        let hour_string = now_date.getHours().toString();
        let now_date_string = moment(now_date).toISOString();
        let minute_val = (parseInt((parseInt(now_date_string.split('T')[1].split(':')[1]) / 5).toString().split('.')[0]) * 5).toString();
        if (minute_val.length === 1) {
          minute_val = '0' + minute_val;
        }
        this.d_newWorkTime.start = now_date_string.split('T')[0] + ' ' + hour_string + ':' + minute_val;
      } else if (date_type === 'this_date') {
        let x = new Date(this_date);
        x.setHours(8, 0, 0, 0);
        this.d_newWorkTime.start = moment(x).toISOString();
        x.setHours(23, 0, 0, 0);
        this.d_newWorkTime.end = moment(x).toISOString();
      }
      this.selected_task_index = task_ind;
      this.d_showWorkTimeModal.task_id = this.d_taskList[this.selected_task_index].id;
      this.f_calculateTotalWorkTime(this.d_taskList[this.selected_task_index]);
      this.d_showWorkTimeModal.show = true;
    },
    f_calculateTotalWorkTime: function (task) {
      if (this.d_taskList[this.selected_task_index].data.general.work_time && this.d_taskList[this.selected_task_index].data.general.work_time.list && this.d_taskList[this.selected_task_index].data.general.work_time.list.length > 0) {
        let total_miliseconds = 0;
        for (let i in this.d_taskList[this.selected_task_index].data.general.work_time.list) {
          let start = '';
          let end = '';
          try {
            start = this.d_taskList[this.selected_task_index].data.general.work_time.list[i].start.val;
            end = this.d_taskList[this.selected_task_index].data.general.work_time.list[i].end.val;
          } catch (err) {}
          if (start && end) {
            let start_date = new Date(start);
            let end_date = new Date(end);
            let start_date_mSec = start_date.getTime();
            let end_date_mSec = end_date.getTime();
            total_miliseconds += end_date_mSec - start_date_mSec;
          }
        }
        if (total_miliseconds >= 86400000) {
          let k = parseInt((total_miliseconds / 86400000).toString().split('.')[0]);
          this.d_taskWorkTime.day = k.toString();
          total_miliseconds -= (k * 86400000);
        }
        if (total_miliseconds >= 3600000) {
          let k = parseInt((total_miliseconds / 3600000).toString().split('.')[0]);
          this.d_taskWorkTime.hour = k.toString();
          total_miliseconds -= (k * 3600000);
        }
        if (total_miliseconds >= 60000) {
          let k = parseInt((total_miliseconds / 60000).toString().split('.')[0]);
          this.d_taskWorkTime.minute = k.toString();
          total_miliseconds -= (k * 60000);
        }
        if (total_miliseconds >= 1000) {
          let k = parseInt((total_miliseconds / 1000).toString().split('.')[0]);
          this.d_taskWorkTime.second = k.toString();
          total_miliseconds -= (k * 1000);
        }
      }
    },
    f_updateWorkTimeInfo: function (worktime_ind) {
      let task_index = '';
      for (let i in this.d_taskList) {
        if (this.d_taskList[i].id === this.d_showWorkTimeModal.task_id) {
          task_index = parseInt(i);
          break
        }
      }
      this.selected_task_index = task_index;
      let task_data = this.d_taskList[task_index];
      this.f_saveOperationFast(task_index, task_data, 'no');
      let work_time_text = this.f_convertHtmlToPlainText(task_data.data.general.work_time.list[worktime_ind].work_time_note.val);
      // this.f_sendAllUsersMailAboutThisOperation(task_data, 'no', work_time_text);
      /*
        let work_time_text = this.f_convertHtmlToPlainText(task_data.data.general.work_time.list[worktime_ind].work_time_note.val);
        this.f_sendMailToTagIncludingUsers(work_time_text, task_data);
      */
    },
    f_deleteWorkTime: function (worktime_ind) {
      let task_index = '';
      for (let i in this.d_taskList) {
        if (this.d_taskList[i].id === this.d_showWorkTimeModal.task_id) {
          task_index = parseInt(i);
          break
        }
      }
      this.selected_task_index = task_index;
      let task_data = this.d_taskList[this.selected_task_index];
      this.d_taskList[this.selected_task_index].data.general.work_time.list.splice(worktime_ind, 1);
      this.f_saveOperationFast(this.selected_task_index, task_data);
    },
    f_editWorkTime: function (worktime_ind) {
      this.d_selectedWorkItemIndex = worktime_ind;
      this.d_workTimeEditMode = true;
      this.d_newWorkTime.start = this.d_taskList[this.selected_task_index].data.general.work_time.list[worktime_ind].start.val;
      if (this.d_taskList[this.selected_task_index].data.general.work_time.list[worktime_ind].end && this.d_taskList[this.selected_task_index].data.general.work_time.list[worktime_ind].end.val) {
        this.d_newWorkTime.end = this.d_taskList[this.selected_task_index].data.general.work_time.list[worktime_ind].end.val;
      }
      if (this.d_taskList[this.selected_task_index].data.general.work_time.list[worktime_ind].work_time_note && this.d_taskList[this.selected_task_index].data.general.work_time.list[worktime_ind].work_time_note.val) {
        this.d_newWorkTime.work_time_note = this.d_taskList[this.selected_task_index].data.general.work_time.list[worktime_ind].work_time_note.val;
      }
      this.$forceUpdate();
    },
    f_addEditNewWorkTime: function () {
      let task_index = '';
      for (let i in this.d_taskList) {
        if (this.d_taskList[i].id === this.d_showWorkTimeModal.task_id) {
          task_index = parseInt(i);
          break
        }
      }
      this.selected_task_index = task_index;
      let task_data = this.d_taskList[task_index];
      if (this.d_newWorkTime.start) {
        if (!task_data.data.general.work_time) {
          task_data.data.general.work_time = { 'list': [] };
        }
        let start = '';
        let end = '';
        let new_start = '';
        let new_startMsec = 0;
        let new_end = '';
        let new_endMsec = 0;
        // console.log(this.d_newWorkTime);
        if (this.d_newWorkTime.start && this.d_newWorkTime.end) {
          for (let i in task_data.data.general.work_time.list) {
            try {
              start = task_data.data.general.work_time.list[i].start.val;
              end = task_data.data.general.work_time.list[i].end.val;
              new_start = new Date(this.d_newWorkTime.start);
              new_startMsec = new_start.getTime();
              new_end = new Date(this.d_newWorkTime.end);
              new_endMsec = new_end.getTime();
            } catch (err) {}
            // console.log(start, end);
            if (start && end) {
              let start_date = new Date(start);
              let end_date = new Date(end);
              let start_date_mSec = start_date.getTime();
              let end_date_mSec = end_date.getTime();
              // console.log('start_date_mSec: ', start_date_mSec);
              // console.log('end_date_mSec: ', end_date_mSec);
              // console.log('new_startMsec: ', new_startMsec);
              // console.log('new_endMsec: ', new_endMsec);
              if ((new_startMsec >= start_date_mSec && new_startMsec < end_date_mSec) || (new_endMsec > start_date_mSec && end_date_mSec >= new_endMsec)) {
                alert('Daha önce belirttiğiniz çalışma aralığını lütfen belirtmeyiniz.');
                return;
              }
            }
          }
        }
        let prepare_work_time = {
          'start': { 'val': this.d_newWorkTime.start },
          'end': { 'val': this.d_newWorkTime.end },
          'work_time_note': { 'val': this.d_newWorkTime.work_time_note }
        };
        if (this.d_workTimeEditMode) {
          task_data.data.general.work_time.list[this.d_selectedWorkItemIndex] = prepare_work_time;
        } else {
          task_data.data.general.work_time.list.push(prepare_work_time);
        }
        let work_time_text = this.f_convertHtmlToPlainText(this.d_newWorkTime.work_time_note);
        // console.log('work_time_text : ', work_time_text);
        /*
          this.f_sendMailToTagIncludingUsers(work_time_text, task_data);
        */
        /*
          let parent_wdmr_id = '';
          if (this.d_selectedWdmr307 && this.d_selectedWdmr307.id) {
            parent_wdmr_id = this.d_selectedWdmr307.id;
          } else {
            parent_wdmr_id = task_data.id_tree[0]['id'][task_data.id_tree[0]['id'].length - 2].split('_')[1];
          }
        */
        let parent_wdmr_id = task_data.id_tree[0]['id'][task_data.id_tree[0]['id'].length - 2].split('_')[1];
        let query = 'organization_id=' + parent_wdmr_id;
        query += '&operation_id=' + task_data.id
        this.$store.commit('MutationLoading', { 'status': true, 'data': { 'label': 'Operasyon kayıt işlemi tamamlanıyor. Lütfen bekleyiniz.' } });
        let data = {
          'new_work_time_note': this.d_newWorkTime.work_time_note,
          'new_work_time_start': this.d_newWorkTime.start,
          'new_work_time_end': this.d_newWorkTime.end,
        };

        OrganizationService.operation_work_time_add(query, data)
          .then(resp => {
            this.$store.commit('MutationLoading', { 'status': false, 'data': {} });
            if (resp.data.status_code === '12000') {
              //
            } else {
              // this.$store.commit('MutationLoading', { 'status': false, 'data': {} });
              // console.log(resp);
              let msg = JSON.stringify(resp);
              let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Operasyon çalışma notu hakkında' };
              this.$store.commit('MutationModal', modal_data);
              this.$store.commit('MutationModalShow', true);
            }
          }, resp => {
            let msg = JSON.stringify(resp);
            let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Operasyon çalışma notu hakkında' };
            this.$store.commit('MutationModal', modal_data);
            this.$store.commit('MutationModalShow', true);
          });
        // this.f_saveOperationFast(this.selected_task_index, task_data);
        // this.f_sendAllUsersMailAboutThisOperation(task_data, 'no', this.d_newWorkTime.work_time_note);
      }
    },
    f_sendMailToTagIncludingUsers: function (plain_text, task_data) {
      /*
        let parent_wdmr_id = '';
        if (this.d_selectedWdmr307 && this.d_selectedWdmr307.id) {
          parent_wdmr_id = this.d_selectedWdmr307.id;
        } else {
          parent_wdmr_id = task_data.id_tree[0]['id'][task_data.id_tree[0]['id'].length - 2].split('_')[1];
        }
      */
      let parent_wdmr_id = task_data.id_tree[0]['id'][task_data.id_tree[0]['id'].length - 2].split('_')[1];
      let organization_name = '';
      if (this.d_selectedWdmr307 && this.d_selectedWdmr307.id) {
        organization_name = this.d_selectedWdmr307.label;
      } else {
        for (let i in this.d_organizationListData.list) {
          if (this.d_organizationListData.list[i].w_id === parent_wdmr_id) {
            organization_name = this.d_organizationListData.list[i].label;
            break;
          }
        }
      }
      if (plain_text.indexOf('@') !== -1 && this.d_organizationWdm22List.length > 0) {
        let user_regx = /@[a-z0-9A-Z_\.]+\s/g;
        let match_list = plain_text.match(user_regx);
        // console.log('match_list : ', match_list);
        if (match_list && match_list.length > 0) {
          let subject = this.user.username + ', Op, No: ' + task_data.id;
          if (organization_name) {
            subject += ' / ' + organization_name;
          }
          let mail_text = this.f_prepareOperationMailDetailed(task_data);
          let document_list = [];
          if (task_data.document_list && task_data.document_list.length > 0) {
            document_list = task_data.document_list;
          }
          let uniq_wdm22_id_list = [];
          for (let i in match_list) {
            if (match_list[i]) {
              // console.log('match_list[i] : ', match_list[i]);
              let target_user_name = match_list[i].replace('@', '');
              target_user_name = target_user_name.replace(/[\s]+/g, "");
              // console.log('target_user_name : ', target_user_name);
              let user_id = '';
              for (let k in this.d_organizationWdm22List) {
                // console.log('this.d_organizationWdm22List[k].username : ', this.d_organizationWdm22List[k].username);
                // console.log('target_user_name                         : ', target_user_name);
                if (this.d_organizationWdm22List[k].username === target_user_name) {
                  // console.log('addded');
                  if (uniq_wdm22_id_list.indexOf(this.d_organizationWdm22List[k].w_id) === -1) {
                    uniq_wdm22_id_list.push(this.d_organizationWdm22List[k].w_id);
                  }
                  break;
                }
              }
            }
          }
          // console.log('uniq_wdm22_id_list : ', uniq_wdm22_id_list);
          if (uniq_wdm22_id_list.length > 0) {
            this.f_sendMail(subject, uniq_wdm22_id_list, mail_text, document_list);
          }
        }
      }
    },
    f_convertHtmlToPlainText: function (html_text) {
      // Create a new div element
      var tempDivElement = document.createElement("div");
      // Set the HTML content with the given value
      tempDivElement.innerHTML = html_text;
      // Retrieve the text property of the element 
      return tempDivElement.textContent || tempDivElement.innerText || "";
    },
    f_showWorkTimeEnd: function () {
      this.d_showWorkTimeEnd = false;
      setTimeout(function () {
        this.d_showWorkTimeEnd = true;
      }.bind(this), 1)
    },
    f_saveTask: function () {
      let return_data = ClsWdmrForever.turn(this.wisdom_data, this.option_data[this.data_type], ['name', 'delete']);
      let wisdom_data = return_data.wdmr;
      wisdom_data.label = return_data.name;
      let wdmr_require_alerts = return_data.wdmr_require_alerts;
      let only_one_param_is_filled = return_data.only_one_param_is_filled;
      if (!only_one_param_is_filled) {
        let modal_data = { 'type': 'alert', 'text': 'Veri modelini kaydedebilmek için en az bir veriniz dolu olmalıdır.', 'centered': true, 'title': 'Veri modeli kayıt işlemi zorunlu alanları tamamlayınız.' };
        this.$store.commit('MutationModal', modal_data);
        this.$store.commit('MutationModalShow', true);
        return;
      }
      if (wdmr_require_alerts && wdmr_require_alerts.length > 0) {
        let require_txt = '';
        for (let r in wdmr_require_alerts) {
          require_txt += ' * ' + wdmr_require_alerts[r].label;
        }
        let modal_data = { 'type': 'alert', 'text': require_txt, 'centered': true, 'title': 'Veri modeli kayıt işlemi zorunlu alanları tamamlayınız.' };
        this.$store.commit('MutationModal', modal_data);
        this.$store.commit('MutationModalShow', true);
        return;
      }
      OrganizationService.save_task(wisdom_data)
        .then(resp => {
          if (resp.data.status_code === '12000') {
            this.show_wisdom_data_modal = false;
            if (this.d_taskList[this.selected_task_index] && resp.data.result.id === this.d_taskList[this.selected_task_index].id) {
              this.d_taskList[this.selected_task_index] = resp.data.result;
            }
            this.$forceUpdate();
          } else {
            alert('error ' + resp.data.message);
          }
        });
    },
    f_prepareTagList: function () {
      this.d_tagOperation.all_tags = [];
      if (this.d_selectedWdmr307.data.general && this.d_selectedWdmr307.data.general.tag && this.d_selectedWdmr307.data.general.tag.list && this.d_selectedWdmr307.data.general.tag.list.length > 0) {
        for (let i in this.d_selectedWdmr307.data.general.tag.list) {
          this.d_tagOperation.all_tags.push(this.d_selectedWdmr307.data.general.tag.list[i].tag_name.val);
        }
      }
    },
    f_showTagOperation: function (operation_ind) {
      this.d_tagOperation.operation_ind = operation_ind;
      if (this.d_taskList[operation_ind].data.general.tag && this.d_taskList[operation_ind].data.general.tag.list && this.d_taskList[operation_ind].data.general.tag.list.length > 0) {
        for (let i in this.d_taskList[operation_ind].data.general.tag.list) {
          this.d_tagOperation.selected_tags.push(this.d_taskList[operation_ind].data.general.tag.list[i].tag_name.val);
        }
      }
      this.d_tagOperation.show = true;
    },
    f_updateOperationTags: function () {
      let operation_data = JSON.parse(JSON.stringify(this.d_taskList[this.d_tagOperation.operation_ind]));
      if (this.d_tagOperation.selected_tags.length === 0) {
        if (operation_data.data.general.tag) {
          this.$delete(operation_data.data.general, 'tag');
        }
      } else {
        operation_data.data.general.tag = { 'list': [] };
        for (let i in this.d_tagOperation.selected_tags) {
          let x = { 'tag_name': { 'val': this.d_tagOperation.selected_tags[i] } };
          operation_data.data.general.tag.list.push(x);
        }
      }
      this.f_saveOperationFast(this.d_tagOperation.operation_ind, operation_data);
    },
    f_addRemoveTag: function (tag) {
      if (this.d_tagOperation.selected_tags.indexOf(tag) === -1) {
        this.d_tagOperation.selected_tags.push(tag);
      } else {
        this.d_tagOperation.selected_tags.splice(this.d_tagOperation.selected_tags.indexOf(tag), 1);
      }
      this.$forceUpdate();
    },
    f_createTasksForPrint: function () {
      // console.log(this.d_taskList)
      let with_tags = {'tags_order': [], 'tags': {}};
      let without_tags = {'list': []};
      let tagFilter = this.d_workTimeTagFilter.selected_list;
      for (let task of this.d_taskList) {
        if (task.data && task.data.general && task.data.general.operation_name && task.data.general.operation_name.val) {
          if(task.data.general.tag){
            for (let taskTag of task.data.general.tag.list) {
              if( (tagFilter.length && tagFilter.includes(taskTag.tag_name.val)) || !tagFilter.length ) {
                if (!with_tags.tags_order.includes(taskTag.tag_name.val)) {
                  with_tags.tags_order.push(taskTag.tag_name.val)
                  with_tags.tags[taskTag.tag_name.val]={}
                  with_tags.tags[taskTag.tag_name.val]["list"]=[]
                }
                with_tags.tags[taskTag.tag_name.val]["list"].push({'data': task.data, 'id': task.id})
                break;
              }
              
            }
          } else {
            if(task.data && !tagFilter.length){
              without_tags["list"].push({'data': task.data, 'id': task.id})
            }
            
          }
        }
      }
      with_tags.tags_order.sort()
      //sorting for with_tags
      for (let tag of with_tags.tags_order) {
        for (let task of with_tags.tags[tag].list){
          if (task.data.general) {
            if("work_time" in task.data.general)
              task.data.general.work_time.list.sort((a,b)=>{
                return new Date(a.start.val) - new Date(b.start.val)
              })
          }
        }
        with_tags.tags[tag].list.sort((a,b)=>{
          if (a.data.general && b.data.general) {
            if("work_time" in a.data.general){
              if("work_time" in b.data.general){
                return new Date(a.data.general.work_time.list[0].start.val) - new Date(b.data.general.work_time.list[0].start.val);
              }
              else{
                return -1;
              }
            } else if("work_time" in b.data.general){
              return 1;
            } else{
              if("desired_begin_date" in a.data.general){
                if("desired_begin_date" in b.data.general){
                  return new Date(a.data.general.desired_begin_date.val) - new Date(b.data.general.desired_begin_date.val);
                }
                else{
                  return -1;
                }
              }
              else{
                if("desired_begin_date" in b.data.general){
                  return 1;
                }
                else{
                  return 0;
                }
              }
            }
          }

          // if("work_time" in a.data.general && "work_time" in b.data.general){
          //   return new Date(a.data.general.work_time.list[0].start.val) - new Date(b.data.general.work_time.list[0].start.val);
          // }
          // else{
          //   return 0;
          // }
        })
      }
      //sorting for without_tags
      for(let task of without_tags.list){
        if (task.data && task.data.general && task.data.general.operation_name && task.data.general.operation_name.val) {
          if("work_time" in task.data.general) {
            task.data.general.work_time.list.sort((a,b)=>{
              return new Date(a.start.val) - new Date(b.start.val)
            })
          }
        }
      }

      // console.log('without_tags: ', without_tags);
      without_tags.list.sort((a,b)=>{
        if (a.data.general && b.data.general) {
          if("work_time" in a.data.general){
              if("work_time" in b.data.general){
                return new Date(a.data.general.work_time.list[0].start.val) - new Date(b.data.general.work_time.list[0].start.val);
              }
              else{
                return -1;
              }
            }
            else if("work_time" in b.data.general){
              return 1;
            }
            else{
              if("desired_begin_date" in a.data.general){
                if("desired_begin_date" in b.data.general){
                  return new Date(a.data.general.desired_begin_date.val) - new Date(b.data.general.desired_begin_date.val);
                }
                else{
                  return -1;
                }
              }
              else{
                if("desired_begin_date" in b.data.general){
                  return 1;
                }
                else{
                  return 0;
                }
              }
            }
        }

        // if("work_time" in a.data.general && "work_time" in b.data.general){
        //   return new Date(a.data.general.work_time.list[0].start.val) - new Date(b.data.general.work_time.list[0].start.val)
        // }
        // else{
        //   return 0;
        // }
      })
      this.d_tasksForPrint = {'with_tags': with_tags, 'without_tags': without_tags}
      // console.log(this.d_tasksForPrint);
      this.f_setAllTasksAssignedBy();
    },
    f_printFunction: function () {
      /*
        *** window.open usage ***
          URL Optional.
            The URL of the page to open.
            If no URL is specified, a new blank window/tab is opened
          name  Optional.
            The target attribute or the name of the window.
            The following values are supported:
            Value Description
            _blank  URL is loaded into a new window, or tab. This is the default
            _parent URL is loaded into the parent frame
            _self URL replaces the current page
            _top  URL replaces any framesets that may be loaded
            name  The name of the window (does not specify the title of the window)
          specs Optional.
            A comma-separated list of items, no whitespaces.
            The following values are supported:
          fullscreen=yes|no|1|0 Whether or not to display the browser in full-screen mode. Default is no. A window in full-screen mode must also be in theater mode. IE only
          height=pixels The height of the window. Min. value is 100
          left=pixels The left position of the window. Negative values not allowed
          location=yes|no|1|0 Whether or not to display the address field. Opera only
          menubar=yes|no|1|0  Whether or not to display the menu bar
          resizable=yes|no|1|0  Whether or not the window is resizable. IE only
          scrollbars=yes|no|1|0 Whether or not to display scroll bars. IE, Firefox & Opera only
          status=yes|no|1|0 Whether or not to add a status bar
          titlebar=yes|no|1|0 Whether or not to display the title bar. Ignored unless the calling application is an HTML Application or a trusted dialog box
          toolbar=yes|no|1|0  Whether or not to display the browser toolbar. IE and Firefox only
          top=pixels  The top position of the window. Negative values not allowed
          width=pixels  The width of the window. Min. value is 100
      */
      let organizationCreatedDate = new Date(this.d_selectedWdmr307.created_at);
      let organizationCreatedDateFormatted = moment(organizationCreatedDate.toString()).format('DD.MM.YYYY');
      let organizationLabel = this.d_selectedWdmr307.label;
      let organizationId = this.d_selectedWdmr307.id;
      let d_printLabels = this.d_printLabels;
      let d_printLang = this.d_printLang;
      let formattedDate = this.f_dateTimeForLang(this.d_selectedWdmr307.created_at, false)
      let footer_replace = "";
      let header_replace = `
        <div>
          <div style="position: absolute; top: 0px; left: 0px;">
            <p style="font-size: 16px;">${d_printLabels.documentTitle[d_printLang]} ${organizationId}</p>
          </div>
          <div style="position: absolute; top: 0px; right: 0px;">
            <img src="/img/logo.png" alt="logo" style="height: 60px; display: block">
          </div>
        </div>
      `;


      let w = window.open('', '_blank', 'height=750, width=1000, toolbar=0, menubar=0, left=100, top=30');
      let pageHtml = htmlTemplate;
      pageHtml = pageHtml.replace('##HEADER##', header_replace);
      let cover_replace = `
        <div style="display: flex; justify-content: center; align-items: center; text-align: center; height: 100vh; flex-direction: column; font-size: 24px;">
          <strong>WisdomEra</strong>
          <strong>${organizationLabel}</strong>
          <strong>${d_printLabels.title[d_printLang]}</strong>
          <strong>Proje Id: ${organizationId}</strong>
          <strong>(${formattedDate})</strong>
        </div>
      `;
      pageHtml = pageHtml.replace('##COVER##', cover_replace);
      let title_html = `${d_printLabels.documentTitle[d_printLang]} ${organizationId} - ${organizationLabel}`
      pageHtml = pageHtml.replace('##TITLE##', title_html);
      let body_html = '<div style="padding: 10px;">';
      body_html += this.$refs.print_area.innerHTML;
      body_html += '</div>';
      pageHtml = pageHtml.replace('##BODY##', body_html);
      pageHtml = pageHtml.replace('##FOOTER##', footer_replace);
      w.document.write(pageHtml);
      w.document.close();
      w.onload = function () {
        w.focus();
        w.print();
      };
    },
    f_getTasksFinishDate: function (tag="") {
      let finishTime = "";
      if (tag) {
        for(let task of this.d_tasksForPrint.with_tags.tags[tag].list){
          if(task.data.general.work_time && task.data.general.work_time.list.length){
            for(let workTime of task.data.general.work_time.list){
              if(!finishTime || new Date (workTime.end.val) > new Date (finishTime)){
                finishTime = workTime.end.val;
              }
            }
          }
        }
      } else {
        for (let task of this.d_tasksForPrint.without_tags.list) {
          if(task.data.general.work_time && task.data.general.work_time.list.length){
            for(let workTime of task.data.general.work_time.list){
              if(!finishTime || new Date (workTime.end.val) > new Date (finishTime)){
                finishTime = workTime.end.val;
              }
            }
          }
        }
      }
      if (finishTime) {
        let formattedDate = moment(finishTime).format('DD.MM.YYYY')
        return formattedDate;
      }
      return finishTime;
    },
    f_getAssignedByFromTag: function (tag="") {
      let users = [];
      if(tag){
        for(let task of this.d_tasksForPrint.with_tags.tags[tag].list){
          let taskUsers = this.f_getAssignedBy(task, 'by_array');
          for(let taskUser of taskUsers){
            if(!users.includes(taskUser)){
              users.push(taskUser)
            }
          }
        }
      }
      else{
        for(let task of this.d_tasksForPrint.without_tags.list){
          let taskUsers = this.f_getAssignedBy(task, 'by_array');
          for(let taskUser of taskUsers){
            if(!users.includes(taskUser)){
              users.push(taskUser)
            }
          }
        }
      }
      // console.log(users)
      return users;
    },
    f_setAllTasksAssignedBy: function () {
      for(let tag of this.d_tasksForPrint.with_tags.tags_order){
        for(let task of this.d_tasksForPrint.with_tags.tags[tag].list){
          if (task.data && task.data.general) {
            let users = [];
            let taskUsers = this.f_getAssignedBy(task, 'by_array');
            for(let taskUser of taskUsers){
              if(!users.includes(taskUser)){
                users.push(taskUser)
              }
            }
            if(users && users.length){
              task.data.general.assigned_users = users;
            }
          }
        }
      }
      for(let task of this.d_tasksForPrint.without_tags.list){
        let users = [];
        let taskUsers = this.f_getAssignedBy(task, 'by_array');
        for(let taskUser of taskUsers){
          if(!users.includes(taskUser)){
            users.push(taskUser)
          }
        }
        if(users && users.length){
          if (task.data && task.data.general) {
            task.data.general.assigned_users = users;
          }
        }
      }
      this.f_getAllAssigned();
    },
    f_getAllAssigned: function () {
      let usersAndTitles = {
        users_order: []
      }
      for(let tag of this.d_tasksForPrint.with_tags.tags_order){
        for(let task of this.d_tasksForPrint.with_tags.tags[tag].list){
          if(task.data.general && task.data.general.assigned_users && task.data.general.assigned_users.length){
            for(let user of task.data.general.assigned_users){
              if(!usersAndTitles.users_order.includes(user)){
                usersAndTitles.users_order.push(user);
                usersAndTitles[user] = { title: {}, titles_order: [], label:"" };
              }
              let assignedUserTitle = task.data.general.assigned_user_title;
              if (assignedUserTitle && assignedUserTitle.val){
                if(!usersAndTitles[user].titles_order.includes(assignedUserTitle.val.value)){
                  usersAndTitles[user].label = user
                  usersAndTitles[user].titles_order.push(assignedUserTitle.val.value);
                  usersAndTitles[user].title[assignedUserTitle.val.value] = {
                    translation: {
                      "en": assignedUserTitle.val.translation.en,
                      "tr": assignedUserTitle.val.translation.tr
                    }
                  };
                }
              }
              
            }
          }
        }
      }
      for(let task of this.d_tasksForPrint.without_tags.list){
        if(task.data.general && task.data.general.assigned_users && task.data.general.assigned_users.length){
          for(let user of task.data.general.assigned_users){
            if(!usersAndTitles.users_order.includes(user)){
              usersAndTitles.users_order.push(user);
              usersAndTitles[user] = { title: {}, titles_order: [] };
            }
            let assignedUserTitle = task.data.general.assigned_user_title;
            if (assignedUserTitle && assignedUserTitle.val){
              if(!usersAndTitles[user].titles_order.includes(assignedUserTitle.val.value)){
                  usersAndTitles[user].label = user
                usersAndTitles[user].titles_order.push(assignedUserTitle.val.value);
                usersAndTitles[user].title[assignedUserTitle.val.value] = {
                  translation: {
                    "en": assignedUserTitle.val.translation.en,
                    "tr": assignedUserTitle.val.translation.tr
                  }
                };
              }
            }
            
          }
        }
      }
      // console.log(usersAndTitles);
      this.d_allAssigned = usersAndTitles;
    },
    f_calculateTotalPrice: function () {
      if(this.d_inputDayPrice){
        let countDay=0;
        for(let tag of this.d_tasksForPrint.with_tags.tags_order){
          for(let task of this.d_tasksForPrint.with_tags.tags[tag].list){
            if(task.data.general && task.data.general.planned_total_worktime_days){
              countDay += parseInt(task.data.general.planned_total_worktime_days.val);
            }
          }
        }
        for(let task of this.d_tasksForPrint.without_tags.list){
          if(task.data.general && task.data.general.planned_total_worktime_days){
              countDay += parseInt(task.data.general.planned_total_worktime_days.val);
            }
        }
        // console.log("countDay: "+countDay);
        this.d_totalPriceWithoutKDV = this.d_inputDayPrice * countDay;
        this.d_totalPrice = this.d_totalPriceWithoutKDV * (1 + this.d_inputKDV / 100)
      }
      else{
        this.d_totalPrice = 0;
      }
    },
    f_getAllWorkDaysFromTag: function (tag="") {
      let totalWorkDays = 0;
      if(tag){
        for(let task of this.d_tasksForPrint.with_tags.tags[tag].list){
          if(task.data.general && task.data.general.planned_total_worktime_days && task.data.general.planned_total_worktime_days.val){
            totalWorkDays += parseInt(task.data.general.planned_total_worktime_days.val);

          }
        }
      }
      else{
        for(let task of this.d_tasksForPrint.without_tags.list){
          if(task.data.general && task.data.general.planned_total_worktime_days && task.data.general.planned_total_worktime_days.val){
            totalWorkDays += parseInt(task.data.general.planned_total_worktime_days.val);
            
          }
        }
      }
      return totalWorkDays;
    },
    f_chancePrintLang: function () {
      if(this.d_printLang == "tr"){
        this.d_printLang = "en"
      }
      else{
        this.d_printLang = "tr"
      }
    },
    f_dateTimeForLang: function (dateTime, withTime) {
      const momentDate = moment(dateTime);
      let formattedDate = "";
      if(this.d_printLang == "en"){
        formattedDate = momentDate.format(withTime ? 'YYYY.MM.DD HH:mm' : 'YYYY.MM.DD');
      }
      else{
        formattedDate = momentDate.format(withTime ? 'DD.MM.YYYY HH.mm' : 'DD.MM.YYYY');
      }
      return formattedDate;
    },
    f_desiredBeginEndForTag: function (tag="") {
      let begin = null;
      let end = null;
      if(tag){
        for(let task of this.d_tasksForPrint.with_tags.tags[tag].list){
          if(task.data.general && task.data.general.desired_begin_date){
            let taskBegin = moment(task.data.general.desired_begin_date.val);
            if(!begin || begin > taskBegin) {
              begin = taskBegin;
            }
          }
          if(task.data.general && task.data.general.desired_end_date){
            let taskEnd = moment(task.data.general.desired_end_date.val)
            if(!end || taskEnd > end) {
              end = taskEnd;
            }
          }
        }
      }
      else{
        for(let task of this.d_tasksForPrint.without_tags.list){
          if(task.data.general && task.data.general.desired_begin_date){
            let taskBegin = moment(task.data.general.desired_begin_date.val);
            if(!begin || begin > taskBegin) {
              begin = taskBegin;
            }
          }
          if(task.data.general && task.data.general.desired_end_date){
            let taskEnd = moment(task.data.general.desired_end_date.val)
            if(!end || taskEnd > end) {
              end = taskEnd;
            }
          }
        }
      }
      if(begin && end){
        let formattedBegin = this.f_dateTimeForLang(begin, false)
        let formattedEnd = this.f_dateTimeForLang(end, false)
        return(formattedBegin + " - " + formattedEnd);
      }
      return "";
    },
    f_actualBeginEndForTag: function (tag="") {
      let begin = null;
      let end = null;
      if(tag){
        for(let task of this.d_tasksForPrint.with_tags.tags[tag].list){
          if(task.data.general && task.data.general.work_time && task.data.general.work_time.list.length){
            for(let workTime of task.data.general.work_time.list){
              let workTimeBegin = moment(workTime.start.val);
              if(!begin || begin > workTimeBegin) {
                begin = workTimeBegin;
              }
            }
          }
          if(task.data.general && task.data.general.work_time && task.data.general.work_time.list.length){
            for(let workTime of task.data.general.work_time.list){
              let workTimeEnd = moment(workTime.end.val);
              if(!end || workTimeEnd > end) {
                end = workTimeEnd;
              }
            }
          }
        }
      }
      if(begin && end){
        let formattedBegin = this.f_dateTimeForLang(begin, false)
        let formattedEnd = this.f_dateTimeForLang(end, false)
        return(formattedBegin + " - " + formattedEnd);
      }
      return "";
    }
  },
  watch: {
    'd_selectedWdmr307': function(){
      // console.log(this.d_selectedWdmr307)
    },
    // 'd_tasksForPrint': function () {
    //   console.log(this.d_tasksForPrint)
    // },
    // 'd_workTimeTagFilter.selected_list': function() {
    //   console.log(this.d_workTimeTagFilter.selected_list)
    // },
    // 'd_taskList': function () {
    //   console.log("d_taskList")
    //   console.log(this.d_taskList)
    // },
    'd_inputDayPrice': function () {
      if(this.timerForCalculatePrice){
        clearTimeout(this.timerForCalculatePrice);
      }
      this.timerForCalculatePrice = setTimeout(() => {
        this.f_calculateTotalPrice();
      }, 2000);
    },
    'd_inputKDV': function () {
      if(this.timerForCalculatePrice){
        clearTimeout(this.timerForCalculatePrice);
      }
      this.timerForCalculatePrice = setTimeout(() => {
        this.f_calculateTotalPrice();
      }, 2000);
    },
    'd_selectedTab': function () {
      if (this.d_selectedTab === 'organization_details') {
        this.d_WmanagerLayerData.organization.url_show_iframe = '';
        let query = 'wdmr_id=' + this.d_selectedWdmr307.id;
        OrganizationService.organization_wdmr_token_get(query)
          .then(resp => {
            if (resp.data.status_code === '12000') {
              this.d_WmanagerLayerData.organization.url_show_iframe = resp.data.url_show_iframe;
              this.$forceUpdate();
            } else {
              alert('error ' + resp.data.message);
            }
          });
      } else if (this.d_selectedTab === 'report_list') {
        this.f_prepareReportByWmanagerSql();
      }
    },
    'd_filter.operation_no': function () {
      let text = this.d_filter.operation_no;
      setTimeout(function () {
        if (this.d_filter.operation_no === '') {
          if (!this.$route.query === undefined || (this.$route.query !== undefined && this.$route.query.organization_id !== undefined && this.$route.query.operation_no !== undefined)) {
            let route_data = {
              'query': {
                'organization_id': this.$route.query.organization_id
              }
            };
            this.$router.push(route_data);
          }
        } else {
          if (!this.$route.query === undefined || (this.$route.query !== undefined && this.$route.query.organization_id !== undefined && (this.$route.query.operation_no === undefined || this.$route.query.operation_no !== this.d_filter.operation_no))) {
            let route_data = {
              'query': {
                'organization_id': this.$route.query.organization_id,
                'operation_no': this.d_filter.operation_no
              }
            };
            this.$router.push(route_data);
          }
        }
        if (text === this.d_filter.operation_no) {
          // this.f_updateOperations();
        }
      }.bind(this), 1000);
    },
    'd_filter.search_text': function () {
      let text = this.d_filter.search_text;
      setTimeout(function () {
        if (text === this.d_filter.search_text) {
          console.log('filter started..');
          this.f_createWorkTimeTaskList();
        }
      }.bind(this), 2000);
    },
    'd_pagination.current': function () {
      if (this.d_pagination.current !== null && this.d_selectedWdmr307 && this.d_selectedWdmr307.id) {
        let new_start = (this.d_pagination.current - 1) * this.d_pagination.perpage;
        // console.log("this.d_pagination.current", this.d_pagination.current);
        // console.log("new_start", new_start);
        if (new_start >= this.d_pagination.start) {
          if (this.d_wdmrListByAlgorithmResponse.next) {
            this.d_pagination.start = new_start;
            this.d_pagination.end = (this.d_pagination.perpage * this.d_pagination.current) - 1;
            this.f_getWdm17OfThisOrganization([this.d_selectedWdmr307.id]);
          } else {
            let msg = 'Bu algoritmaya uygun yeni operasyon bulunmamaktadır.';
            let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Organizasyon kaydı hakkında' };
            this.$store.commit('MutationModal', modal_data);
            this.$store.commit('MutationModalShow', true);
          }
        } else {
          this.d_pagination.start = new_start;
          this.d_pagination.end = (this.d_pagination.perpage * this.d_pagination.current) - 1;
          this.f_getWdm17OfThisOrganization([this.d_selectedWdmr307.id]);
        }
      }
    }
  }
}

</script>

<style type="text/css">
table,
tr,
th,
td {
  border-bottom: solid 0.5px lightseagreen;
  font-size: 10px;
  /*text-align: center;*/
}

.task-worktime {
  /*text-align: left;*/
  width: 100%;
  /*float: left;*/
  margin: 3px;
  font-size: 10px;
  cursor: pointer;
}

.task-worktime:hover {
  /*text-align: left;*/
  width: 100%;
  /*float: left;*/
  margin: 3px;
  font-size: 10px;
  cursor: pointer;
  background: #9ff5ff;
}

.task-comment {
  /*text-align: left;*/
  width: 100%;
  /*float: left;*/
  margin: 3px;
  font-size: 10px;
  cursor: pointer;
}

.task-comment:hover {
  /*text-align: left;*/
  width: 100%;
  /*float: left;*/
  margin: 3px;
  font-size: 10px;
  cursor: pointer;
  background: #9ff5ff;
}

.operation-useful-link {
  /*text-align: left;*/
  width: 100%;
  /*float: left;*/
  margin: 3px;
  font-size: 10px;
  cursor: pointer;
}

.operation-tag {
  /*text-align: left;*/
  width: 100%;
  /*float: left;*/
  margin: 3px;
  font-size: 10px;
  cursor: pointer;
}

.operation-tag:hover {
  /*text-align: left;*/
  width: 100%;
  /*float: left;*/
  margin: 3px;
  font-size: 10px;
  cursor: pointer;
  background: #9ff5ff;
}

.operation-useful-link:hover {
  /*text-align: left;*/
  width: 100%;
  /*float: left;*/
  margin: 3px;
  font-size: 10px;
  cursor: pointer;
  background: #9ff5ff;
}

.wdmr-link {
  float: right;
  margin: 3px;
  font-size: 10px;
  cursor: pointer;
}

.wdmr-link:hover {
  background: #9ff5ff;
}

.task-visibility {
  text-align: left;
  float: left;
  margin: 3px;
  font-size: 10px;
  cursor: pointer;
}

.task-visibility:hover {
  text-align: left;
  float: left;
  margin: 3px;
  font-size: 10px;
  cursor: pointer;
  background: #9ff5ff;
}

.normal-mode {
  width: 100%;
}

.full-screen-mode {
  padding: 3px;
  position: fixed;
  z-index: 1021;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  /*background-color: rgba(0, 0, 0, .5);*/
  /*display: table;*/
  -webkit-transition: opacity .3s ease;
  transition: opacity .3s ease;
}

</style>


